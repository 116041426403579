import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'

const Meeting = () => import(/* webpackChunkName: "Meeting" */'../views/Meeting')
const MeetingPlaces = () => import(/* webpackChunkName: "MeetingPlaces" */'../views/MeetingPlaces')

const authRequired = true

export default [
  {
    name: 'MeetingPlace',
    path: '/ticket/reuniao/local/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'MeetingPlace'}},
    meta: {authRequired, backRoute: '/ticket/reuniao/locais'}
  },
  {
    path: '/ticket/reuniao/locais',
    components: {default: MeetingPlaces, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Meeting',
    path: '/ticket/reuniao/:_id?',
    components: {default: Meeting, header: Logged},
    meta: {authRequired}
  },
]
