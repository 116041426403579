<template>
    <div class="input-group">
        <input
            :id="id"
            ref="input"
            autocomplete="off"
            :disabled="disabled"
            class="ticketlist-datep form-control datetimepicker-input"
            :placeholder="placeholder"
        />

        <div :data-target="'#' + id" class="input-group-append" data-toggle="datetimepicker">
            <div class="input-group-text">
                <i class="la la-calendar-day"></i>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import BaseComponent from './BaseComponent'

export default {
    name: 'DatePicker',
    extends: BaseComponent,
    props: {
        value: {},
        disabled: {},
        init_placeholder: {String, default: 'Hoje'},
    },
    data () {
        return {
            id: '',
            placeholder: '',
            current_value: ''
        }
    },
    mounted () {
        this.baseMounted()
        this.id = Math.floor(Math.random() * 10000)
        this.init()
    },
    watch: {
        value (v) {
            if (moment(v).isValid()) {
                $(this.$refs.input).datetimepicker('date', moment(v).toDate())
            }
        }
    },
    methods: {
        init () {
            const opts = {
                locale: moment.locale(),
                icons: {
                    time: 'la la-clock-o',
                    date: 'la la-calendar',
                    up: 'la la-arrow-up',
                    down: 'la la-arrow-down',
                    previous: 'la la-chevron-left',
                    next: 'la la-chevron-right',
                    today: 'la la-calendar-check-o',
                    clear: 'la la-trash',
                    close: 'la la-times'
                },
                buttons: {
                    showClear: true,
                    showClose: true
                },
                useCurrent: false
            }

            $(this.$refs.input)
                .on('change.datetimepicker', evnt => {
                    if (!evnt.date) {
                        this.current_value = ''
                        return
                    }
                    this.current_value = evnt.date.format('YYYY-MM-DD')

                    this.$emit('input', this.current_value)
                })
                .datetimepicker({
                    format: 'L',
                    ...opts,
                })
        }
    }
}
</script>

<style scoped>

</style>
