<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>Cadastro de Oportunidades</h3>
        <div class="dropdown-divider"></div>
        <h5>Aqui vamos cadastrar e editar todas as oportunidades</h5>
      </div>
    </div>

    <div class="content-body kanban-application" id="vue-kanban">
      <!-- Kanban starts -->
      <section class="app-kanban-wrapper">
        <!--        <div class="row">-->
        <!--          <div class="col-12">-->
        <!--            <form class="add-new-board">-->
        <!--              <label class="add-new-btn mb-2" for="add-new-board-input">-->
        <!--                <i class="align-middle" data-feather="plus"></i>-->
        <!--                <span class="align-middle">Novo quadro</span>-->
        <!--              </label>-->
        <!--              <input type="text" class="form-control add-new-board-input mb-50" v-model="tituloNovoBoard" placeholder="Add Board Title" id="add-new-board-input" required  style="display: none;"/>-->
        <!--              <div class="form-group add-new-board-input" style="display: none;">-->
        <!--                <button class="btn btn-primary btn-sm mr-75" v-on:click="addBoard()">Adicionar</button>-->
        <!--                <button type="button" class="btn btn-outline-secondary btn-sm cancel-add-new">Cancelar</button>-->
        <!--              </div>-->
        <!--            </form>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="kanban-wrapper ps" id="kanban-wrapper"></div>

        <!-- Kanban Sidebar starts -->
        <div class="modal modal-slide-in update-item-sidebar fade">
          <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0 withoutbr">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
              <div class="modal-header mb-1">
                <h5 class="modal-title">Dados do registro</h5>
              </div>
              <div class="modal-body flex-grow-1">
                <div class="tab-content mt-2">
                  <div class="tab-pane tab-pane-update fade show active">
                    <form class="update-item-form" onsubmit="return false;">
                      <div class="form-group">
                        <label class="form-label" for="title">Title</label>
                        <input type="text" id="title" class="form-control" placeholder="Título" v-model="item.titulo" />
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="due-date">Previsão</label>
                        <!--                        <datepicker-->
                        <!--                          v-model="item.previsao"-->
                        <!--                          :language="ptBR"-->
                        <!--                          :clear-button="true"-->
                        <!--                          :format="dateFormatter"-->
                        <!--                          input-class="form-control"-->
                        <!--                          :bootstrap-styling="true">-->
                        <!--                        </datepicker>-->
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="label">Label</label>
                        <select class="select2 select2-label form-control" id="label" v-model="item.kanban_tag_id">
                          <option value="">&nbsp;</option>
                          <option v-for="tag in tags" :value="tag.id" v-text="tag.descricao"></option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="due-date">Descrição</label>
                        <!--                        <quill-editor-->
                        <!--                          ref="myQuillEditor"-->
                        <!--                          v-model="item.descricao"-->
                        <!--                          :options="editorOption">-->
                        <!--                        </quill-editor>-->
                        <div class="d-flex justify-content-end comment-toolbar">
                          <span class="ql-formats mr-0">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-image"></button>
                          </span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="d-flex flex-wrap">
                          <button class="btn btn-primary mr-1" id="updateItem" v-on:click="update()">
                            <span class="spinner-border spinner-border-sm d-none" id="loading"></span>
                            <span class="ml-25 align-middle" id="textButton">Salvar</span>
                          </button>
                          <button type="button" class="btn btn-outline-danger" id="deleteItem" v-on:click="deleteKanbanItem()">
                            <span class="spinner-border spinner-border-sm d-none" id="loading"></span>
                            <span class="ml-25 align-middle" id="textButton">Deletar</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Kanban Sidebar ends -->
      </section>
      <!-- Kanban ends -->
    </div>
  </div>
</template>

<script>
import {H, showInfo, showError} from 'hennig-common'
import moment from 'moment'
import {en, ptBR} from 'vuejs-datepicker/dist/locale'
// import Datepicker from 'vuejs-datepicker'

export default {
  name: 'CrmOpportunity',
  components: {
    moment,
    // Datepicker
  },
  data () {
    return {
      boards: [],
      tituloNovoBoard: '',
      item: {},
      editorOption: {
        modules: {
          toolbar: '.comment-toolbar'
        },
        placeholder: '',
        theme: 'snow'
      },
      ptBR,
      tags: [],
      kanban: {}
    }
  },
  mounted () {
    H.rpc('CrmOpportunity', 'getKanban', [], r => {
      if (r) {
        this.boards = r
        this.mounteBorder()
      }
    })
  },
  methods: {
    deleteKanbanItem () {
      let self = this
      window.toggleSaveLoading(false, 'Deletar', 'deleteItem')
      window.confirmArena('Parceiros', 'Deseja realmente deletar este registro?', function () {
        self.$http.delete('/app/tools/kanban/' + self.item.id, {}).then((response) => {
          if (response.body.success) {
            window.alertSuccess('Kanban', response.body.message)
            window.toggleSaveLoading(true, 'Deletar', 'deleteItem')
            $('.update-item-sidebar').modal('hide')
            self.kanban.removeElement(self.item.id)
            return
          }

          window.alertError('Kanban', response.body.message)
        }).catch((exception) => {
          console.log(exception)
          window.toggleSaveLoading(false, 'Deletar', 'deleteItem')
          window.alertError('Kanban', 'Ocorreu um erro, favor tentar novamente.')
        })
      }, function () {
        window.toggleSaveLoading(false, 'Deletar', 'deleteItem')
      })
    },
    dateFormatter (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    addBoard () {
      this.$http.post('/app/tools/kanban/addBoard', {titulo: this.tituloNovoBoard}).then((response) => {
        if (response.body.success) {
          this.tituloNovoBoard = ''
          return
        }

        window.alertError('Kanban', response.body.message)
      }).catch((exception) => {
        console.log(exception)
        window.toggleSaveLoading(false)
        window.alertError('Kanban', 'Ocorreu um erro, favor tentar novamente.')
      })
    },
    update () {
      let self = this
      window.toggleSaveLoading(false, 'Salvar', 'updateItem')
      this.$http.post('/app/tools/kanban/' + this.item.id, this.item).then((response) => {
        if (response.body.success) {
          window.toggleSaveLoading(true, 'Salvar', 'updateItem')
          $('.update-item-sidebar').modal('hide')
          window.alertSuccess('Kanban', response.body.message)

          let $this = $('[data-eid="' + this.item.id + '"]'),
            $text = '<span class=\'kanban-text\'>' + response.body.data.item.titulo + '</span>'

          $this.html('')
          $this.html(
            self.renderHeader(response.body.data.color, response.body.data.categoria)
                      + $text
                      + (response.body.data.previsao ? self.renderFooter(response.body.data.previsao) : '')
          )
          return
        }

        window.alertError('Kanban', response.body.message)
      }).catch((exception) => {
        console.log(exception)
        window.toggleSaveLoading(false)
        window.alertError('Kanban', 'Ocorreu um erro, favor tentar novamente.')
      })
    },
    renderBoardDropdown () {
      return (
        '<div class=\'dropdown\'>' +
              feather.icons['more-vertical'].toSvg({
                class: 'dropdown-toggle cursor-pointer font-medium-3 mr-0',
                id: 'board-dropdown',
                'data-toggle': 'dropdown',
                'aria-haspopup': 'true',
                'aria-expanded': 'false'
              }) +
              '<div class=\'dropdown-menu dropdown-menu-right\' aria-labelledby=\'board-dropdown\'>' +
              '<a class=\'dropdown-item delete-board\' href=\'javascript:void(0)\'> ' +
              feather.icons['trash'].toSvg({ class: 'font-medium-1 align-middle' }) +
              '<span class=\'align-middle ml-25\'>Delete</span></a>' +
              '<a class=\'dropdown-item\' href=\'javascript:void(0)\'>' +
              feather.icons['edit'].toSvg({ class: 'font-medium-1 align-middle' }) +
              '<span class=\'align-middle ml-25\'>Rename</span></a>' +
              '<a class=\'dropdown-item\' href=\'javascript:void(0)\'>' +
              feather.icons['archive'].toSvg({ class: 'font-medium-1 align-middle' }) +
              '<span class=\'align-middle ml-25\'>Archive</span></a>' +
              '</div>' +
              '</div>'
      )
    },
    renderDropdown () {
      return (
        '<div class=\'dropdown item-dropdown px-1\'>' +
              Trash2Icon +
              '<div class=\'dropdown-menu dropdown-menu-right\' aria-labelledby=\'item-dropdown\'>' +
              '<a class=\'dropdown-item delete-task\' href=\'javascript:void(0)\'>Delete</a>' +
              '</div>' +
              '</div>'
      )
    },
    renderHeader (color, text) {
      return (
        '<div class=\'d-flex justify-content-between flex-wrap align-items-center mb-1\'>' +
              '<div class=\'item-badges\'> ' +
              '<div class=\'badge badge-pill badge-light-' +
              color +
              '\'> ' +
              text +
              '</div>' +
              '</div>' +
              '</div>'
      )
    },
    renderFooter (avatar) {
      // if (dueDate) {
      return (
        '<div class=\'d-flex justify-content-between align-items-center flex-wrap mt-1\'>' +
                  '<div> <span class=\'align-middle mr-50\'>' +
                  '<img class="round" alt="avatar" src="' + avatar + '" height="40" width="40">' +
                  // '<span class=\'attachments align-middle\'>' +
                  // dueDate +
                  // '</span>' +
                  '</span>' +
                  '</div>' +
                  '</div>'
      )
      // }
    },
    mounteBorder () {
      let self = this
      this.kanban = new jKanban({
        element: '.kanban-wrapper',
        gutter: '15px',
        widthBoard: '250px',
        dragItems: true,
        boards: this.boards,
        dragBoards: false,
        addItemButton: true,
        itemAddOptions: {
          enabled: true, // add a button to board for easy item creation
          content: '+ Nova Oportunidade', // text or html content of the board button
          class: 'kanban-title-button btn btn-default btn-xs', // default class of the button
          footer: false // position the button on footer
        },
        click (element) {
          self.$router.push({ name: 'CrmOpportunity', params: {_id: $(element).attr('data-eid')} })
          // this.item = {}
          // self.$http.get('/app/tools/kanban/' + $(element).attr('data-eid')).then((response) => {
          //   if (response.body.success) {
          //     self.item = response.body.data
          //     if (self.item.previsao) {
          //       self.item.previsao = self.item.previsao + ' 00:00:00'
          //     }
          //     $('.update-item-sidebar').modal('show')
          //     return
          //   }
          //
          //   window.alertError('Kanban', response.body.message)
          // }).catch((exception) => {
          //   console.log(exception)
          //   window.toggleSaveLoading(false)
          //   window.alertError('Kanban', 'Ocorreu um erro, favor tentar novamente.')
          // })
        },
        buttonClick (el, boardId) {
          self.$router.push({ name: 'CrmOpportunity', query: { board_id: boardId } })
          // let addNew = document.createElement('form')
          // addNew.setAttribute('class', 'new-item-form')
          // addNew.innerHTML =
          //             '<div class="form-group mb-1">' +
          //             '<textarea data-gramm_editor="false" class="form-control add-new-item" rows="2" placeholder="Titulo" required></textarea>' +
          //             '</div>' +
          //             '<div class="form-group mb-2">' +
          //             '<button type="submit" id="AddNewKanbanItem" class="btn btn-primary btn-sm mr-1">' +
          //             '<span class="spinner-border spinner-border-sm d-none" id="loading"></span>' +
          //             '<span class="ml-25 align-middle" id="textButton">Adicionar</span></button>' +
          //             '<button type="button" class="btn btn-outline-secondary btn-sm cancel-add-item">Cancelar</button>' +
          //             '</div>'
          // self.kanban.addForm(boardId, addNew)
          // addNew.querySelector('textarea').focus()
          // addNew.addEventListener('submit', function (e) {
          //   e.preventDefault()
          //   let currentBoard = $('.kanban-board[data-id=\'' + boardId + '\']'),
          //     details = {
          //       'kanban_quadro_id': boardId,
          //       'titulo': e.target[0].value
          //     }
          //   window.toggleSaveLoading(false, 'Salvo', 'AddNewKanbanItem')
          //   self.$http.post('/app/tools/kanban/fastadd', details).then((response) => {
          //     if (response.body.success) {
          //       self.kanban.addElement(boardId, {
          //         title: '<span class=\'kanban-text\'>' + e.target[0].value + '</span>',
          //         id: response.body.data.id
          //       })
          //       currentBoard.find('.kanban-item:last-child .kanban-text').before(self.renderHeader('dark', 'Sem categoria'))
          //       // currentBoard.find('.kanban-item:last-child .kanban-text').before(self.renderDropdown());
          //       addNew.remove()
          //       window.toggleSaveLoading(true, 'Salvo', 'AddNewKanbanItem')
          //       return
          //     }
          //
          //     window.alertError('Kanban', response.body.message)
          //   }).catch((exception) => {
          //     console.log(exception)
          //     window.toggleSaveLoading(false)
          //     window.alertError('Kanban', 'Ocorreu um erro, favor tentar novamente.')
          //   })
          // })
          // $(document).on('click', '.cancel-add-item', function () {
          //   $(this).closest(addNew).toggle()
          // })
        },
        dropEl (el, target, source) {
          let nodes = self.kanban.getBoardElements($(target.parentElement).data('id')),
            currentOrder = 0,
            targetOrder = 0,
            to = target.parentElement.dataset.id,
            id = el.getAttribute('data-eid')

          nodes.forEach(function (value, index, array) {
            if (id === $(value).data('eid')) {
              targetOrder = currentOrder
            }
            currentOrder++
          })

          self.$http.post('/app/tools/kanban/' + id + '/moveItem', {newcolumn: to, order: targetOrder}).then((response) => {
            if (response.body.success) {
              return
            }

            window.alertError('Kanban', response.body.message)
            window.href.reload()
          }).catch(() => {
            window.alertError('Kanban', 'Ocorreu um erro, favor tentar novamente.')
          })
        }
      })

      this.initBoard()
    },
    initBoard () {
      let kanbanWrapper = $('.kanban-wrapper'),
        sidebar = $('.update-item-sidebar'),
        addNewForm = $('.add-new-board'),
        addNewInput = $('.add-new-board-input'),
        self = this

      if (kanbanWrapper.length) {
        new PerfectScrollbar(kanbanWrapper[0])
      }

      // Render add new inline with boards
      $('.kanban-container').append(addNewForm)

      // Change add item button to flat button
      $.each($('.kanban-title-button'), function () {
        $(this).removeClass().addClass('kanban-title-button btn btn-flat-secondary btn-sm ml-50')
        // Waves.init();
        // Waves.attach('[class*=\'btn-flat-\']');
      })

      // Deletes Board
      $(document).on('click', '.delete-board', function () {
        let id = $(this).closest('.kanban-board').data('id')
        self.kanban.removeBoard(id)
      })

      // Open/Cancel add new input
      $('.add-new-btn, .cancel-add-new').on('click', function () {
        addNewInput.toggle()
      })

      // Add new board
      addNewForm.on('submit', function (e) {
        e.preventDefault()
        let $this = $(this),
          value = $this.find('.form-control').val(),
          id = value.replace(/\s+/g, '-').toLowerCase()
        self.kanban.addBoards([
          {
            id,
            title: value
          }
        ])

        // Adds delete board option to new board & updates data-order
        $('.kanban-board:last-child').find('.kanban-board-header').append(self.renderBoardDropdown())

        // Remove current append new add new form
        addNewInput.val('').css('display', 'none')
        $('.kanban-container').append(addNewForm)

        // Update class & init waves
        $.each($('.kanban-title-button'), function () {
          $(this).removeClass().addClass('kanban-title-button btn btn-flat-secondary btn-sm ml-50')
          // Waves.init();
          // Waves.attach('[class*=\'btn-flat-\']');
        })
      })

      // Re-init tooltip when modal opens(Bootstrap bug)
      sidebar.on('shown.bs.modal', function () {
        $('[data-toggle="tooltip"]').tooltip()
      })

      // Render custom items
      $.each($('.kanban-item'), function () {
        let $this = $(this),
          $text = '<span class=\'kanban-text\'>' + $this.text() + '</span>'

        if ($this.attr('data-badge') !== undefined && $this.attr('data-badge-text') !== undefined) {
          $this.html(self.renderHeader($this.attr('data-badge'), $this.attr('data-badge-text')) + $text)
        }
        $this.append(
          self.renderFooter(
            $this[0].dataset.avatar
          )
        )
      })
    }
  }
}
</script>

<style scoped>
    @import '../../sass/jkanban.min.css';
    @import '../../sass/app-kanban.css';
</style>
