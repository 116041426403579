<template>
    <div>
        <div class="form-group row">
            <div class="col-12">
                <label>Destinatários</label>
            </div>
            <div class="col-12">
                <div class="btn btn-sm btn-primary" @click="addEmail">
                    <i class="la la-plus-circle link" title="Adicionar um novo destinatário"></i>
                    <span>Adicionar</span>
                </div>
            </div>
            <div class="col-12">
                <div class="email-table border">
                    <table class="table table-bordered table-sm">
                        <tr>
                            <th>E-mail</th>
                            <th>Status</th>
                            <th>Enviado em</th>
                            <th>Lido em</th>
                            <th class="icon-col">
                                ...
                            </th>
                        </tr>
                        <tr v-for="recipient in current_detail.emails">
                            <td>{{ recipient.to_email }}</td>
                            <td class="table__status">{{ recipient.status }}</td>
                            <td>{{ formatDateTime(recipient.sent_at) }}</td>
                            <td>{{ formatDateTime(recipient.read_at) }}</td>
                            <td class="icon-col">
                                <i
                                    v-if="recipient.status !== 'pending'" class="la la-refresh link" title="Reenviar"
                                    @click="addSubmitEmail(recipient.to_email, recipient._id, true)"
                                ></i>
                                <i class="la la-trash link" title="Cancelar" @click="deleteSubmitEmail(recipient._id)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12">
                <div class="btn btn-sm btn-primary" title="Enviar os e-mails acima" @click="sendEmail">
                    <span>Enviar agora</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {H} from 'hennig-common'

export default {
    name: 'EmailPanel',
    props: {
        current_detail: {},
        form: {}
    },
    methods: {
        addEmail () {
            this.dialogs().AddSubmitEmail.show(email => {
                this.addSubmitEmail(email, '', false)
            })
        },
        sendEmail () {
            this.dialogs().Confirmation.show({}, r => {
                if (!r) return
                H.rpc('Ticket', 'sendQueuedEmails', [this.current_detail.detail_id], (r) => {
                    if (r) {
                        this.$emit('reload')
                    }
                })
            })
        },
        deleteSubmitEmail (_id) {
            H.rpc('Ticket', 'deleteSubmitEmail', [_id], (r) => {
                if (r) {
                    this.$emit('reload')
                }
            })
        },
        addSubmitEmail (email, _id, use_confirm = false) {
            if (use_confirm) {
                this.dialogs().Confirmation.show({}, r => {
                    if (r) {
                        this.addSubmitEmail(email, _id, false)
                    }

                })
                return
            }

            H.rpc('Ticket', 'addSubmitEmail', [this.current_detail.detail_id, _id, email], (r) => {
                if (r) {
                    this.$emit('reload')
                }
            })
        }
    }
}
</script>

<style scoped>
.email-table {
    min-height: 150px;
}

.icon-col {
    text-align: center;
}

.table__status {
    overflow: hidden;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
