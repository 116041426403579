<template>
    <VueEditor
        ref="ve"
        v-model="current"
        :class="{ 'no-toolbar': !toolbar }"
        :disabled="disabled"
        :editorOptions="editorSettings"
        :editorToolbar="editorToolbar"
        @input="emitInput"
        @keyup="emitKeyup"
    />
</template>
<script>
import BaseComponent from './BaseComponent'
import {H} from 'hennig-common'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import {VueEditor} from 'vue2-editor'

export default {
    name: 'HtmlEditor',
    props: {
        value: {},
        toolbar: {
            Boolean,
            default: true
        },
        disabled: {
            Boolean,
            default: false
        }
    },
    extends: BaseComponent,
    components: {
        VueEditor
    },
    watch: {
        value (v) {
            this.current = v
            this.$forceUpdate()
        }
    },
    mounted () {
        const that = this
        this.$nextTick(() => {
            $(this.$el)
                .find('.ql-editor')
                .on('keyup', evnt => {
                    // Capturando Keyup para usar em Salvar auto
                    that.emitKeyup(evnt)
                })
                .on('keydown', evnt => {
                    // Nao enviar qdo estiver com a tela de selecao de usuarios
                    if (that.mention_open) return
                    if (evnt.keyCode === 13) {
                        that.$emit('enterKey', evnt)
                    }
                })
        })
    },
    data () {
        const that = this
        return {
            current: '',
            mention_open: false,
            editorSettings: {
                modules: {
                    mention: {
                        onOpen () {
                            that.mention_open = true
                        },
                        onClose () {
                            setTimeout(() => that.mention_open = false, 100)
                        },
                        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                        mentionDenotationChars: ['@', '#'],
                        source (searchTerm, renderList, mentionChar) {
                            H.rpc('UserPublic', 'mention', [searchTerm, mentionChar], rows => {
                                if (!rows) return
                                renderList(rows, searchTerm)
                            })
                        }
                    }
                }
            },
            editorToolbar: [[{header: [false, 1, 2, 3, 4, 5, 6]}], ['bold', 'italic', 'underline', 'strike'], [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}], ['blockquote', 'code-block'], [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}], [{indent: '-1'}, {indent: '+1'}], [{color: []}, {background: []}], ['link', 'image', 'video'], ['clean']]
        }
    },
    methods: {
        emitKeyup (evnt) {
            this.$emit('keyup', evnt)
        },
        getMentions () {
            const user_mentions = []
            const group_mentions = []
            $(this.$el).find('.mention').each((i, el) => {
                const denotationChar = $(el).data('denotation-char')
                const _id = $(el).data('id')
                if (denotationChar === '@') {
                    user_mentions.push(_id)
                } else if (denotationChar === '#') {
                    group_mentions.push(_id)
                }
            })

            return {user_mentions, group_mentions}
        },
        /**
         * Hack
         * Depois de um enter, nao estava funcionando limpar o campo
         */
        clear () {
            this.current = ''
            $(this.$el).find('.ql-editor').empty()
        }
    }
}
</script>

<style lang="scss" scoped>

$size: 18px;

.quillWrapper::v-deep {
  svg {
    vertical-align: initial !important;
  }

  .ql-snow {
    .ql-formats:first-child {
      .ql-picker-label {
        width: 100%;

        svg {
          height: $size !important;
          width: $size !important;
        }

        &::before {
          line-height: $size;
          top: 0;
          position: absolute;
        }
      }
    }

    .ql-picker {
      top: 0;
      height: $size;
    }

    .ql-picker:not(.ql-color-picker) {
      border-bottom: 1px silver solid;
      padding-left: .5em;

      svg {
        width: $size !important;
      }
    }

    button {
      width: $size !important;
      height: $size !important;
      padding: 2px;
    }

    .ql-picker-label {
      padding: 0;
      border: 0;
      width: $size;
      height: $size;
    }

    .ql-color-picker, .ql-icon-picker {
      width: $size !important;
      height: $size !important;
    }
  }
}

.quillWrapper::v-deep .ql-snow.ql-toolbar button svg,
.quillWrapper::v-deep .ql-snow.ql-toolbar .ql-picker-label svg {
  width: 100% !important;
  height: 100% !important;
}

.quillWrapper::v-deep .ql-formats {
  margin-right: 2px !important;
}

.quillWrapper::v-deep .ql-toolbar.ql-snow {
  font-family: inherit !important;
}

.quillWrapper::v-deep .ql-editor,
.quillWrapper::v-deep .ql-picker-label,
.quillWrapper::v-deep .ql-snow .ql-picker {
  font-size: 1em !important;
}

.quillWrapper.no-toolbar::v-deep .ql-editor {
  min-height: initial !important;
}

.quillWrapper.no-toolbar::v-deep .ql-toolbar .ql-formats {
  display: none;
}

.quillWrapper::v-deep .ql-container {
  font-size: inherit;
  font-family: inherit;
}

.quillWrapper::v-deep .ql-mention-list-item {
  font-size: inherit;
  font-family: inherit;
  line-height: 2em;
}
</style>
