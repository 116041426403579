<template>
    <div
        class="btn btn-primary"
        v-on:click="$emit('click')"
    >
        <template v-if="saveState === 'saving'">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
            <span class="ml-1">Salvando...</span>
        </template>
        <template v-else-if="saveState === 'saved'">
            <i class="la la-check-circle text-success"></i>
            <span class="ml-1">Salvo</span>
        </template>
        <template v-else-if="saveState === 'failed'">
            <i class="la la-exclamation-circle text-danger"></i>
            <span class="ml-1">Falhou</span>
        </template>
        <template v-else>
            Salvar
        </template>
    </div>
</template>
<script>
import {endButtonState, failButtonState, startButtonState} from '../common'

export default {
    name: 'SaveButton',
    data () {
        return {
            saveState: ''
        }
    },
    methods: {
        start () {
            this.obj = startButtonState(this)
        },
        done () {
            endButtonState(this, this.obj)
        },
        fail () {
            failButtonState(this, this.obj)
        }

    }
}
</script>
