<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Permissões</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui estão todas as permissões do sistema</h5>
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
                        <th
                            data-align="center"
                            data-column-id="active"
                            data-formatter="checkbox"
                            data-width="36px"
                        ></th>
                        <th
                            data-column-id="name"
                            data-order="asc"
                            data-sortable="true"
                        >Descrição
                        </th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'

export default {
    name: 'Permissions',
    mounted () {
        this.init()
    },
    methods: {
        init () {
            const collectionObj = 'Permission'
            initGrid({
                container: $(this.$el).find('table'),
                collectionObj,
                search: () => this.search,
                noAddButton: false,
                rowClick: true
            })
        }
    }
}
</script>

<style scoped>

</style>
