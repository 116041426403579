<template>
    <div class="main">
        <div class="w-100 mb-2">
            <div class="input-group">
                <select v-model="currentSelect" class="form-control custom-select">
                    <option value="">Selecione</option>
                    <option
                        v-for="option in options"
                        :value="option"
                        v-text="option.name"
                    ></option>
                </select>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <i class="la las la-chevron-circle-down" v-on:click="select(currentSelect)"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-group w-100 scroll-styled p-1 border scroll-box">
            <div
                v-for="(option, index) in current"
                :key="index"
                :class="getClass(option, index)"
                class="list-group-item d-flex justify-content-between"
            >
                <div class="d-flex justify-content-center align-items-center sequence">
                    <div class="inline-edit mr-1">
                        <input
                            :value="index + 1"
                            class="inline-edit fs-larger"
                            size="2"
                            @blur="updateIndex($event, option, index)"
                        />
                    </div>
                    <img
                        v-if="option.concurrent"
                        height="10" src="img/arrow-bottom-rigth.svg"
                    />
                </div>
                <div class="flex-grow-1 d-flex align-items-center" v-text="option.name"></div>
                <div class="d-flex flex-row justify-content-between align-items-center">
<!--                    <small-->
<!--                        :class="{strikethrough: !option.concurrent}"-->
<!--                        class="text-muted mr-1 btn-link"-->
<!--                        v-on:click="toggleConcurrent(option)"-->
<!--                    >Concorrente</small>-->
                    <div class="inline-edit mr-1">
                        <input
                            v-model="option.short_name"
                            placeholder="apelido"
                        />
                    </div>

                    <div class="inline-edit mr-1">
                        <input
                            v-model="option.execution_days"
                            :size="3"
                        />
                        <span>turno(s)</span>
                    </div>

                    <div class="inline-edit mr-4">
                        <input
                            v-model="option.execution_min"
                            :size="3"
                        />
                        <span>min(s)</span>
                    </div>

                    <!--                    <div class="d-flex flex-column justify-content-center">-->
                    <!--                        <i class="la las la-info-circle" v-on:click="showInfo(option, index)"></i>-->
                    <!--                    </div>-->
                    <!--                    <div class="d-flex flex-column justify-content-center">-->
                    <!--                        <i-->
                    <!--                            v-if="index > 0"-->
                    <!--                            class="la las la-arrow-circle-up"-->
                    <!--                            v-on:click="up(option, index)"-->
                    <!--                        ></i>-->
                    <!--                        <i-->
                    <!--                            v-if="index < (value.length-1)"-->
                    <!--                            class="la las la-arrow-circle-down"-->
                    <!--                            v-on:click="down(option, index)"-->
                    <!--                        ></i>-->
                    <!--                    </div>-->
                    <div class="d-flex flex-column justify-content-center">
                        <i class="la las la-times-circle" v-on:click="unselect(option, index)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {valueAsArray} from '../common'
import BaseComponent from './BaseComponent'

export default {
    name: 'PhaseEditor',
    extends: BaseComponent,
    data () {
        return {
            currentInfo: {},
            currentSelect: ''
        }
    },
    mounted () {
        this.baseMounted()
    },
    methods: {
        updateIndex (evnt, option, index) {
            let newIndex = evnt.target.value - 1
            newIndex = Math.max(0, newIndex)
            newIndex = Math.min(newIndex, this.current.length)

            const [el] = this.current.splice(index, 1)
            this.current.splice(newIndex, 0, el)
        },
        getClass (option, index) {
            return {
                // 'col-12': !option.concurrent,
                // 'col-auto': option.concurrent
            }
        },
        showInfo (option, index) {
            this.currentInfo = option
            this.$nextTick(() => {
                this.$refs.info.show(() => {
                    this.currentInfo = null
                })
            })
        },
        down (option, index) {
            const [el] = this.current.splice(index, 1)
            this.current.splice(Math.min(this.current.length, index + 1), 0, el)
        },
        up (option, index) {
            const [el] = this.current.splice(index, 1)
            this.current.splice(Math.max(0, index - 1), 0, el)
        },
        unselect (option, index) {
            // this.$set(option, 'selected', false)
            this.current.splice(index, 1)
        },
        select (option) {
            let copy = {...option}
            // this.$set(copy, 'selected', true)
            this.current.push(copy)
        },
        toggleConcurrent (option) {
            this.$set(option, 'concurrent', !option.concurrent)
        },
        setProps (props) {
            if (props.options) {
                for (const option of props.options) {
                    this.options.push({
                        concurrent: false,
                        notify_manager: '0',
                        notify_user: '0',
                        ...option
                    })
                }
            }

            // for (const i in props) {
            //     this.$set(this, i, props[i])
            // }
        },
        getOptionById (_id) {
            for (const option of this.options) {
                if (_id === option._id) {
                    return option
                }
            }

            return null
        },
        setValue (v) {
            this.current = []
            v = valueAsArray(v)

            const values = v || []
            for (const value of values) {
                let option = this.getOptionById(value._id)
                if (!option) continue
                // option.selected = true
                this.current.push({...option, ...value})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.strikethrough {
  text-decoration: line-through;
}

.main {

}

.inline-edit {
  input {
    border: 1px solid rgba(12, 12, 12, 0.1);
    outline: none;
    text-align: right;

    &:hover, &:focus, &:active {
      border: 1px solid rgba(12, 12, 12, 0.5);
    }
  }
}

.mr25px {
  margin-right: 25px;
}

.btn-link {
  cursor: pointer;
}

.fs-larger {
  font-size: 1.1rem;
}

.sequence {
  span {
    font-size: 1.2rem;
  }

  img {
    width: 1.2rem;
    height: 1.2rem;
  }

  margin-right: .5em;
}

.la {
  font-size: 1.5em;
  cursor: pointer;
}

.list-group {
  height: 400px;
  background-color: #e9ecef;
}

.list-group-item {
  padding: .25rem;
}

.input-group-text {
  background-color: initial;
}
</style>
