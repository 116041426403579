<template>
    <BaseModal
        v-if="visible"
        ref="BaseModal"
        title="Adicionar job combo"
    >
        <Ticket
            ref="Ticket"
            :embed="true"
            :no_footer="false"
            :no_header="true"
            @saved="confirm"
        />
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'
import Ticket from '../views/Ticket'

export default {
    name: 'AddTicketCombo',
    components: {Ticket, BaseModal},
    data () {
        return {
            visible: false
        }
    },
    methods: {
        async show ({customer_id, combo_ticket_id}, callback) {
            this.visible = true
            await this.$nextTick()

            this.$refs.Ticket.clear()
            this.$refs.Ticket.setProps({customer_id, combo_ticket_id})
            this.$refs.BaseModal.show()

            this.callback = callback
        },
        confirm () {
            this.callback(true)
            this.$refs.BaseModal.hide()
            this.visible = false
        }
    }
}
</script>

<style scoped>

</style>
