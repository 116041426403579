<template>
    <VueSelect
        v-model="current"
        :disabled="disabled"
        :filterable="true"
        :options="options"
        class="form-control "
        style="padding-top: 2px"
        :reduce="row => row._id"
        label="name"
        @input="emitInput"
        @search="fetchOptions"
        placeholder="- Responsável -"
    >
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                Nenhum usuário encontrado: <em>{{ search }}</em>.
            </template>
            <em v-else style="opacity: 0.5;">Digite para procurar um usuário...</em>
        </template>
    </VueSelect>
</template>

<script>
import {VueSelect} from 'vue-select'
import {H} from 'hennig-common'
import BaseComponent from './BaseComponent'

export default {
    name: 'UserSelect',
    extends: BaseComponent,
    components: {VueSelect},
    props: {
        value: {},
        disabled: {},
        myGroup: {},
        init_placeholder: {String, default: ''},
        data: {
            Object,
            default: () => ({})
        }
    },
    data () {
        return {
            group_id: '',
            placeholder: '- Responsável -'
        }
    },
    watch: {
        value (_new, _old) {
            if (_new !== _old) this.setValue(_new)
        }
    },
    mounted () {
        this.baseMounted()
        this.setValue(this.value)
    },
    methods: {
        setValue (v) {
            this.fetchOptions(v, (loading) => {
                if (loading === false) {
                    this.current = v
                    this.emitInput(v)
                }
            })
        },
        fetchOptions (search, loading) {
            search = search || this.current
            if (!search) return

            loading(true)
            this.options = []

            if (this.myGroup) {
                H.rpc('User', 'lookupMyGroup', [search], r => {
                    if (r) {
                        this.options = r
                    }

                    loading(false)
                })

                return
            }

            const group_id = this.data.group_id || this.group_id

            if (group_id) {
                H.rpc('User', 'lookupByGroup', [search, group_id], r => {
                    if (r) {
                        this.options = r
                    }

                    loading(false)
                })
            } else {
                H.rpc('User', 'lookupUsers', [search], r => {
                    if (r) {
                        this.options = r
                    }

                    loading(false)
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
