<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Logs de atividade</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui ficarão cadastradas todas as alterações feitas dentro do sistema</h5>
                <div class="container-fluid search-container">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <select v-model="search.user" class="form-control ">
                                <option value="">- Todos usuários -</option>
                                <option v-for="user in users" :key="user._id" :value="user._id" v-text="user.name"></option>
                            </select>
                        </div>
                        <div class="form-group col-sm-3">
                            <select v-model="search.action" class="form-control ">
                                <option value="">- Todas ações -</option>
                                <option value="created">Criado</option>
                                <option value="updated">Alterado</option>
                                <option value="deleted">Removido</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-3">
                            <select v-model="search.object" class="form-control ">
                                <option value="">- Todos objetos -</option>
                                <option v-for="(v, k) in objects" :key="k" :value="k" v-text="v"></option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr>
                <table ref="grid" class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
                        <th
                            data-align="center"
                            data-column-id="viewLog"
                            data-formatter="viewLog"
                            data-sortable="false"
                            data-width="36px"
                        ></th>
                        <th data-column-id="pk" data-sortable="true">Chave</th>
                        <th data-column-id="name" data-sortable="true">Objeto</th>
                        <th data-column-id="action" data-sortable="true">Ação</th>
                        <th data-column-id="created_by_name">Usuário</th>
                        <th
                            data-column-id="created_at"
                            data-formatter="dt"
                            data-sortable="true"
                            data-order="desc"
                        >Quando
                        </th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'
import {H} from 'hennig-common'

const collectionObj = 'ActivityLog'

export default {
    name: 'ActivityLogs',
    data () {
        return {
            search: {user: '', action: '', object: ''},
            users: {},
            objects: {},
        }
    },
    mounted () {
        this.init()
        this.loadFilters()
    },
    methods: {
        init () {
            const noAddButton = true

            initGrid({
                container: $(this.$el).find('table'),
                collectionObj,
                rowClick: true,
                noAddButton,
                search: () => this.search,
                actions: [
                    {
                        name: 'viewLog',
                        icon: 'la-external-link-square',
                        handler (_id) {
                            H.rpc(collectionObj, 'view', [_id], function (r) {
                                if (!r) return
                                H.createForm(r)
                            })
                        }
                    }
                ]
            })
        },
        loadFilters () {
            H.rpc(collectionObj, 'filterOptions', [], r => {
                if (r) {
                    this.users = r.users
                    this.objects = r.objects
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
