<template>
    <div>
        <div v-if="current_detail.approval_detail" class="form-group row">
            <label class="col-12 d-inline pb-1 pt-1">
                <div class="col-12 col-lg-5 float-left p-0">
                    <span v-if="current_detail.phase_id">Fase: </span>
                    <b class="mr-4">{{ current_detail.phase_name }}</b>
                </div>
                <div class="col-12 col-lg-5 float-left p-0">
                    <template v-if="current_detail.due_at">
                        <span>Prazo: </span><b>{{ current_detail.due_at }}</b>
                    </template>
                </div>
            </label>
            <div class="col-lg-12">
                <div
                    class="form-control w-100 content"
                    v-html="current_detail.approval_detail.content"
                ></div>
            </div>
        </div>

        <div v-if="current_detail.approval_detail" class="form-group row">
            <label class="col-12">Arquivos para aprovação</label>
            <div class="col-12">
                <div class="border p-2">
                    <button class="btn btn-primary" @click="downloadAll">
                        <i class="la la-download"></i>
                        <span>Download</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ApprovalRouteBody',
    components: {},
    props: {
        current_detail: {},
        form: {}
    },
    methods: {
        downloadAll () {
            const opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    param: {
                        customer_id: this.form.customer_id,
                        ticket_id: this.form._id,
                        ticket_detail_id: this.current_detail.approval_detail._id
                    },
                    path: ''
                })
            }
            fetch('/download/folder', opts)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    // the filename you want
                    // a.download = name + '.zip'
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                })
        },
    }
}
</script>

<style scoped>
.content {
    min-height: 300px;
    overflow-x: scroll;
}
</style>
