<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Cadastro de feriados</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui vamos cadastrar e editar os feriados que vão basear toda a pauta da agência</h5>
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
<!--                        <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>-->
                        <th data-align="center" data-column-id="x" data-formatter="x" data-sortable="false" data-width="36px"></th>
                        <th data-align="center" data-column-id="active" data-formatter="checkbox" data-width="36px"></th>
                        <th data-column-id="name" data-sortable="true">Descrição do feriado</th>
                        <th data-column-id="full" data-formatter="d" data-order="desc" data-sortable="true">Data</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'

export default {
    name: 'HolidayList',
    mounted () {
        this.init()
    },
    methods: {
        init () {
            const addLabel = 'Adicionar'
            const collectionObj = 'Holiday'
            initGrid({
                container: $(this.$el).find('table'),
                collectionObj,
                rowClick: true,
                addLabel
            })
        }
    }
}
</script>

<style scoped>

</style>
