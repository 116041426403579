import Logged from '../views/headers/Logged'
import TicketView from '../views/TicketView'
import Tickets from '../views/Tickets'
import TicketsAgenda from '../views/TicketsAgenda'
import MyTickets from '../views/MyTickets'

const TicketAdapt = () => import(/* webpackChunkName: "TicketAdapt" */'../views/TicketAdapt')
const TicketReplic = () => import(/* webpackChunkName: "TicketReplic" */'../views/TicketReplic')
const TicketBudget = () => import(/* webpackChunkName: "TicketBudget" */'../views/TicketBudget')
const Ticket = () => import(/* webpackChunkName: "Ticket" */'../views/Ticket')

const authRequired = true

export default [
  {
    path: '/ticket',
    components: {default: Ticket, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/ticket/combo',
    components: {default: Ticket, header: Logged},
    props: {default: {is_combo: true}},
    meta: {authRequired}
  },
  {
    name: 'TicketReplic',
    path: '/ticket/replicacao',
    components: {default: TicketReplic, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'TicketAdapt',
    path: '/ticket/adaptacao',
    components: {default: TicketAdapt, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'TicketBudget',
    path: '/ticket/orcamento',
    components: {default: TicketBudget, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Ticket',
    path: '/ticket/:_id',
    components: {default: TicketView, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/tickets',
    components: {default: Tickets, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'TicketsBudget',
    path: '/tickets/orcamentos/lista',
    components: {default: Tickets, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/tickets/:team_id',
    components: {default: Tickets, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/tickets/job/:ticket_type_id',
    components: {default: Tickets, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/tickets/setor/:setor',
    components: {default: Tickets, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/meus/tickets',
    components: {default: MyTickets, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/pauta',
    components: {default: TicketsAgenda, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'TicketApproval',
    path: '/ticket/aprovacao/:_id',
    components: {default: TicketView, header: Logged},
    props: {default: {isApprovalRoute: true}},
    meta: {authRequired}
  },
]
