<template>
  <BaseModal
    ref="BaseModal"
    title="Editar titulo do ticket"
  >
    <div class="font-weight-bold">
      Deseja realmente alterar o título desse ticket?
    </div>
    <hr>
    <div class="mt-2">
      O titulo atual desse ticket é: <span v-text="currenttitle"></span>
    </div>
    <div class="mt-2">
      Novo título: <input type="text" class="form-control" v-model="newtitle">
    </div>
    <template v-slot:footer>
      <div class="btn btn-primary" @click="confirm()">Trocar Título</div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'
import {showError} from 'hennig-common'

export default {
  name: 'EditTitle',
  components: {BaseModal},
  data () {
    return {
      newtitle: '',
      currenttitle: ''
    }
  },
  methods: {
    show (currenttitle, callback) {
      this.currenttitle = currenttitle
      this.newtitle = ''
      this.$refs.BaseModal.show()
      this.callback = callback
    },
    confirm () {
      if (!this.newtitle) {
        showError('Um título precisa ser definido')
        return
      }

      this.callback({name: this.newtitle})
      this.newtitle = ''
      this.$refs.BaseModal.hide()
    }
  }
}
</script>

<style scoped>
::v-deep .modal-content {
    min-height: 400px;
}
</style>
