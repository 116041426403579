<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Timeline
          :steps="details"
        />
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header" v-bind:class="headerStyle(current_detail, form.status)">
            <div class="row w-100">
              <div class="col-auto mr-auto">
                <span class="mr-2"># Código do Ticket: <b>{{ form.num }}</b></span>
              </div>
              <div class="col-auto mr-auto">
                <span class="mr-2"># Tipo de Serviço: <b>{{ form.job_type_name }}</b></span>
              </div>
              <div class="col-auto mr-auto">
                <span class="mr-2"># Liberação para Aprovação:
                  <b>{{ formatShort(approval_start_at) }} a {{ formatShort(approval_due_at) }}</b>
                </span>
              </div>
              <div class="col-auto mr-auto">
                <span class="mr-2"># Fase atual:
                  <b>{{ current_detail.phase_name }}</b>
                </span>
              </div>
              <div class="col-auto mr-auto">
                <span class="mr-2"># Prazo:
                  <b>{{ current_detail.due_at }}</b>
                </span>
              </div>
            </div>
          </div>
          <div class="card-body mt-1">
            <Multipane>
              <div class="col-left noShowMobile">
                <HistoryPanel
                  :details="detailsRev"
                  :form="form"
                  :fixed_phases="fixed_phases"
                  :disable-click="true"
                  @itemClicked="setCurrentDetail"
                />
              </div>
              <MultipaneResizer class="noShowMobile"/>
              <div :class="{'approval-route': isApprovalRoute, 'submit-phase': isCurrentSubmit(), 'col': !isCurrentSubmit(), 'p-0': !isCurrentSubmit()}" class="col-middle"><!--Coluna central-->
                <div v-if="view === 'references'">
                  <label class="col-12">Arquivos do briefing</label>
                  <UploadsEditor
                    :param="{ customer_id: form.customer_id, ticket_id: form._id}"
                    height="300px"
                  />
                </div>
                <div v-else-if="current_detail">
                  <!--Fase atual eh de reuniao-->
                  <div v-if="isCurrentMeeting()" class="form-group row">
                    <Meeting
                      :embed="true"
                      :init_customer_id="form.customer_id"
                      :init_ticket_detail_id="current_detail.detail_id"
                      :meeting_data="current_detail.meeting_data"
                      :read_only="!canMove()"
                      init_type="planning"
                    />
                  </div>
                  <ComboBody
                    v-else-if="isCurrentCombo()"
                    :current_detail="current_detail"
                    :form="form"
                    @reload="load"
                  />

                  <PublishBody
                    v-else-if="currentAllowSchedule()"
                    :current_detail="current_detail"
                    :form="form"
                    @reload="load"
                  />

                  <!--Fase atual eh de para envio de arquivos-->
                  <SubmitBody
                    v-else-if="isCurrentSubmit()"
                    :current_detail="current_detail"
                    :form="form"
                    @reload="load"
                  />
                  <ApprovalRouteBody
                    v-else-if="isApprovalRoute"
                    :current_detail="current_detail"
                    :form="form"
                  />
                  <template v-else>
                    <!--Fase atual eh de aprovacao-->
                    <template v-if="isCurrentApproval()">
                      <div class="row">
                        <div class="col-lg-12">
                          <div
                            class="form-control w-100 h-auto"
                            v-html="current_detail.approval_detail.content"
                          ></div>
                        </div>

<!--                        <label class="col-12">Arquivos da fase</label>-->
<!--                        <div class="col-12">-->
<!--                          <UploadsEditor-->
<!--                            :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.approval_detail._id}"-->
<!--                            :read_only="true"-->
<!--                          />-->
<!--                        </div>-->
                      </div>
                    </template>
                    <template v-else>
                      <div class="form-group row">
                        <div class="col-12">
                          <div class="row p-2">
                            <div class="col-auto fs12">
                              <span v-if="current_detail.phase_id"># Fase Atual: </span>
                              <b class="mr-4">{{ current_detail.phase_name }}</b>
                            </div>
                            <div class="col-auto fs12">
                              <template v-if="current_detail.due_at">
                                <span># Prazo de Entrega: </span><b>{{ current_detail.due_at }}</b>
                              </template>
                            </div>
                            <div class="col-auto fs12">
                              <span v-if="current_detail" class="mr-2"># Responsável pela fase:
                                <b>{{ current_detail.user_name }}</b></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div v-if="isApprovalRoute && !approvalCanShowContent()">
                            Sem conteúdo disponível
                          </div>

                          <template v-else-if="canMove() && current_detail.active">
                            <HtmlEditor
                              ref="content"
                              v-model="new_content"
                              disabled="disabled"
                            />
                            <div class="small text-muted">{{ content_saved }}</div>
                          </template>

                          <div
                            v-else
                            class="form-control w-100 h-auto"
                            v-html="current_detail.content"
                          ></div>
                        </div>
                      </div>
<!--                      <div v-if="!isApprovalRoute || approvalCanShowContent()" class="form-group row">-->
<!--                        <label class="col-12">Arquivos da fase</label>-->
<!--                        <div class="col-12">-->
<!--                          <UploadsEditor-->
<!--                            :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.detail_id}"-->
<!--                            :read_only="true"-->
<!--                          />-->
<!--                        </div>-->
                    </template>
                  </template>
                </div>
              </div>
              <MultipaneResizer/>
              <div v-if="loaded" class="noShowMobile ">
                <label>Visualização dos arquivos</label>
                <div class="border">
                  <FileViewWrap
                    :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.approval_detail._id}"
                  />
                </div>
              </div>
              <div class="col-right" v-if="isCurrentSubmit()">
                <EmailPanel
                  v-if="isCurrentSubmit()"
                  :current_detail="current_detail"
                  :form="form"
                  @reload="load"
                />
                <div v-else-if="isApprovalRoute">
                  <div>
                    <label>Visualização dos arquivos</label>
                    <div class="border">
                      <FileViewWrap
                        :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.approval_detail._id}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Multipane>
          </div>
          <div class="col-middle col p-0 noShowDesk" v-if="loaded">
            <div class="col-12">
              <label>Visualização dos arquivos</label>
              <div class="border">
                <FileViewWrap
                  :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.approval_detail._id}"
                />
              </div>
            </div>
          </div>
          <div v-if="isApprovalRoute || isCurrentApproval()" class="card-footer d-flex justify-content-center">
            <div>
              <div
                class="btn btn-next"
                @click="goApprovalNext()"
              >
                APROVAR MATERIAL
              </div>
              <div
                class="btn btn-gray"
                @click="goApprovalBack()"
              >
                PEDIR ALTERAÇÃO
              </div>
            </div>
          </div>
          <!--          <div v-if="isCurrentBudget()" class="card-footer d-flex justify-content-center">-->
          <!--            <div>-->
          <!--              <div-->
          <!--                class="btn btn-next"-->
          <!--                @click="confirmBudget()"-->
          <!--              >-->
          <!--                ASSINAR AUTORIZAÇÃO DE MÍDIA-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div v-else class="card-footer d-flex justify-content-between">
            <div class="d-flex justify-content-end">
              <div
                class="btn btn-forward"
                @click="back()"
              >
                Voltar
              </div>
            </div>
            <!--            <div>-->
            <!--              <div-->
            <!--                v-if="(form.in_attendance_group || form.can_cancel_or_edit_time) && form.status !== 'Cancelado'"-->
            <!--                class="btn btn-danger"-->
            <!--                @click="cancel()"-->
            <!--              >-->
            <!--                <i class="la la-trash"></i>-->
            <!--                Cancelar-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="d-flex justify-content-end">-->
            <!--              <div-->
            <!--                v-if="form.in_attendance_group && form.combo_ticket_id"-->
            <!--                class="btn btn-forward"-->
            <!--                @click="unlink()"-->
            <!--              >-->
            <!--                <i class="la la-unlink"></i>-->
            <!--                Desvincular-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="form.can_cancel_or_edit_time"-->
            <!--                class="btn btn-warning"-->
            <!--                @click="editTime()"-->
            <!--              >-->
            <!--                <i class="la la-clock"></i>-->
            <!--                Editar Tempo-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="form.in_attendance_group && !form.combo_ticket_id"-->
            <!--                class="btn btn-forward"-->
            <!--                @click="link()"-->
            <!--              >-->
            <!--                <i class="la la-link"></i>-->
            <!--                Vincular-->
            <!--              </div>-->

            <!--              &lt;!&ndash;                            <div&ndash;&gt;-->
            <!--              &lt;!&ndash;                                v-if="form.job_type_require_budget"&ndash;&gt;-->
            <!--              &lt;!&ndash;                                class="btn btn-forward"&ndash;&gt;-->
            <!--              &lt;!&ndash;                                @click="newBudget()"&ndash;&gt;-->
            <!--              &lt;!&ndash;                            >&ndash;&gt;-->
            <!--              &lt;!&ndash;                                Criar orçamento&ndash;&gt;-->
            <!--              &lt;!&ndash;                            </div>&ndash;&gt;-->

            <!--              <div-->
            <!--                v-if="canAdapt()"-->
            <!--                class="btn btn-forward"-->
            <!--                @click="newAdapt()"-->
            <!--              >-->
            <!--                <i class="la la-plus-circle"></i>-->
            <!--                Adaptação-->
            <!--              </div>-->

            <!--              <div-->
            <!--                v-if="canAdapt()"-->
            <!--                class="btn btn-forward"-->
            <!--                @click="newRepl()"-->
            <!--              >-->
            <!--                <i class="la la-plus-circle"></i>-->
            <!--                Replicação-->
            <!--              </div>-->

            <!--              <div-->
            <!--                v-if="canMove() && form.status !== 'Cancelado'"-->
            <!--                class="btn btn-forward"-->
            <!--                @click="forwardTicket()"-->
            <!--              >-->
            <!--                Encaminhar-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="isSameGroup() && form.status !== 'Cancelado' && current_detail && current_detail.active"-->
            <!--                class="btn btn-owner"-->
            <!--                @click="beOwner()"-->
            <!--              >-->
            <!--                Apropriar-se-->
            <!--              </div>-->

            <!--              <div-->
            <!--                v-if="canMoveBack()"-->
            <!--                class="btn btn-back"-->
            <!--                @click="goBack()"-->
            <!--              >-->
            <!--                Voltar fase-->
            <!--              </div>-->

            <!--              <button-->
            <!--                v-if="canMove() && !isCurrentRejected()"-->
            <!--                class="btn btn-next"-->
            <!--                type="button"-->
            <!--                @click="goNext()"-->
            <!--              >Passar fase-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <ConfirmAskChange ref="ConfirmAskChange"/>
    <ConfirmBeOwner ref="ConfirmBeOwner"/>
    <ConfirmForward ref="ConfirmForward"/>
    <ConfirmCancel ref="ConfirmCancel"/>
    <ConfirmNext ref="ConfirmNext" :title="current_detail.phase_name"/>
    <ConfirmPrior ref="ConfirmPrior"/>
    <EditTime ref="EditTime"/>
  </div>
</template>

<script>
import {H, showError, showInfo} from 'hennig-common'
import {setObj, valueAsArray} from '../common'
import ConfirmBeOwner from '../dialogs/ConfirmBeOwner'
import ConfirmForward from '../dialogs/ConfirmForward'
import ConfirmCancel from '../dialogs/ConfirmCancel'
import ConfirmNext from '../dialogs/ConfirmNext'
import ConfirmPrior from '../dialogs/ConfirmPrior'
import EditTime from '../dialogs/EditTime'
import Meeting from './Meeting'
import ConfirmAskChange from '../dialogs/ConfirmAskChange'
import Timeline from './TicketView/Timeline'
import CommentsPanel from './TicketView/CommentsPanel'
import ApprovalRouteBody from './TicketView/ApprovalRouteBody'
import SubmitBody from './TicketView/SubmitBody'
import HistoryPanel from './TicketView/HistoryPanel'
import {Multipane, MultipaneResizer} from 'vue-multipane'
import FileViewWrap from './TicketView/FileViewWrap'
import EmailPanel from './TicketView/EmailPanel'
import ComboBody from './TicketView/ComboBody'
import DatePicker from '../components/DatePicker'
import moment from 'moment'
import PublishBody from './TicketView/PublishBody'

const initial = {
  _id: '',
  num: '',
  active: 1,
  customer_id: '',
  ticket_type_id: '',
  meeting_id: '',
  combo_ticket_id: '',
  parent_ticket_id: '',
  content: '',
  user: {},
  status: 'Aberto',
  job_type_name: '',
  type: '',
  due_at: '',
  in_attendance_group: false,
  can_cancel_or_edit_time: false,
  same_group: false,
  can_move: false,
  current_execution_time: 0
}

export default {
  name: 'Ticket',
  components: {
    PublishBody,
    ComboBody,
    EmailPanel,
    FileViewWrap,
    Multipane,
    MultipaneResizer,
    HistoryPanel,
    SubmitBody,
    ApprovalRouteBody,
    CommentsPanel,
    Timeline,
    Meeting,
    ConfirmCancel,
    ConfirmForward,
    ConfirmBeOwner,
    ConfirmNext,
    ConfirmAskChange,
    ConfirmPrior,
    moment,
    EditTime
  },
  props: {
    isApprovalRoute: {Boolean, default: false}
  },
  data () {
    return {
      feedbacks: {},
      form: {...initial},

      approval_start_at: '',
      approval_due_at: '',

      customer_name: '',
      new_content: '',
      view: '',
      content_saved: '',

      phase_id: '',
      details: [],

      fixed_phases: [],
      active_detail: {},
      current_detail: {},

      content_save_handler: {},

      editing: false,
      loaded: false
    }
  },
  computed: {
    detailsRev () {
      const reversed = this.details.slice().reverse()
      if (this.isCurrentRejected()) {
        return reversed.filter(v => v.completed || v.active)
      }

      return reversed
    }
  },
  mounted () {
    this.load()

    // $(this.$refs.bodyRow).splitter()
  },
  methods: {

    canAdapt () {
      if (!this.form.in_attendance_group) {
        return false
      }

      if (!this.form.type) {
        this.form.type = 'normal'
      }

      // Combo nao replica
      if (this.form.type === 'combo') {
        return false
      }

      // Somente normal replica por hora
      return this.form.type === 'normal'
    },
    canMoveBack () {
      if (!this.current_detail) {return false}
      if (this.current_detail.phase_type === 'fixed') {return false}
      if (this.current_detail.phase_type === 'submit') {return false}
      return this.form.status === 'Aberto' && true
      // return this.form.status === 'Aberto' && this.form.can_move && this.form.status === 'Aguardando'
    },
    canMove () {
      if (!this.current_detail) {return false}
      if (this.current_detail.phase_type === 'fixed') {return false}
      if (this.current_detail.phase_type === 'submit') {return false}
      return this.form.status === 'Aberto' && this.current_detail && this.current_detail.active
      // return this.form.status === 'Aberto' && this.current_detail && this.current_detail.active && this.form.can_move
    },
    isSameGroup () {
      return this.form.same_group
    },
    approvalCanShowContent () {
      return this.isCurrentBrief() || this.isCurrentMeeting() || this.isCurrentApproval()
    },
    isCurrentApproval () {
      return this.current_detail.approval_detail && this.active_detail && this.active_detail.approval_detail
    },
    isCurrentBrief () {
      return this.current_detail && this.current_detail.is_brief
    },
    isCurrentRejected () {
      return this.active_detail && this.active_detail.phase_type === 'rejected'
    },
    isCurrentMeeting () {
      return this.current_detail && this.current_detail.active && this.current_detail.phase_type === 'meeting'
    },
    isCurrentCombo () {
      return this.current_detail && this.current_detail.active && this.current_detail.phase_type === 'combo'
    },
    isCurrentSubmit () {
      return this.current_detail && this.current_detail.phase_type === 'submit'
    },
    currentAllowSchedule () {
      return this.current_detail && this.current_detail.phase && this.current_detail.phase.allow_schedule
    },
    setCurrentDetail (detail) {
      this.view = 'details'
      this.current_detail = detail

      this.updateComponents()
    },
    loadFixedPhases (ticket_id) {
      H.rpc('Ticket', 'fixedPhases', [ticket_id], rows => {
        if (!rows) {return}
        this.fixed_phases = rows
      })
    },
    load () {
      const _id = this.$route.params._id || ''
      if (!_id) {
        this.updateComponents()
        return
      }

      H.rpc('Ticket', 'getFormData', [_id], (r, e) => {
        if (e) {
          showError('Ocorreu um erro ao buscar este ticket', () => {
            // this.$router.replace('/tickets')
          })
          return
        }

        if (!r) {// Not found
          return
        }

        // Load details
        const ticket_details = valueAsArray(r.details)
        this.details = []
        this.active_detail = {}
        for (const i in ticket_details) {
          if (!ticket_details.hasOwnProperty(i)) {continue}
          this.details.push(ticket_details[i])

          if (ticket_details[i].active) {
            this.active_detail = ticket_details[i]
            this.setCurrentDetail(ticket_details[i])
          }

          if (ticket_details[i].phase_type === 'preapproval') {
            this.approval_start_at = ticket_details[i].dti
            this.approval_due_at = ticket_details[i].dtf
          }
        }

        setObj(this.form, r, {}, Object.keys(initial))

        this.customer_name = r.customer_display_name
        this.new_content = ''

        this.loadFixedPhases(this.form._id)

        this.$refs.CommentsPanel && this.$refs.CommentsPanel.loadComments(this.form._id)
        this.updateComponents()
        if (!this.isCurrentApproval() && !this.isCurrentBudget()) {
          this.setCurrentDetail(this.details[0])
        }
      })
    },
    async updateComponents () {
      await this.$nextTick()
      this.$refs?.content?.setValue(this.current_detail.content || '')
      this.loaded = true
    },
    viewBrief () {
      // todo
      this.current_detail = {
        active: false,
        content: this.form.content
      }
    },
    fieldClass (name) {
      return {
        'is-invalid': this.feedbacks[name]
      }
    },
    newBudget () {
      window.sessionStorage.setItem('customer_id', this.form.customer_id)
      window.sessionStorage.setItem('budget_ticket_id', this.form._id)
      this.$router.push({name: 'TicketBudget'})
    },
    back () {
      this.$router.push({name: 'Tickets'})
    },
    newAdapt () {
      window.sessionStorage.setItem('customer_id', this.form.customer_id)
      window.sessionStorage.setItem('parent_ticket_id', this.form._id)
      this.$router.push({name: 'TicketAdapt'})
    },
    newRepl () {
      window.sessionStorage.setItem('customer_id', this.form.customer_id)
      window.sessionStorage.setItem('parent_ticket_id', this.form._id)
      this.$router.push({name: 'TicketReplic'})
    },
    forwardTicket () {
      this.$refs.ConfirmForward.show(this.current_detail.group_id, user_id => {
        if (user_id) {
          H.rpc('Ticket', 'forward', [this.current_detail.detail_id, user_id], (r) => {
            if (r) {
              this.load()
            }
          })
        }
      })
    },
    beOwner () {
      this.$refs.ConfirmBeOwner.show(r => {
        if (r) {
          H.rpc('Ticket', 'beOwner', [this.current_detail.detail_id], (r) => {
            if (r) {
              this.load()
            }
          })
        }
      })
    },
    cancel () {
      this.$refs.ConfirmCancel.show(r => {
        if (r) {
          H.rpc('Ticket', 'cancel', [this.form._id], (r) => {
            if (r) {
              this.form.status = 'Cancelado'
            }
          })
        }
      })
    },
    goBack () {
      this.$refs.ConfirmPrior.show(this.form._id, (r) => {
        H.rpc('Ticket', 'goto', [this.form._id, {
          content: this.new_content,
          ...r
        }], (r) => {
          if (r) {
            this.load()
          }
        })
      })
    },
    unlink () {
      this.dialogs()
        .Confirmation
        .show({text: 'Deseja realmente desvincular este ticket?'}, r => {
          if (!r) {return}
          H.rpc('Ticket', 'unlinkSubTask', [this.form._id], r => {
            if (!r) {return}
            this.load()
          })
        })
    },
    link () {
      this.dialogs()
        .LinkTicketCombo
        .show({...this.form}, combo_ticket_id => {
          if (!combo_ticket_id) {return}
          H.rpc('Ticket', 'linkSubTask', [this.form._id, combo_ticket_id], r => {
            if (!r) {return}
            this.load()
          })
        })
    },
    goNext (check = true) {
      if (check && this.form.type === 'combo' && this.isCurrentCombo()) {
        H.rpc('Ticket', 'comboCanMove', [this.form._id], (r, e) => {
          if (r) {
            showError(`Existem ${r} jobs em andamento neste combo`)
            return
          }

          if (e) {
            return
          }

          this.goNext(false)
        })
        return
      }

      this.$refs.ConfirmNext.show(() => {
        H.rpc('Ticket', 'next', [this.form._id, this.new_content], (r) => {
          if (r) {
            this.load()
          }
        })
      })
    },
    goApprovalBack () {
      const param = {
        customer_id: this.form.customer_id,
        ticket_id: this.form._id,
        ticket_detail_id: '_new'
      }
      this.$refs.ConfirmAskChange.show(param, content => {
        if (content) {
          H.rpc('Ticket', 'reject', [this.form._id, content], (r) => {
            if (r) {
              this.load()
            }
          })
        }
      })
    },
    goApprovalNext () {
      H.rpc('Ticket', 'next', [this.form._id, 'Aprovado'], (r) => {
        if (r) {
          if (this.isApprovalRoute) {
            showInfo('Esta fase foi aprovada')
            setTimeout(() => {
              this.$router.push('/tickets')
            }, 1000)
            return
          }

          this.load()
        }
      })
    },
    headerStyle (current_detail, Fatherstatus) {
      console.log('Detailsss', current_detail)
      let stat = true
      let stathj = true
      let dphase = moment(current_detail.dtf).format('YYYY-MM-DD')
      let dtoday = moment(new Date()).format('YYYY-MM-DD')

      console.log('dphase', dphase)
      console.log('dtoday', dtoday)

      stat = moment(dphase).isSameOrBefore(dtoday)
      stathj = moment(dphase).isSame(dtoday)

      if(Fatherstatus === 'Fechado'){
        return 'PhaseCompleted'
      }else{
        if(stathj === true){
          return 'PhaseHoje'
        }

        if(stat === true){
          return 'PhaseAtrasada'
        }
        else{
          return 'PhaseEmDia'
        }
      }
    },
    saveDetail () {
      clearTimeout(this.content_save_handler[this.current_detail._id])
      clearTimeout(this.content_saved_handler)

      const detail = this.current_detail
      const content = this.new_content

      this.content_save_handler[this.current_detail._id] = setTimeout(() => {
        H.rpc('Ticket', 'saveDetailContent', [detail, content], (r) => {
          if (r) {
            detail.content = content
            this.content_saved = 'Este texto foi salvo na fase'
            this.content_saved_handler = setTimeout(() => {
              this.content_saved = ''
            }, 2000)
          }
        })
      }, 2000)
    },
    editTime () {
      this.$refs.EditTime.show(this.form.current_execution_time, (r) => {
        H.rpc('Ticket', 'changetime', [this.form._id, {
          ...r
        }], (r) => {
          if (r) {
            showInfo('Tempo do ticket alterado com sucesso')
            this.load()
          }
        })
      })
    },
    confirmBudget () {
      this.dialogs()
        .Confirmation
        .show({text: 'Aqui vai a mensagem de confirmação?'}, r => {
          if (!r) {return}
          H.rpc('Ticket', 'downloadBudget', [this.form._id], r => {
            if (!r) {return}
            H.rpc('Ticket', 'next', [this.form._id, this.new_content], (r) => {
              if (r) {
                this.load()
              }
            })
          })
        })
    },
    isCurrentBudget () {
      return this.current_detail.phase_type === 'budget'
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/variables";

.phase-progress-container {
    text-align: center;
    font-size: .5rem;
    max-width: 100px;

    .phase-progress {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: #e6e6e6;

        &.completed {
            background-color: gray;
        }

        &.active {
            background-color: $green;
        }

        &.selected {
            background-color: $blue;
        }
    }
}

.invalid-feedback {
    display: initial;
}

.card-footer {
    .btn:not(.dropdown-toggle) {
        min-width: 120px;
        margin-left: 4px;
    }

    .btn-back {
        background-color: $red;
        color: #fff;
    }

    .btn-forward {
        background-color: #8b8b8b;
        color: #fff;
    }

    .btn-owner {
        background-color: #dab502;
        color: #fff;
    }

    .btn-next {
        background-color: $green;
        color: #fff;
    }

    .btn-gray {
        background-color: #8b8b8b;
        color: #fff;
    }
}

.h-auto {
    min-height: 300px;
}

.multipane.layout-v {
    min-height: 400px;

    .multipane-resizer {
        left: 0; /* reset default styling */
        width: 6px;
        height: 200px;
        margin: 100px 0 0;
        border-radius: 3px;

        &:hover {
            background-color: rgba(0, 0, 0, .25);
        }
    }

    .col-left {
        width: 20%;
    }

    .col-middle {
        width: 60%;

        &.approval-route, &.submit-phase {
            width: 30%;
        }
    }

    .col-right {
        width: 20%;
        flex-grow: 1;
    }
}

.PhaseHoje {
    background-image: linear-gradient(47deg, #ff9f43, #ffb976);
    color: #fff;
}

.PhaseEmDia {
    background-image: linear-gradient(47deg, #28c76f, #48da89);
    color: #fff;
}

.PhaseAtrasada {
    background-image: linear-gradient(47deg, #ea5455, #f08182);
    color: #fff;
}

.PhaseCompleted {
    background-image: linear-gradient(47deg, #7367f0, #9e95f5);
    color: #fff;
}

@media (min-width: 768px) {
    .noShowDesk {
        display: none;
    }
}

@media (max-width: 768px) {
    .noShowMobile {
        display: none;
    }
}
</style>
