<template>
    <VueSelect
        v-model="current"
        :options="options"
        :reduce="row => row._id"
        label="name"
        :placeholder="placeholder"
        @search="fetchOptions"
        @input="emitInput"
    >
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                Nenhum setor encontrado: <em>{{ search }}</em>.
            </template>
            <em v-else style="opacity: 0.5;">Digite para procurar um setor...</em>
        </template>
    </VueSelect>
</template>

<script>
import {VueSelect} from 'vue-select'
import {H} from 'hennig-common'
import BaseComponent from './BaseComponent'

export default {
    name: 'GroupSelect',
    extends: BaseComponent,
    components: {VueSelect},
    props: {
        data: {},
        init_placeholder: {String, default: ''},
    },
    data () {
        return {
            placeholder: ''
        }
    },
    mounted () {
        this.baseMounted()
    },
    methods: {
        setValue (v) {
            this.fetchOptions(v, (loading) => {
                if (loading === false) {
                    this.current = v
                    this.emitInput(v)
                }
            })
        },
        fetchOptions (search, loading) {
            loading(true)
            this.options = []

            H.rpc('Group', 'lookup', [search], r => {
                if (r) {
                    this.options = r
                }

                loading(false)
            })
        }
    }
}
</script>

<style scoped>

</style>
