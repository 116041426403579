<template>
    <div class="content-wrapper">
        <div class="content-header row"></div>
            <div class="content-overlay"></div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <div class="d-none d-lg-flex col-lg-8 p-0 align-items-center">
                            <div class="w-100 d-lg-flex align-items-center justify-content-center"><img class="img-fluid" src="img/mario-luigi-bg.png" alt="Login V2" /></div>
                        </div>
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <div class="text-center mb-3">
                                    <img height="80" src="img/clubedemidia-world.png"/>
                                </div>
                                <div class="auth-login-form mt-2">
                                    <div class="form-group">
                                        <input
                                            v-model="form.username"
                                            class="form-control"
                                            type="email"
                                            name="email"
                                            placeholder="E-mail"
                                            aria-describedby="login-email"
                                            autocomplete="email"
                                            required
                                            autofocus
                                            tabindex="1"
                                            v-on:keyup.enter="login()"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input
                                                v-model="form.password"
                                                class="form-control form-control-merge"
                                                id="login-password"
                                                type="password"
                                                name="password"
                                                placeholder="Senha"
                                                aria-describedby="login-password"
                                                tabindex="2"
                                                autocomplete="current-password"
                                                required
                                                v-on:keyup.enter="login()"
                                            />
                                        </div>
                                    </div>

                                    <div class="text-center mb-2">
                                        <button class="btn btn-primary btn-block" tabindex="4" v-on:click="login()">Entrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

import {H} from 'hennig-common'
import { FacebookIcon, YoutubeIcon, InstagramIcon, LinkedinIcon } from 'vue-feather-icons'

export default {
    name: 'Login',
    components: {
        FacebookIcon, YoutubeIcon, InstagramIcon, LinkedinIcon
    },
    data () {
        return {
            windowWidth: window.innerWidth,
            form: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        login (params = this.form) {
            H.rpc('Auth', 'login', [params], r => {
                if (r) {
                    window.auth = {
                        user_id: r._id,
                        user_name: r.name
                    }

                    if (this.$route.params.nextUrl) {
                        this.$router.push(this.$route.params.nextUrl)
                    } else {
                        location.href = '/'
                    }
                }
            })
        }
    }
}
</script>
<style scoped>

</style>
