<template>
    <div class="card-body">
        <div class="row">
        <div class="col-sm-8 col-12 d-flex justify-content-center pt-2">
            <!-- <div id="support-trackers-chart"></div> -->
            <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="series"
                :info1="info1"
                :info2="info2"
                :info3="info3"
            ></apexchart>
        </div>
        <div class="col-sm-4 col-12 d-flex justify-content-center">
            <div class="d-flex justify-content flex-column mt-1">

            <div class="text-center mb-3">
                <div class="avatar bg-light-primary mr-2 mb-1">
                <div class="avatar-content">
                    <activity-icon size="1.5x" class="custom-class"></activity-icon>
                </div>
                </div>
                <span class="font-large-1 font-weight-bold">{{ info1 }}</span>
                <h6 class="align-self-center mb-0">Tickets para hoje</h6>
            </div>

            <div class="text-center mb-3">
                <div class="avatar bg-light-success mr-2 mb-1">
                <div class="avatar-content">
                    <smile-icon size="1.5x" class="custom-class"></smile-icon>
                </div>
                </div>
                <span class="font-large-1 font-weight-bold">{{ info2 }}</span>
                <h6 class="align-self-center mb-0">Total entregue</h6>
            </div>

            <div class="text-center mb-3">
                <div class="avatar bg-light-warning mr-2 mb-1">
                <div class="avatar-content">
                    <alert-triangle-icon
                    size="2.5x"
                    class="custom-class"
                    ></alert-triangle-icon>
                </div>
                </div>
                <span class="font-large-1 font-weight-bold">{{ info3 }}</span>
                <h6 class="align-self-center mb-0">Total pendente</h6>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {AlertTriangleIcon,FrownIcon,ActivityIcon,SmileIcon} from "vue-feather-icons";

export default {
    name: 'RadialGraph',
    components: {AlertTriangleIcon,FrownIcon,ActivityIcon,SmileIcon,VueApexCharts},
    props: {
        series: {
            type: Array,
            default: []
        },
        info1: {
            type: Number,
            default: 0
        },
        info2: {
            type: Number,
            default: 0
        },
        info3: {
            type: Number,
            default: 0
        },
    },
    data () {
        return {
            chartOptions: {
                chart: {
                    height: 350,
                    type: "radialBar",
                    offsetY: -10,
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                        name: {
                            fontSize: "16px",
                            color: "#5e5873",
                            offsetY: 120,
                        },
                        value: {
                            offsetY: 76,
                            fontSize: "22px",
                            color: "#5e5873",
                            formatter: function (val) {
                            return val + "%";
                            },
                        },
                        },
                    },
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: undefined,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        // colorStops: [
                        //     {
                        //         offset: 0,
                        //         color: "#ea5455",
                        //         opacity: 1
                        //     },
                        //     {
                        //         offset: 50,
                        //         color: "#ff9f43",
                        //         opacity: 1
                        //     },
                        //     {
                        //         offset: 100,
                        //         color: "#28c76f",
                        //         opacity: 1
                        //     }],
                    },
                },
                stroke: {
                    dashArray: 4
                },
                labels: [''],
            },
        }
    },
    watch: {
        
    },
    mounted () {

    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
