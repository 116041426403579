<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Minha pauta</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui você visualiza a sua pauta dividida pelas fases que estão atrasadas, com entrega prevista para o turno atual, liberadas para execução e previstas.</h5>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                Pauta Atrasada
            </div>
            <div class="card-body ">
                <div class="table-responsive">
                    <table ref="delayed" class="table">
                        <TableHeaders/>
                    </table>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-header">
                Fases com vencimento neste turno
            </div>
            <div class="card-body ">
                <div class="table-responsive">
                    <table ref="ontime" class="table">
                        <TableHeaders/>
                    </table>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                Tickets liberados para execução
            </div>
            <div class="card-body ">
                <div class="table-responsive">
                    <table ref="execute" class="table">
                        <TableHeaders/>
                    </table>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                Tickets previstos para sua pauta nos próximos turnos
            </div>
            <div class="card-body ">
                <div class="table-responsive">
                    <table ref="next" class="table">
                        <TableHeaders/>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'
import {setObj} from '../common'
import {approval, effective_approval, phase_dates, projection, TableHeaders} from '../formmaters'

const formatters = {
    effective_approval,
    approval,
    projection,
    phase_dates,
    numPadding (column, row) {
        const combo = '<img height="12" class="mr-2" src="img/arrow-bottom-rigth.svg"/>'
        const parent = '<img height="12" class="mr-2" src="img/angle-double-right-solid.svg"/>'

        if (row.combo_ticket_id && row.parent_ticket_id) {
            return combo + parent + `<span>${row.num}</span>`
        }

        if (row.combo_ticket_id) {
            return combo + `<span>${row.num}</span>`
        }

        if (row.parent_ticket_id) {
            return parent + `<span>${row.num}</span>`
        }

        return row.num
    }
}
export default {
    name: 'TicketsAgenda',
    components: {
        TableHeaders
    },
    data () {
        return {}
    },
    mounted () {
        this.init()
    },
    methods: {
        openFilter () {
            this.$refs.FilterTickets.show()
        },
        filter (data) {
            setObj(this.search, data)
            $(this.$el).find('table').bootgrid('reload')
        },
        init () {
            const collectionObj = 'Ticket'
            const owner = 'my_user_id'
            const rowClick = true

            initGrid({
                container: $(this.$refs.delayed),
                collectionObj,
                noAddButton: true,
                rowClick,
                search: {agenda: 'delayed'},
                bootgridParams: {
                    rowCss (row) {
                        let issubcombo = ''
                        let isCombo = ''
                        let rowStatus = row.status;
                        if (row.combo_ticket_id){
                            issubcombo = ' row-subcombo '
                        }

                        if (row.type === 'Combo'){
                            isCombo = 'row-combo'
                        }
                        // if (row.type === 'Combo') return ' row-combo ' + issubcombo + ' ' + rowStatus
                        if (row.status !== 'Aberto') return ' entregue ' + issubcombo + ' ' + rowStatus
                        if (!row.approval_diff) return ' no-prazo ' + issubcombo + ' ' + rowStatus

                        if(row.approval_diff){
                            let diff = parseInt(row.approval_diff)
                            const pos = diff < 0 ? ' a-menos ' : ' a-mais '
                            return pos + issubcombo + ' ' + rowStatus + ' ' + isCombo
                        }

                        return ''
                    }
                },
                formatters,
            })
            initGrid({
                container: $(this.$refs.ontime),
                collectionObj,
                noAddButton: true,
                rowClick,
                search: {agenda: 'ontime'},
                formatters,
            })
            initGrid({
                container: $(this.$refs.execute),
                collectionObj,
                noAddButton: true,
                rowClick,
                search: {agenda: 'execute'},
                formatters,
            })
            initGrid({
                container: $(this.$refs.next),
                collectionObj,
                noAddButton: true,
                rowClick,
                search: {agenda: 'next'},
                formatters,
            })
        },
        setStyle () {

        }
    }
}
</script>

<style lang="scss" scoped>
.card-header {
    font-weight: bold;
    font-size: 1.2em;
}

.card {
    margin-bottom: 1em;
}

::v-deep .bootgrid-header,
::v-deep .bootgrid-footer,
::v-deep .pagination {
    display: none;
}
::v-deep {
    .table th:nth-child(6),
    .table th:nth-child(7),
    .table th:nth-child(8) {
        background-color: darkgray;
    }

    .th-phases {
        background-color: #b8f2f9;
    }

    .a-menos {
        border-left: 20px solid #48da89 ;
    }

    .no-prazo {
        border-left: 20px solid #c3efd7;
    }

    .a-mais {
        background-color: #f9cfcf;
        border-color: #f4a6a7;
    }

    .Fechado, .Cancelado {
        border-left: 20px solid #ffe4ca;
    }

    .Aguardando {
        border-left: 20px solid #d8d4fb;
    }

    .badge.badge-light-primary {
        background-color: rgba(115, 103, 240, 0.12);
        color: #7367f0 !important;
        font-size: 11px;
        margin-top: 0.72rem;
    }

    .Aberto td:last-child {
        color: #7367f0 !important;
        font-weight: 600;
    }

    .Fechado td:last-child {
        color: #ff9f43 !important;
        font-weight: 600;
    }

    .Cancelado td:last-child {
        color: #ea5455 !important;
        font-weight: 600;
    }

    .Aguardando td:last-child {
        color: #00cfe8 !important;
        font-weight: 600;
    }

    .row-combo {
        background-color: #7ae6f3;
    }

    .row-subcombo {
        border-right: 20px solid #7ae6f3 !important;
    }

    .table tr {
        border-top: 0px;
        border-bottom: 1px solid #cece;
        transition: 0.3s;
        position: relative;
    }

    .table tr:hover {
        top: -1px;
        transition: 1s;
    }
}
</style>
