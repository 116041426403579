<template>
  <div class="container">
    <div v-if="status === 'loading'">
      <div class="spinner-border" role="status">
      </div>
    </div>
    <div v-else-if="status === 'empty'" class="card">
      <div class="card-body">Pedido indisponível para visualização</div>
    </div>
    <div v-else class="card">
      <div class="card-body">
        <div class="card-header pl-0">
          Pedido realizado por: {{ result.created_by_name }} em {{ result.order_fdate + ' ' + result.order_ftime }}
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Data</th>
              <th>Hora</th>
              <th>Produto</th>
              <th>Preço</th>
              <th>Quantidade</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!Object.keys(result).length">
              <td colspan="6" class="text-center">Sem itens</td>
            </tr>
            <tr v-else v-for="row in result.item_list">
              <td>{{ row.date }}</td>
              <td>{{ row.hour }}</td>
              <td>{{ row.product_name }}</td>
              <td>{{ row.price }}</td>
              <td>{{ row.quantity }}</td>
              <td>{{ row.value }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="Object.keys(result).length">
              <td colspan="5" class="text-right font-weight-bolder">Total</td>
              <td class="font-weight-bolder" v-text="result.total"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {setObj} from '../common'
import {H} from 'hennig-common'

export default {
  name: 'CoffeeOrderDetail',
  data () {
    return {
      status: 'loading',
      result: {}
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      H.rpc('CoffeeOrder', 'get', [this.$route.params._id], r => {
        if (r) {
          this.result = r
          this.status = 'loaded'
          return
        }

        this.status = 'empty'
      })
    }
  }
}
</script>

<style scoped>

</style>
