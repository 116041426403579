<template>
  <div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-overlay"></div>
    <div class="content-body">
      <!-- Stats Vertical Card -->
      <div class="row">
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ myClientTickets }}</h2>
              <p class="card-text mb-0">Tickets dos meus clientes</p>
              <small>(Aguardando e Abertos)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ myTickets }}</h2>
              <p class="card-text mb-0">Tickets na minha pauta</p>
              <small>(Aguardando e Abertos)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ myTicketsToday }}</h2>
              <p class="card-text mb-0">Minha pauta para hoje</p>
              <small>(Em período de entrega de fase)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ mySectorTicketsTotal }}</h2>
              <p class="card-text mb-0">Tickets do meu setor</p>
              <small>(Aguardando e Abertos)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ mySectorTicketsTodayTotal }}</h2>
              <p class="card-text mb-0">Tickets do setor para hoje</p>
              <small>(Em período de entrega de fase)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ mySectorTicketsLateTotal }}</h2>
              <p class="card-text mb-0">Tickets do setor atrasados</p>
              <small>(Passou do período de entrega de fase)</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-md-12 col-12">
          <div class="card card-statistics">
            <div class="card-header pb-0">
              <h4 class="card-title">Estatísticas por Equipe:</h4>
              <div class="d-flex align-items-center"></div>
            </div>
            <div class="card-body statistics-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="font-weight-bolder">&nbsp;</th>
                      <th class="font-weight-bolder" v-for="(name, key) in teams">
                        <a :href="'/#/tickets/' + key">{{ name }}</a>
                      </th>
                      <th class="font-weight-bolder">Totais</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in result">
                      <td>{{ key }}</td>
                      <td class="text-nowrap" v-for="(tvalue, tkey) in teams">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value[tkey]}}</span>
                          <span class="font-small-2 text-muted" v-if="totais[key] === 0">
                            &nbsp;(0 %)
                          </span>
                          <span class="font-small-2 text-muted" v-else>
                            &nbsp;({{ ((value[tkey] * 100) / totais[key]).toFixed(1) + "%" }})
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{ totais[key] }}</span>
                          <span class="font-small-2 text-muted">&nbsp;(100%)</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { H } from 'hennig-common'

export default {
  name: 'DashboardNew',
  data () {
    return {
      userName: '',
      teams: [],
      result: [],
      totais: [],
      myClientTickets: 0,
      myTickets: 0,
      myTicketsToday: 0,
      mySectorTicketsTotal: 0,
      mySectorTicketsTodayTotal: 0,
      mySectorTicketsLateTotal: 0
    }
  },
  mounted () {
    let self = this
    H.rpc('Dashboard', 'HeaderData', [], (r) => {
      if (r) {
        this.myTickets = r.myTickets
        this.myTicketsToday = r.myTicketsToday
        this.myTicketsLate = r.myTicketsLate
        this.myClientTickets = r.myClientTickets
        this.mySectorTickets = r.mySectorTickets
        this.mySectorTicketsLate = r.mySectorTicketsLate
        this.mySectorTicketsToday = r.mySectorTicketsToday

        this.mySectorTicketsTotal = this.mySectorTickets.reduce((mySectorTicketsTotal, mySectorTickets) => {
          return mySectorTickets + mySectorTicketsTotal
        }, 0)

        this.mySectorTicketsLateTotal = this.mySectorTicketsLate.reduce((mySectorTicketsLateTotal, mySectorTicketsLate) => {
          return mySectorTicketsLate + mySectorTicketsLateTotal
        }, 0)

        this.mySectorTicketsTodayTotal = this.mySectorTicketsToday.reduce((mySectorTicketsTodayTotal, mySectorTicketsToday) => {
          return mySectorTicketsToday + mySectorTicketsTodayTotal
        }, 0)
      }
    })

    H.rpc('DashboardNew', 'myTicketsData', [], (r) => {
      if (r) {
        this.teams = r.teams
        this.result = r.result
        this.totais = r.totais
      }
    })
  },
}
</script>

<style scoped>
</style>


