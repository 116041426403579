<template>
    <div>
        <div v-for="group in groups" :key="group._id" class="form-row">
            <div class="col-4">
                {{ group.name }}
            </div>
            <div class="col">
                <UserSelect @input="selectUpdated($event, group)" />
            </div>
        </div>
    </div>
</template>
<script>

import BaseComponent from './BaseComponent'
import {H} from 'hennig-common'
import UserSelect from './UserSelect'

export default {
    components: {UserSelect},
    extends: BaseComponent,
    name: 'CustomerUserGroupEditor',
    data () {
        return {
            groups: [],
            users: [],
        }
    },
    mounted () {
        this.baseMounted()
        this.fetchGroups()
    },
    methods: {
        selectUpdated(event, group) {
            group.user_id = event
        },
        fetchGroups () {
            this.groups = []
            H.rpc('Group', 'all', [], r => {
                if (r) {
                    for (const group of r) {
                        this.groups.push({user_id: '', ...group})
                    }
                }
            })
        }
    }
}
</script>
