<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <img src="/img/clubedemidia-world-escudo.png" class="d-none d-print-inline"/>
          <h3>Pedidos</h3>
        </div>
        <div class="dropdown-divider"></div>
      </div>

      <div class="card-body d-print-none">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Data Inicial</label>
            <DatePicker
              init_placeholder="- Escolher data -"
              v-model="search.dataini"
            />
          </div>
          <div class="col-lg-6">
            <label>Data Final</label>
            <DatePicker
              init_placeholder="- Escolher data -"
              v-model="search.datafim"
            />
          </div>
          <div class="form-group col-md-12 mt-1">
            <label>Usuário</label>
            <select class="form-control" id="coffe_user_id" v-model="search.user_id">
              <option value="">Selecione um usuário</option>
              <option v-for="user in users" :value="user._id" v-text="user.name"></option>
            </select>
          </div>
          <div class="col-lg-12">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="load()" v-text="textoBotao"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <th>Funcionário</th>
            <th>Data</th>
            <th>Hora</th>
            <th>Total</th>
            <th>Ações</th>
          </thead>
          <tbody>
            <tr v-if="!result.length">
              <td colspan="5" class="text-center">Sem resultado</td>
            </tr>
            <tr v-else v-for="row in result">
              <td>{{ row.name }}</td>
              <td>{{ row.order_fdate }}</td>
              <td>{{ row.order_ftime }}</td>
              <td>{{ row.total_formated }}</td>
              <td class="text-center width-5-per">
                <eye-icon class="cursor-pointer" size="1.5x" v-on:click="view(row._id)"></eye-icon>
                <trash2-icon v-if="canAccess('delete_order_item')" class="cursor-pointer text-danger" size="1.5x" v-on:click="deleteOrder(row._id)"></trash2-icon>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="result.length">
              <td colspan="3" class="text-right font-weight-bolder">Total</td>
              <td colspan="2" class="font-weight-bolder" v-text="total"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'
import {setArr} from '../common'
import DatePicker from '../components/DatePicker'
import {EyeIcon, Trash2Icon} from 'vue-feather-icons'

export default {
  name: 'CoffeeOrderDetail',
  components: {
    DatePicker,
    EyeIcon,
    Trash2Icon
  },
  data () {
    return {
      search: {
        dataini: '',
        datafim: '',
        user_id: '',
      },
      users: [],
      result: [],
      total: 0,
      textoBotao: 'Consultar'
    }
  },
  mounted () {
    H.rpc('User', 'all', [], r => {
      if (r) {
        this.users = r
      }
    })
  },
  methods: {
    fillCustomer (row) {
      this.customer_display_name = row.trade_name
    },
    view (id) {
      window.open('/#/cafeteria/pedido/detalhe/' + id, '_blank')
    },
    deleteOrder (id) {
      let self = this
      this.dialogs().Confirmation.show({
        text: 'Deseja realmente excluir esse pedido?',
        primary_text: 'Não',
        secondary_text: 'Sim',
        secondary_class: 'btn btn-danger'
      }, r => {
        if (!r) {
          H.rpc('CoffeeOrder', 'deleteOrder', [id], r => {
            if (r) {
              showInfo('Pedido removido com sucesso!')
              self.load()
            }
          })
        }

        return
      })
    },
    load () {
      setArr(this, 'result', [])
      let self = this
      this.textoBotao = 'Consultando'
      H.rpc('CoffeeOrder', 'getOrders', [this.search], rows => {
        if (rows) {
          self.textoBotao = 'Consultar'
          self.total = rows.total
          setArr(this, 'result', rows.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
    th {
        font-size: 0.7rem;
        background-color: rgba(0, 0, 0, 0.125);
    }
}
</style>
