import Vue from 'vue'

export function getEntryType (entry) {
    if (!entry.name) {
        return 'default'
    }

    if (entry.name.match(/(jpeg|bmp|png|gif|svg)$/i)) {
        return 'image'
    }

    if (entry.folder) {
        return 'folder'
    }

    if (entry.name.match(/txt$/i)) {
        return 'text'
    }

    if (entry.name.match(/(avi|mpg|mkv|mp4)$/i)) {
        return 'video'
    }

    if (entry.name.match(/(mp3|wav|ogg)$/i)) {
        return 'audio'
    }

    if (entry.name.match(/(doc|odt|docx)$/i)) {
        return 'doc'
    }

    if (entry.name.match(/(xls|ods|xlsx|csv)$/i)) {
        return 'xls'
    }

    if (entry.name.match(/pdf$/i)) {
        return 'pdf'
    }
    if (entry.name.match(/zip|rar|7z/i)) {
        return 'zip'
    }

    return 'default'
}

export function removeAccents (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function empty () {
    //
}

export function valueAsArray (v) {
    if (!v) {
        v = []
        return v
    }

    if (typeof v === 'string') {
        try {
            v = JSON.parse(v)
        } catch (e) {
            v = []
        }
    }

    if (typeof v === 'object') {
        if (!Array.isArray(v)) {
            v = Object.values(v)
        }
    } else {
        v = [v]
    }

    return v
}

export function setArr (vueProp, key, values) {
    if (!Array.isArray(values)) {
        values = Object.values(values)
    }

    Vue.set(vueProp, key, values)
}

export function setObj (o, values, extra = {}, whitelist = null) {
    const l_values = {...extra, ...values}
    for (const i in l_values) {
        if (whitelist === null || whitelist.includes(i)) {
            // eslint-disable-next-line no-prototype-builtins
            if (l_values.hasOwnProperty(i)) {
                Vue.set(o, i, l_values[i])
            }
        }
    }
}

export function clearObj (o) {
    for (const i in o) {
        // eslint-disable-next-line no-prototype-builtins
        if (o.hasOwnProperty(i)) {
            Vue.set(o, i, null)
            delete o[i]
        }
    }
}

export function startButtonState (vue) {
    const started = performance.now()
    vue.saveState = 'saving'
    return {
        started
    }
}

export function endButtonState (vue, o) {
    const update = () => {
        vue.saveState = 'saved'
        vue.changed = false
        setTimeout(() => {
            vue.saveState = ''
        }, 2000)
    }

    setTimeout(update, Math.max((performance.now() - o.started), 2000))
}

export function failButtonState (vue, o) {
    const update = () => {
        vue.saveState = 'failed'
        setTimeout(() => {
            vue.saveState = ''
        }, 2000)
    }

    setTimeout(update, Math.max((performance.now() - o.started), 1000))
}


export function renderFeedback (vue, e) {
    if (e.data) {
        for (const prop in e.data) {
            vue.$set(vue.feedbacks, prop, e.data[prop].join(','))
        }

        return true
    }
}

export function getFilesWebkitDataTransferItems (dataTransferItems) {
    function traverseFileTreePromise (item, path = '') {
        return new Promise(resolve => {
            if (!item) return
            if (item.isFile) {
                item.file(file => {
                    file.filepath = path + file.name //save full path
                    files.push(file)
                    resolve(file)
                })
            } else if (item.isDirectory) {
                let dirReader = item.createReader()
                dirReader.readEntries(entries => {
                    let entriesPromises = []
                    for (let entr of entries)
                        entriesPromises.push(traverseFileTreePromise(entr, path + item.name + '/'))
                    resolve(Promise.all(entriesPromises))
                })
            }
        })
    }

    let files = []
    return new Promise((resolve, reject) => {
        let entriesPromises = []
        for (let it of dataTransferItems)
            entriesPromises.push(traverseFileTreePromise(it.webkitGetAsEntry()))
        Promise.all(entriesPromises)
            .then(entries => {
                //console.log(entries)
                resolve(files)
            })
    })
}
