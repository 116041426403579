<template>
    <BaseModal
        v-if="visible"
        ref="BaseModal"
        title="Vincular job "
    >
        <TicketSelect
            ref="TicketSelect"
            :filter="{type: 'combo', customer_id: customer_id}"
            class="form-control"
        />

        <template v-slot:footer>
            <div class="btn btn-primary" @click="confirm()">Vincular</div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'

export default {
    name: 'LinkTicketCombo',
    components: {BaseModal},
    data () {
        return {
            visible: false
        }
    },
    methods: {
        async show ({customer_id}, callback) {
            this.visible = true
            this.customer_id = customer_id

            await this.$nextTick()

            this.$refs.BaseModal.show()
            this.callback = callback
        },
        confirm () {
            this.callback(
                this.$refs.TicketSelect.getValue()
            )
            this.$refs.BaseModal.hide()
            this.visible = false
        }
    }
}
</script>

<style scoped>
::v-deep .modal-content {
    min-height: 400px;
}
</style>
