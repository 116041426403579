<template>
    <div v-if="current_detail">
        <div class="form-group row">
            <div class="col-12">
                <label><b class="mr-4">{{ current_detail.phase_name }}</b></label>
            </div>
            <div class="col-lg-12">
                <HtmlEditor
                    ref="HtmlEditor"
                    v-model="content"
                />
                <div class="btn btn-primary btn-sm" @click="saveDetail">Salvar</div>
            </div>
        </div>

<!--        <div class="form-group row">-->
<!--            <label class="col-12">Arquivos para publicação</label>-->
<!--            <div class="col-12">-->
<!--                <UploadsEditor-->
<!--                    :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.detail_id}"-->
<!--                    height="150px"-->
<!--                />-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'

export default {
    name: 'SubmitBody',
    props: {
        current_detail: {},
        form: {}
    },
    data () {
        return {
            content: ''
        }
    },
    watch: {
        current_detail: {
            handler (v) {
                this.$refs.HtmlEditor.setValue(v.content)
            },
            deep: true
        }
    },
    mounted () {
        this.$refs.HtmlEditor.setValue(this.current_detail.content)
    },
    methods: {
        saveDetail () {
            H.rpc('Ticket', 'saveDetailContent', [this.current_detail, this.content], (r) => {
                if (r) {
                    this.$emit('reload')
                    showInfo('Este texto foi salvo na fase')
                }
            })
        }
    }
}
</script>

<style scoped>
.content {
    min-height: 300px;
}
</style>
