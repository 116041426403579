<template>
  <nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
    <div class="navbar-header d-xl-block custom-logo-position">
      <!-- MENU LOGO -->
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
            <img height="40" src="img/logo-clube-shield.png"/>
          </router-link>
        </li>
      </ul>
      <!-- FIM MENU LOGO -->
    </div>
    <div class="navbar-container d-flex content">
      <div class="d-flex align-items-center">
        <!-- MENU ESQUERDO -->
        <ul class="nav navbar-nav bookmark-icons">
          <li class="nav-item d-lg-block">
            <router-link class="nav-link" title="Lista de tickets" to="/tickets">
              <list-icon size="1.5x" class="ficon"></list-icon>
            </router-link>
          </li>
          <li class="nav-item d-lg-block dropdown">
            <a id="navbarDropdown" aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" title="Relatórios">
              <pie-chart-icon size="1.5x" class="ficon"></pie-chart-icon>
            </a>
            <ul class="dropdown-menu">
              <li class="nav-item d-none d-lg-block">
                <router-link class="dropdown-item" to="/relatorio/jobs">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Tempos e Prazos dos Jobs</span>
                </router-link>
              </li>
              <li class="nav-item d-lg-block">
                <router-link class="dropdown-item" to="/relatorio/horas/trabalhadas">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Relatório de Horas</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block">
                <router-link class="dropdown-item" to="/relatorio/horasgerencial/">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Relatório de Horas Gerencial</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>

      </div>
      <!-- FIM MENU ESQUERDO -->
      <!-- MENU DIREITO -->
      <ul class="nav navbar-nav align-items-center ml-auto">
        <li class="nav-item dropdown dropdown-notification mr-25">
          <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
            <bell-icon size="1.5x" class="ficon"></bell-icon>
            <span v-if="notifications_unread_count >= 1" class="badge badge-pill badge-danger badge-up">
              {{ notifications_unread_count }}
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
                <div class="badge badge-pill badge-light-primary">{{ notifications_unread_count }}</div>
              </div>
            </li>
            <li class="scrollable-container media-list">
              <div v-if="notifications.length" class="list-group hidden-box">
                <div
                  v-for="(item, idx) in notifications"
                  :key="item._id"
                  class="d-flex"
                >
                  <div v-if="notifications.length" class="media d-flex align-items-start position-relative">
                    <i class="la la-trash link position-absolute" style="right: 2%;bottom: 10%;" @click="removeNotification(notifications, idx)"></i>
                    <router-link :to="'/aviso/' + item._id" class="text-nowrap d-flex justify-content-between flex-grow-1">
                      <span class="font-weight-bolder">{{ item.brief }}</span><br>
                      <i class="text-muted">{{ formatElapsed(item.created_at) }}</i>
                    </router-link>
                  </div>
                  <div v-else class="p-2 text-center">
                    <small class="notification-text">Nenhuma notificação</small>
                  </div>
                </div>
              </div>
            </li>
            <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)" @click="removeAllNotifications()">Limpar notificações</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown dropdown-notification mr-25">
          <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
            <at-sign-icon size="1.5x" class="ficon"></at-sign-icon>
            <span v-if="mentions_unread_count >= 1" class="badge badge-pill badge-primary badge-up">
              {{ mentions_unread_count }}
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Menções</h4>
                <div class="badge badge-pill badge-light-primary">{{ mentions_unread_count }}</div>
              </div>
            </li>
            <li class="scrollable-container media-list">

              <div v-if="mentions.length" class="list-group hidden-box">
                <div
                  v-for="(mention, idx) in mentions"
                  :key="mention._id"
                  class="d-flex"
                >
                  <div class="media d-flex align-items-start position-relative">
                    <i class="la la-trash link position-absolute" style="right: 2%;bottom: 11%;" @click="removeMention(mention, idx)"></i>
                    <div class="media-left">
                      <div class="avatar bg-light">
                        <div class="avatar-content"><img height="40" width="40" src="img/clubedemidia-world-escudo.png"></div>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder" v-html="mention.content"/>
                      </p>
                      <small class="notification-text">{{ formatElapsed(mention.created_at) }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)" @click="removeAllMentions()">Limpar menções</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown dropdown-user">
          <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="user-nav d-sm-flex d-none"><span class="user-name font-weight-bolder">{{ firstName(user_name) }}</span><span class="user-status">{{ role_name }}</span></div><span class="avatar"><img class="round" :src="checkPhoto(photo)" alt="avatar" height="40" width="40"><span class="avatar-status-online"></span></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user">
            <router-link class="dropdown-item" to="/profile">
              <user-icon size="1.5x" class="mr-50"></user-icon> Meus dados
            </router-link>
            <router-link class="dropdown-item" to="/logout">
              <power-icon size="1.5x" class="mr-50"></power-icon> Sair
            </router-link>
          </div>
        </li>
      </ul>
      <!-- FIM MENU DIREITO -->
    </div>
  </nav>
</template>

<script>
import Vue from 'vue'
import { H } from 'hennig-common'
import info from '../../../../info.json'

import { ListIcon, PieChartIcon, TypeIcon, ShoppingCartIcon, BellIcon, AtSignIcon, PowerIcon, UserIcon } from 'vue-feather-icons'

export default {
  name: 'Logged',
  components: {
    ListIcon,
    PieChartIcon,
    TypeIcon,
    ShoppingCartIcon,
    BellIcon,
    AtSignIcon,
    PowerIcon,
    UserIcon,
  },
  data () {
    return {
      version: info.version,
      mentions_unread_count: 0,
      mentions: [],
      notifications_unread_count: 0,
      notifications: [],
      photo: '',
      role_name: '',
      ...window.auth
    }
  },
  mounted () {
    this.getMessages()
    this.getMentions()
    this.initEcho()
  },
  methods: {
    getMessages () {
      H.rpc('Message', 'getMy', [{'new': true}], (r) => {
        if (r) {
          this.notifications = []

          for (const item of r.data) {
            if (item.type === 'L') {
              if (window.sessionStorage.getItem(item._id)) {
                continue
              }
              window.sessionStorage.setItem(item._id, true)
              $(`<div class="modal lightbox" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered modal-xl">
                                    <div class="modal-content bg-white">
                                        <div class="content"></div>
                                    </div>
                                </div>
                            </div>`
              )
                .find('.content')
                .html(item.content)
                .end()
                .on('hidden.bs.modal', function () {
                  $(this).remove()
                })
                .appendTo($('body'))
                .modal('show')
              continue
            }

            this.notifications.push(item)
          }

          this.notifications_unread_count = r.total
        }
      })
    },
    getMentions () {
      H.rpc('Mention', 'getMy', [{'new': true}], (r) => {
        if (r) {
          for (const item of r.data) {
            if (item.type === 'L') {
              if (window.sessionStorage.getItem(item._id)) {
                continue
              }
              window.sessionStorage.setItem(item._id, true)
              $(`<div class="modal lightbox" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered modal-xl">
                                    <div class="modal-content bg-white">
                                        <div class="content"></div>
                                    </div>
                                </div>
                            </div>`
              )
                .find('.content')
                .html(item.content)
                .end()
                .on('hidden.bs.modal', function () {
                  $(this).remove()
                })
                .appendTo($('body'))
                .modal('show')
              continue
            }

            this.mentions.push(item)
          }

          this.mentions_unread_count = r.total
        }
      })
    },
    checkPhoto (photo) {
      if(photo === null) {
        photo = 'img/clubedemidia-world-escudo.png'

        return photo
      }
      return photo
    },
    firstName (user_name) {
      user_name = user_name.split(' ')
      return user_name[0]
    },
    initEcho () {
      if (!window.Echo || !window.auth.user_id) {
        setTimeout(this.initEcho, 5000)
        return
      }

      Echo.channel('Public')
        .listen('Deployed', function () {
          showInfo('Sistema foi atualizado')
        })

      Echo.private('User.' + window.auth.user_id)
        .listen('Mention', data => {
          this.mentions_unread_count++
          this.mentions.push(data)
        })
        .listen('Message', data => {
          this.notifications_unread_count++
          this.notifications.push(data)
        })
    },
    removeMention (item, idx) {
      H.rpc('Mention', 'setRead', [item._id], (r) => {
        if (!r) {return}
        this.mentions_unread_count--
        this.mentions.splice(idx, 1)
      })
    },
    removeAllMentions () {
      let index = []
      for(index in this.mentions) {
        H.rpc('Mention', 'setRead', [this.mentions[index]._id], (r) => {
          if (!r) {return}
          this.mentions_unread_count = 0
          this.mentions = []
        })
      }
      this.getMentions()
    },
    removeNotification (item, idx) {
      H.rpc('Message', 'setRead', [item._id], (r) => {
        if (!r) {return}
        this.notifications.splice(idx, 1)
        this.notifications_unread_count--
      })
    },
    removeAllNotifications () {
      let index = []
      for(index in this.notifications) {
        H.rpc('Message', 'setRead', [this.notifications[index]._id], (r) => {
          if (!r) {return}
          this.notifications_unread_count--
          this.notifications = []
        })
      }
      this.getMessages()
    },
  }
}
</script>

<style lang="scss" scoped>
.hidden-box {
    max-height: 300px !important;
    overflow-x: hidden !important;;
    overflow-y: scroll !important;;
}
.custom-logo-position {
    margin-left: -22px;
}
</style>
