<template>
    <div class="comments">
        <div class="d-flex justify-content-between p-1 mb-1 fs12">
            <span class="font-weight-bold">Discussão do Ticket</span>
            <span class="text-muted">Total de mensagens: {{ comments.length }}</span>
        </div>

        <div ref="commentsHistoryContainer" class="border comments-history-container scroll-styled">
            <div class="comments-history">
                <div
                    v-for="comment in comments"
                    class="mb-1"
                >
                    <span class="user">{{ comment.user.name }}</span>
                    <span class="time">{{ formatElapsed(comment.created_at) }}</span>
                    <span class="content" v-html="comment.content"></span>
                </div>
            </div>
        </div>

        <label class="font-weight-bold mt-3">Deixe o seu comentário</label>
        <div
            class="d-flex justify-content-between mb-2"
        >
            <HtmlEditor
                ref="HtmlEditor"
                :toolbar="false"
                @enterKey="onEnterKey"
                class="flex-grow-1 mr-2"
            />
            <div class="d-flex align-items-center">
                <div v-if="posting" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <i v-else class="la la-2x la-send" @click="postComment()"></i>
            </div>
        </div>
    </div>
</template>
<script>
import {H} from 'hennig-common'

export default {
    name: 'CommentsPanel',
    props: {
        ticket_id: {}
    },
    data () {
        return {
            posting: false,
            comments: []
        }
    },
    watch: {
        ticket_id (v) {
            this.loadComments(v)
        }
    },
    methods: {
        loadComments (ticket_id = this.ticket_id) {
            H.rpc('Comment', 'load', [{ticket_id}], r => {
                if (!r) return
                this.comments = r

                this.$nextTick(() => {
                    this.$refs.commentsHistoryContainer && this.$refs.commentsHistoryContainer.scrollTo(0, this.$refs.commentsHistoryContainer.scrollHeight)
                })
            })
        },
        onEnterKey (evnt) {
            if (evnt.ctrlKey || evnt.shiftKey) {
                return
            }

            this.postComment()
        },
        postComment () {
            let comment = this.$refs.HtmlEditor.getValue()
            const mentions = this.$refs.HtmlEditor.getMentions()
            if (!comment) return
            if (this.posting) return
            this.posting = true
            comment = comment.replace(/<p><br><\/p>$/, '')
            H.rpc('Comment', 'post', [comment, {ticket_id: this.ticket_id, mentions}], r => {
                this.posting = false
                if (!r) return
                this.$refs.HtmlEditor.clear()
                this.loadComments()
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import "./resources/sass/variables";

.comments {
  .btn-send {
    min-width: 50px;
    width: 50px;
  }

  .comments-history-container {
    height: 300px;
    overflow-y: scroll;


    .comments-history {
      padding: 1em;

      .user {
        color: $blue;
        margin-right: .5em;
      }

      .time {
        color: silver;
        font-size: .75em;
        margin-right: .5em;
      }

      .content {
        :first-child {
          display: inline;
        }

        ::v-deep p {
          margin: 0;
        }
      }
    }
  }
}
</style>
