<template>
    <VueSelect
        v-model="current"
        :options="options"
        :reduce="row => row._id"
        label="name"
        class="form-control "
        style="padding-top: 2px"
        @input="emitInput"
        @search="fetchOptions"
        placeholder="- Responsável -"
    >
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                Nenhum desenvolvedor encontrado: <em>{{ search }}</em>.
            </template>
            <em v-else style="opacity: 0.5;">Digite para procurar um desenvolvedor...</em>
        </template>
    </VueSelect>
</template>

<script>
import {VueSelect} from 'vue-select'
import {H} from 'hennig-common'
import BaseComponent from './BaseComponent'

export default {
    name: 'SysSelect',
    extends: BaseComponent,
    components: {VueSelect},
    data () {
        return {
        }
    },
    methods: {
        setValue (v) {
            this.fetchOptions(v, (loading) => {
                if (loading === false) {
                    this.current = v
                    this.emitInput(v)
                }
            })
        },
        fetchOptions (search, loading) {
            search = search || this.current
            if (!search) return

            loading(true)
            this.options = []
            H.rpc('User', 'lookupSysGroup', [search], r => {
                if (r) {
                    this.options = r
                }

                loading(false)
            })
        }
    }
}
</script>

<style scoped>

</style>
