<template>
    <div class="container">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <span v-if="meeting_data">{{ meeting_data.name || 'Reunião' }}</span>
                <span v-else-if="form._id">Editar Reunião</span>
                <span v-else>Nova Reunião</span>
            </div>

            <div class="card-body">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Local</label>
                        <PlaceSelect
                            :disabled="read_only"
                            v-model="form.place_id"
                            @row="placeChanged"
                        />
                        <div class="row">
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.place_id"></div>
                        </div>
                        <div v-if="show_check_agenda" class="text-right">
                            <router-link
                                :to="{name:'MeetingCal',query:{place_id:form.place_id}}"
                                target="_blank">Conferir agenda</router-link>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label>Tipo de reunião</label>
                        <select v-model="form.type" :disabled="!!init_type" class="form-control custom-select">
                            <option value="">- Selecione -</option>
                            <option value="business">Área de Negócios</option>
                            <option value="customer">Com Cliente</option>
                            <option value="internal">Interna</option>
                            <option value="planning">Planejamento</option>
<!--                            <option value="manager">Diretoria</option>-->
<!--                            <option value="council">Conselho</option>-->
                        </select>
                        <div class="row">
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.type"></div>
                        </div>
                    </div>

                    <div v-if="require_address" class="col-lg-6">
                        <label>Endereço</label>
                        <input
                            :disabled="read_only"
                            v-model="form.place_address"
                            autocomplete="street-address"
                            class="form-control"
                        />
                        <div class="col-sm-6 invalid-feedback" v-text="feedbacks.place_address"></div>
                    </div>

                    <div v-if="require_address" class="col-lg-6">
                        <label>Cidade</label>
                        <input
                            :disabled="read_only"
                            v-model="form.place_city"
                            autocomplete="address-level2"
                            class="form-control"
                        />
                        <div class="col-sm-6 invalid-feedback" v-text="feedbacks.place_city"></div>
                    </div>

                    <div class="col-lg-6">
                        <label>Data/hora</label>
                        <DateTimePicker :disabled="read_only" v-model="form.start_at"/>
                        <div class="row">
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.start_at"></div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label>Duração</label>
                        <select v-model="form.duration" :disabled="read_only" class="form-control custom-select">
                            <option value="">- Selecione -</option>
                            <option value="30">0h30</option>
                            <option value="45">0h45</option>
                            <option value="60">1h00</option>
                            <option value="75">1h15</option>
                            <option value="90">1h30</option>
                            <option value="120">2h00</option>
                            <option value="150">2h30</option>
                            <option value="180">3h00</option>
                        </select>
                        <div class="row">
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.duration"></div>
                        </div>
                    </div>
                </div>

                <div v-show="form.type">
                    <div v-if="(form.type === 'planning' || form.type === 'customer') && !init_customer_id" class="form-group row">
                        <div class="col-lg-12">
                            <label>Cliente</label>
                            <CustomerSelect ref="customer_id" v-model="form.customer_id"/>
                            <div class="row">
                                <div class="col-sm-6 invalid-feedback" v-text="feedbacks.customer_id"></div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Pauta</label>
                            <input
                                :disabled="read_only"
                                v-model="form.name"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Informe a pauta"
                            />
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.name"></div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label>Ata</label>
                        <div class="col-lg-12">
                            <div v-if="read_only" v-html="form.content"></div>
                            <HtmlEditor v-else ref="content" v-model="form.content"/>
                            <div class="invalid-feedback" v-text="feedbacks.content"></div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Participantes</label>
                            <div
                                v-if="read_only"
                            >
                                <span v-for="user in form.users" class="mr-2">{{ user.name }}</span>
                            </div>
                            <MultiCheckbox
                                v-else
                                ref="users"
                                v-model="form.users"
                            />
                        </div>

                        <div v-if="form.type === 'customer'" class="col-lg-6">
                            <label>Participantes do cliente</label>
                            <MultiCheckbox
                                ref="customer_users"
                                v-model="form.customer_users"
                            />
                        </div>
                    </div>

                    <!--Nao mostra se estiver dentro de fases-->
                    <template v-if="!embed">
                        <div v-if="!show_repeat" class="form-row">
                            <div class="col-12">
                                <span class="link" @click="show_repeat = true">
                                    Repetir?
                                </span>
                            </div>
                        </div>

                        <div v-if="show_repeat" class="form-row">
                            <div class="col-12">
                                <label>
                                    Recorrência <i ref="recurrenceHelp" class='la la-question-circle'></i>
                                </label>
                            </div>
                            <div class="col-sm-6">
                                <input
                                    v-model="form.repeat_qty"
                                    class="form-control"
                                    placeholder="Qtde de repetições"
                                    type="number"
                                />
                            </div>
                            <div class="col-sm-6">
                                <select v-model="form.repeat_type" class="form-control custom-select">
                                    <option value="">- Selecione -</option>
                                    <option value="D">Diariamente</option>
                                    <option value="M">Mensal</option>
                                    <option value="W">Semanal</option>
                                    <option value="2W">Quinzenal</option>
                                </select>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div v-if="!read_only" class="card-footer d-flex justify-content-between">
                <SaveButton
                    ref="save"
                    @click="save()"
                />

                <button v-if="form._id" class="btn btn-danger btn-remove" @click="remove()">
                    <i class="la la-trash"></i> Remover
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {H, showError, showInfo} from 'hennig-common'
import {renderFeedback, setObj} from '../common'
import SaveButton from '../components/SaveButton'
import DateTimePicker from '../components/DateTimePicker'
import PlaceSelect from '../components/PlaceSelect'

const initial = {
    _id: '',
    active: 1,
    duration: '60',
    type: '',
    customer_id: '',
    ticket_id: '',
    ticket_detail_id: '',
    place_id: '',
    content: '',
    repeat_type: '',
    repeat_qty: '',
    start_at: '',
    customer_users: [],
    users: [],
}

export default {
    name: 'Meeting',
    components: {PlaceSelect, DateTimePicker, SaveButton},
    props: {
        embed: {Boolean, default: false},
        read_only: {},
        meeting_data: {},
        init_type: {String, default: ''},
        init_ticket_id: {String, default: ''},
        init_ticket_detail_id: {String, default: ''},
        init_customer_id: {String, default: ''}
    },
    data () {
        return {
            places: [],
            feedbacks: {},
            form: {...initial},
            due_at: '',
            job_name: '',
            show_repeat: false,
            show_check_agenda: false,
            require_address: false
        }
    },
    mounted () {
        this.form.customer_id = window.sessionStorage.getItem('customer_id') || ''
        window.sessionStorage.setItem('customer_id', '')

        this.form.place_id = window.sessionStorage.getItem('place_id') || ''
        window.sessionStorage.setItem('place_id', '')

        this.form.start_at = window.sessionStorage.getItem('start_at') || ''
        window.sessionStorage.setItem('start_at', '')

        if (this.meeting_data) {
            setObj(this.form, this.meeting_data)
        }

        this.updateComponents()

        this.load().then(() => {
            this.init()
            this.initPopover()
            this.loadUsers()
            this.loadPlaces()
            this.loadUsersCustomer()
        })
    },
    watch: {
        'form.customer_id' (v) {
            this.loadUsersCustomer()
        }
    },
    methods: {
        placeChanged (place) {
            this.require_address = place.require_address
            this.show_check_agenda = place.show_check_agenda
        },
        initPopover () {
            const content = `
* As repetições ocorrerão até o final do ano<br>
* Será antecipado se a repetição ocorrer em final de semana, feriado ou final de mês<br>
<br>
Diariamente - Todos os dias<br>
Mensal - Sempre no mesmo dia do mês<br>
Semanal - Sempre no mesmo dia da semana<br>
Quinzenal - A cada duas semanas sempre no mesmo dia da semana<br>
`
            $(this.$refs.recurrenceHelp).popover({
                content,
                html: true,
                trigger: 'hover'
            })
        },
        init () {
            if (this.init_type) {
                this.form.type = this.init_type
            }

            if (this.init_customer_id) {
                this.form.customer_id = this.init_customer_id
            }

            if (this.init_ticket_id) {
                this.form.ticket_id = this.init_ticket_id
            }

            if (this.init_ticket_detail_id) {
                this.form.ticket_detail_id = this.init_ticket_detail_id
            }

            this.updateComponents()
        },
        updateComponents () {
            this.$nextTick(() => {
                if (this.$refs.customer_id) this.$refs.customer_id.setValue(this.form.customer_id)
                this.$refs.content && this.$refs.content.setValue(this.form.content)
            })
        },
        fieldClass (name) {
            return {
                'is-invalid': this.feedbacks[name]
            }
        },
        load () {
            return new Promise(resolve => {
                const _id = this.$route.params._id
                if (!_id) {
                    resolve()
                    return
                }
                H.rpc('Meeting', 'getFormData', [_id], r => {
                    setObj(this.form, r)

                    resolve()
                })
            })
        },
        loadUsers () {
            H.rpc('User', 'lookupUsers', [''], options => {
                if (options) {
                    if (this.$refs.users) {
                        this.$refs.users.setProps({options})
                        this.$refs.users.setValue(this.form.users)
                        this.$refs.users.selectById(window.auth.user_id)
                    }
                }
            })
        },
        loadPlaces () {
            H.rpc('MeetingPlace', 'lookup', [''], options => {
                if (options) {
                    this.places = options
                }
            })
        },
        loadUsersCustomer () {
            if (!this.$refs.customer_users) return
            H.rpc('UserCustomer', 'lookup', ['', {customer_id: this.form.customer_id}], options => {
                if (options) {
                    this.$refs.customer_users.setProps({options})

                    this.$refs.customer_users && this.$refs.customer_users.setValue(this.form.customer_users)
                }
            })
        },
        remove () {
            this.dialogs()
                .Confirmation
                .show({text: 'Deseja realmente excluir?'}, r => {
                    if (r) {
                        H.rpc('Meeting', 'delete', [this.form._id], r => {
                            if (r) {
                                showInfo('Esta reunião foi excluída do sistema')
                                setTimeout(() => {
                                    this.$router.replace('/tickets')
                                }, 1000)
                            }
                        })
                    }
                })
        },
        save () {
            for (let i in this.feedbacks) {
                this.feedbacks[i] = ''
            }

            this.$refs.save.start()
            H.rpc('Meeting', 'save', [this.form], (r, e) => {
                if (e) {
                    this.$refs.save.fail()
                    if (!renderFeedback(this, e)) {
                        showError(e.message)
                    }
                    return true
                }

                if (r) {
                    showInfo(r.message)
                    this.$refs.save.done()
                    setObj(this.form, r.data)
                    setTimeout(() => {
                        this.$router.replace('/tickets')
                    }, 1000)
                }
            })
        }
    }
}
</script>

<style scoped>
.invalid-feedback {
    display: initial;
}

.btn-remove {
    min-width: 120px;
}

</style>
