<template>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12 col-12 d-flex justify-content-center pt-2 scrollInnerBox">
        <!-- <div id="support-trackers-chart"></div> -->
        <apexchart
          type="bar"
          height="300"
          width="1750"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {AlertTriangleIcon,FrownIcon,ActivityIcon,SmileIcon} from 'vue-feather-icons'

export default {
  name: 'AllTicketsGraph',
  components: {AlertTriangleIcon,FrownIcon,ActivityIcon,SmileIcon,VueApexCharts},
  props: {
    series: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter (val) {
            return val
          },
          // offsetY: -20,
          style: {
            fontSize: '10px',
            colors: ['#5e5873']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        },
        yaxis: {
          title: {
            text: ''
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter (val) {
              return val + ' tickets'
            }
          }
        },
      },
    }
  },
  watch: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped>
.scrollInnerBox {
    overflow: auto;
}
</style>
