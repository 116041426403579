<template>
    <BaseModal
        ref="BaseModal"
        title="Apropriar-se"
    >
        <div class="font-weight-bold">
            Deseja realmente apropriar-se desta fase?
        </div>
        <template v-slot:footer>
            <div class="btn btn-primary" @click="confirm()">Sim</div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'

export default {
    name: 'ConfirmBeOwner',
    components: {BaseModal},
    methods: {
        show (callback) {
            this.$refs.BaseModal.show()
            this.callback = callback
        },
        confirm () {
            this.callback(true)
            this.$refs.BaseModal.hide()
        }
    }
}
</script>

<style scoped>

</style>
