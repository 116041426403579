<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>Logs de envio de e-mails</h3>
        <div class="dropdown-divider"></div>
        <h5>Registros de envio e leitura de e-mail</h5>
        <hr>
        <table ref="grid" class="table table-sm table-hover table-striped">
          <thead>
            <tr>
              <th data-column-id="_id" data-visible="false">ID</th>
              <th
                data-align="center"
                data-column-id="viewBody"
                data-formatter="viewBody"
                data-sortable="false"
                data-width="36px"
              ></th>
              <th data-column-id="to_email" data-sortable="true">E-mail</th>
              <th data-column-id="subject" data-sortable="true">Tipo</th>
              <th data-column-id="status" data-sortable="true">Status</th>
              <th data-column-id="sent_at" data-formatter="dt">Enviado</th>
              <th data-column-id="read_at" data-formatter="dt">Lido</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H, initGrid} from 'hennig-common'

export default {
  name: 'EmailLogs',
  data () {
    return {
      search: {}
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const noAddButton = true
      const collectionObj = 'Email'

      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        rowClick: true,
        noAddButton,
        search: () => this.search,
        actions: [
          {
            name: 'viewBody',
            icon: 'la-external-link-square',
            handler (_id) {
              H.rpc(collectionObj, 'view', [_id], function (r) {
                if (!r) {
                  return
                }
                H.createForm(r)
              })
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped>

</style>
