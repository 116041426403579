<template>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12 col-12 d-flex justify-content-center pt-2">
                <!-- <div id="support-trackers-chart"></div> -->
                <apexchart 
                  type="polarArea" 
                  width="452"
                  :options="chartOptions" 
                  :series="series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: 'MultipleRadialGraph',
    components: {VueApexCharts},
    props: {
        series: {
            type: Array,
            default: []
        },
    },
    data () {
        return {
          chartOptions: {
            chart: {
              type: 'polarArea',
            },
            stroke: {
              colors: ['#fff']
            },
            fill: {
              opacity: 0.8
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            labels: ["Bowser","Yoshi","Luigi","Goomba","Mario","Toad"],
          },
        }
    },
    watch: {

    },
    mounted () {

    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
