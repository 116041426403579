<template>
    <BaseModal
        v-if="visible"
        ref="BaseModal"
        title="Adicionar texto livre"
    >
        <div class="mt-2">
            <label>Digite aqui a informação</label>
            <HtmlEditor
                v-model="content"
            />
        </div>
        <div class="mt-2">
            <label class="col-12">Anexar arquivos</label>
            <UploadsEditor
                :param="{ customer_id, ticket_id, ticket_detail_id }"
            />
        </div>
        <template v-slot:footer>
            <button :disabled="!content" class="btn btn-primary" @click="confirm()">Adicionar</button>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'

export default {
    name: 'ConfirmAskDetail',
    components: {BaseModal},
    data () {
        return {
            visible: false,
            customer_id: '',
            ticket_detail_id: '',
            ticket_id: '',
            content: ''
        }
    },
    methods: {
        async show ({ customer_id, ticket_id, ticket_detail_id }, callback) {
            this.visible = true
            await this.$nextTick()

            this.customer_id = customer_id
            this.ticket_id = ticket_id
            this.ticket_detail_id = ticket_detail_id
            this.$refs.BaseModal.show()
            this.callback = callback
        },
        confirm () {
            this.callback(this.content)
            this.$refs.BaseModal.hide()
            this.visible = false
        }
    }
}
</script>

<style scoped>

</style>
