import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'

const QuaTickets = () => import(/* webpackChunkName: "QuaTickets" */'../views/QuaTickets')
const QuaTicketTypes = () => import(/* webpackChunkName: "QuaTicketTypes" */'../views/QuaTicketTypes')

const authRequired = true

export default [
    {
        path: '/qua/tickets',
        components: {default: QuaTickets, header: Logged},
        meta: {authRequired}
    },
    {
        name: 'QuaTicket',
        path: '/qua/ticket/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'QuaTicket'}},
        meta: {authRequired, backRoute: '/qua/tickets'}
    },
    {
        path: '/qua/tipos',
        components: {default: QuaTicketTypes, header: Logged},
        meta: {authRequired}
    },
    {
        name: 'QuaTicketType',
        path: '/qua/tipo/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'QuaTicketType'}},
        meta: {authRequired, backRoute: '/qua/tipos'}
    }
]
