<template>
  <div>
    <div class="row mb-1">
      <div class="col">
        <div
          class="btn btn-primary btn-sm"
          @click="addContract()"
        >Adicionar contrato
        </div>
      </div>
      <div class="col text-right">
        <div
          class="btn btn-danger btn-sm"
          @click="reloadContracts()"
        >Reprocessar contratos do cliente
        </div>
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Tipo de contrato</th>
          <th>{{ coin_name }} de contrato</th>
          <th>Data de assinatura</th>
          <th>Data de encerramento</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!contracts.length">
          <td colspan="5" class="text-center">Sem contratos</td>
        </tr>
        <template
          v-else
          v-for="row in contracts"
        >
          <tr @click="addContract(row._id)">
            <td>{{ row.contract_type.name }}</td>
            <td v-text="row.contract_time"></td>
            <td>{{ format(row.contract_start_date) }}</td>
            <td>{{ format(row.contract_end_date) }}</td>
            <td>{{ row.active ? 'Sim' : 'Não' }}</td>
          </tr>
          <tr v-if="row.contract_type.is_managed || row.contract_type._id === '9215d0c0-fb9b-4823-8f99-1ec2a013c704'">
            <td colspan="5">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Ano/Mês<br />de apuração</th>
                    <th>Saldo do<br />Mês Anterior</th>
                    <th>Ajuste Interno<br />de {{ coin_name }}</th>
                    <th>{{ coin_name }}<br />Contratados</th>
                    <th>{{ coin_name }}<br />Líquidos</th>
                    <th>{{ coin_name }}<br />Utilizados</th>
                    <th>Saldo<br />de {{ coin_name }}</th>
                    <th>{{ coin_name }} Extras a<br /> Compensar (Até 30%)</th>
                    <th>{{ coin_name }} Extras a Pagar<br/> (Acima de 30%)</th>
                    <th>{{ coin_name }} Extras a <br />Pagar ou a Compensar</th>
                  </tr>
                </thead>
                <tbody v-if="row.contract_type._id === '9215d0c0-fb9b-4823-8f99-1ec2a013c704'">
                  <tr v-for="item in row.contract_items">
                    <td>-</td>
                    <td>{{ item.year_month }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ item.balance }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="item in row.contract_items">
                    <td class="text-center">
                      <trash-icon class="cursor-pointer text-danger" size="1.5x" v-on:click="deleteItem(item)"></trash-icon>
                    </td>
                    <td>{{ item.year_month }}</td>
                    <td>{{ item.previous_balance }}</td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        v-model="item.manual"
                        size="3"
                        @blur="save(item)"
                        :disabled="loading"
                      />
                    </td>
                    <td>{{ item.contract_time }}</td>
                    <td>{{ item.previous_balance_contract_time }}</td>
                    <td>{{ item.work_time }}</td>
                    <td>{{ item.balance }}</td>
                    <td>{{ item.extra_time_regular }}</td>
                    <td>{{ item.extra_time }}</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        v-model="item.payment_option"
                        @change="save(item)"
                        :disabled="loading"
                      >
                        <option value="T">Compensar Tudo</option>
                        <option value="C">Compensar Normais e Pagar Extras</option>
                        <option value="P">Pagar Tudo</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {H, showInfo, showError} from 'hennig-common'
import {setArr} from '../common'
import BaseComponent from './BaseComponent'
import {TrashIcon} from 'vue-feather-icons'

export default {
  name: 'ContractsEditor',
  components: {
    TrashIcon
  },
  extends: BaseComponent,
  data () {
    return {
      customer_id: '',
      contracts: [],
      loading: false,
      coin_name: 'Escudos'
    }
  },
  watch: {
    customer_id (new_value) {
      if (new_value) {
        this.load()
      }
    }
  },
  beforeMount () {
    H.rpc('Customer', 'getCoinName', [], r => {
      if (r) {
        this.coin_name = r.coin_name
      }
    })
  },
  methods: {
    addContract (_id = '') {
      const that = this
      H.rpc('Contract', 'form', [_id, {customer_id: this.customer_id}], r => {
        if (r) {
          H.createForm(r, {
            onstore (evnt, data, $form) {
              that.load()
              $form.closest('.modal').modal('hide')
            }
          })
        }
      })
    },
    save (item) {
      this.loading = true
      H.rpc('Contract', 'saveItem', [item._id, item], r => {
        if (r) {
          showInfo('Salvou')
          this.load()
        }
      })
    },
    load () {
      H.rpc('Contract', 'load', [this.customer_id], rows => {
        if (rows) {
          setArr(this, 'contracts', rows)
          this.loading = false
        }
      })
    },
    deleteItem (item) {
      if (confirm('Deseja realmente excluir esse registro?')) {
        this.loading = true
        H.rpc('Contract', 'deleteItem', [item._id, item], r => {
          if (r) {
            showInfo('Salvou')
            this.load()
          }
        })
      }
    },
    reloadContracts () {
      if (confirm('Deseja realmente reprocessar os contratos desse cliente?')) {
        this.loading = true
        H.rpc('Contract', 'reloadContracts', [this.customer_id], r => {
          if (r) {
            showInfo('Salvou')
            this.load()
          }
        })
      }
    },
    getContractTimeLabel (time) {
      return time/60 + 'h | ' + time + 'min'
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
  th {
    font-size: 0.7rem;
    background-color: rgba(0, 0, 0, 0.125);
  }
}
</style>
