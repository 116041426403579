<template>
    <div class="swiper-container">
        <div class="swiper-control">
            <i :class="{disabled: isBeginning}" class="la la-angle-double-left" @click="left()"></i>
            <i :class="{disabled: isEnd}" class="la la-angle-double-right" @click="right()"></i>
        </div>
        <div class="swiper-wrapper timeline">
            <div v-for="(item, index) in steps" class="swiper-slide">
                <div class="timestamp">
                    <span class="date">{{ formatShort(item.dti) }} a {{ formatShort(item.dtf) }}</span>
                </div>
                <div class="circle-container">
                    <div :class="{completed: item.completed, active: item.active}" class="circle">
                        <i :class="laCheck(item.completed, item.active, index, steps)" class="la"></i>
                    </div>
                </div>
                <div class="status">
                    <span>{{ item.phase_name }}</span>
                </div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import moment from 'moment'
import Swiper from 'swiper'


export default {
    name: 'Timeline',
    props: {
        steps: {}
    },
    data () {
        return {
            isBeginning: false,
            isEnd: false
        }
    },
    mounted () {
    },
    watch: {
        steps: {
            async handler (steps) {
                await this.$nextTick()

                if (!this.swiper) {
                    this.swiper = new Swiper(this.$el, {
                        slidesPerView: 2,
                        breakpoints: {
                            576: {
                                slidesPerView: 4
                            },
                            768: {
                                slidesPerView: 6
                            },
                            992: {
                                slidesPerView: 8
                            }
                        },
                        paginationClickable: true,
                        grabCursor: true
                    })

                    this.swiper.on('slideChange', () => {
                        this.isBeginning = this.swiper.isBeginning
                        this.isEnd = this.swiper.isEnd
                    })
                }

                this.swiper.updateSlides()
                this.isEnd = this.swiper.isEnd
                this.isBeginning = this.swiper.isBeginning

                for (const i in steps) {
                    if (steps[i].active) {
                        this.swiper.slideTo(i)
                        return
                    }
                }
            },
            deep: true
        }
    },
    methods: {
        format (dt) {
            const m = moment(dt)
            return m.format('L')
        },
        left () {
            this.swiper.slidePrev()
        },
        right () {
            this.swiper.slideNext()
        },
        laCheck (itemCompleted, itemActive, index, stepsItem) {
            if (index == stepsItem.length - 1) {
                return ('la-flag-checkered')
            } else {
                if (itemCompleted)
                    return ('la-check')

                if (itemActive)
                    return ('la-map-marker-alt')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/variables";
@import '~swiper/swiper-bundle.css';

.timeline {
  display: flex;
}

.timeline li {
  transition: all 200ms ease-in;
}

.timestamp {
  text-align: center;
  margin-bottom: 1em;
}

.status {
  display: flex;
  justify-content: center;
  border-top: 3px solid #c3c3c3;
  transition: all 200ms ease-in;
}

.status span {
  padding-top: 1em;
}

.circle-container {
  display: flex;
  justify-content: center;
  position: relative;

  .circle {
    width: 24px;
    height: 24px;
    background-color: $body-bg;
    border-radius: 24px;
    border: 3px solid #c3c3c3;
    position: absolute;
    transition: all 200ms ease-in;
    top: -12px;

    &.completed {
      background-color: #c3c3c3;
    }

    &.active {
      background-color: $green;
      border-color: $green;
    }

  }
}

.swiper-control {
  text-align: right;
}

.swiper-container {
  width: 100%;
  height: 140px;
  overflow: hidden;
}

.swiper-slide {
  width: 300px;
  text-align: center;
}

.swiper-control {
  display: flex;
  justify-content: space-between;
  height: 1em;

  i {
    cursor: pointer;
  }
}

.la {
  left: -1px;
  position: relative;
}

.disabled {
  opacity: .1;
}
</style>
