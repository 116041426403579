<template>
  <div v-if="current_detail" class="main">
    <div class="form-group row">
      <div class="col-12">
        <div class="row p-2 d-block">
          <label><b class="mr-4 pt-1">Combo: {{ current_detail.phase_name }}</b></label>
          <button v-if="current_detail.active" class="btn btn-primary btn-sm float-right" @click="add">Adicionar serviços</button>
        </div>
      </div>
      <div class="col-12">
        <table class="table table-striped">
          <tr>
            <th>Número</th>
            <th>Serviço</th>
            <th>Fase</th>
            <th>Responsável</th>
            <th>Status</th>
            <th>Entrega</th>
            <th :style="{textAlign: 'center'}">...</th>
          </tr>
          <tr v-for="row in jobs" :key="row._id" @click="viewTicket(row)">
            <td>{{ row.num }}</td>
            <td>{{ row.job_type.name }}</td>
            <td>{{ row.phase.name }}</td>
            <td>{{ row.user.name }}</td>
            <td v-bind:class="row.status">{{ row.status }}</td>
            <td>{{ format(row.due_at) }}</td>
            <td :style="{textAlign: 'center'}">
              <i class="la la-unlink link" title="Desvincular" @click.stop="unlinkSubTask(row)"></i>
              <!--                            <i class="la la-trash link" @click.stop="removeSubTask(row)"></i>-->
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="form-group row">

    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'

export default {
  name: 'ComboBody',
  props: {
    current_detail: {},
    form: {}
  },
  data () {
    return {
      jobs: [],
      content: '',
      classes: '',
    }
  },
  watch: {},
  mounted () {
    this.load()
  },
  methods: {
    unlinkSubTask (row) {
      this.dialogs()
        .Confirmation
        .show({text: 'Deseja realmente desvincular este ticket?'}, r => {
          if (!r) {return}
          H.rpc('Ticket', 'unlinkSubTask', [row._id], r => {
            if (!r) {return}
            this.load()
          })
        })
    },
    removeSubTask (row) {
      // this.dialogs()
      //     .Confirmation
      //     .show({text: 'Deseja realmente excluir este job do combo?'}, r => {
      //         if (!r) return
      //         H.rpc('Ticket', 'removeSubTask', [row._id], r => {
      //             if (!r) return
      //             this.load()
      //         })
      //     })
    },
    viewTicket (row) {
      let routeData = this.$router.resolve({name: 'Ticket', params: {_id: row._id}})
      window.open(routeData.href, '_blank')
    },
    add () {
      const params = {
        customer_id: this.form.customer_id,
        combo_ticket_id: this.form._id
      }

      this.dialogs().AddTicketCombo.show(params, () => {
        this.load()
      })
    },
    load () {
      this.jobs = []
      H.rpc('Ticket', 'allCombos', [this.form._id], rows => {
        if (rows) {
          this.jobs = rows
        }
      })
    }
  }
}
</script>

<style scoped>
.main {
    min-height: 300px;
}

.table__container {
    max-height: 400px;
}

.Aberto {
    color: #7367f0 !important;
    font-weight: 600;
}

.Fechado {
    color: #ff9f43 !important;
    font-weight: 600;
}

.Cancelado {
    color: #ea5455 !important;
    font-weight: 600;
}

.Aguardando {
    color: #00cfe8 !important;
    font-weight: 600;
}
</style>
