<template>
    <div class="container-fluid">
        <div ref="profile"></div>
    </div>
</template>

<script>
import {H} from 'hennig-common'

export default {
    name: 'Profile',
    mounted () {
        this.createForm()
    },
    methods: {
        createForm () {
            $(this.$refs.profile).empty()
            H.rpc('Profile', 'form', [], (r) => {
                if (!r) return
                H.createForm(r, {
                    container: this.$refs.profile,
                    onstore () {
                        // redirect('/')
                    }
                })
            })
        }
    }
}
</script>

<style scoped>
</style>
