import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'
import CrmOpportunityOrigin from '../views/CrmOpportunityOrigin'
import CrmReasons from '../views/CrmReasons'
import CrmLead from '../views/CrmLead'
import CrmFunnels from '../views/CrmFunnels'
import CrmOpportunities from '../views/CrmOpportunities'
const authRequired = true

export default [
  {
    path: '/crm/origemoportunidades',
    components: {default: CrmOpportunityOrigin, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CrmOpportunityOrigin',
    path: '/crm/origemoportunidade/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CrmOpportunityOrigin'}},
    meta: {authRequired, backRoute: '/crm/origemoportunidades'}
  },
  {
    path: '/crm/motivos',
    components: {default: CrmReasons, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CrmReasons',
    path: '/crm/motivos/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CrmReasons'}},
    meta: {authRequired, backRoute: '/crm/motivos'}
  },
  {
    path: '/crm/lead',
    components: {default: CrmLead, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CrmLead',
    path: '/crm/lead/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CrmLead'}},
    meta: {authRequired, backRoute: '/crm/lead'}
  },
  {
    path: '/crm/funil',
    components: {default: CrmFunnels, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CrmFunnel',
    path: '/crm/funil/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CrmFunnel'}},
    meta: {authRequired, backRoute: '/crm/funil'}
  },
  {
    path: '/crm/oportunidades',
    components: {default: CrmOpportunities, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CrmOpportunity',
    path: '/crm/oportunidade/:id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CrmOpportunity'}},
    meta: {authRequired, backRoute: '/crm/oportunidades'}
  },
]
