<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>Listagem de leads</h3>
        <div class="dropdown-divider"></div>
        <h5>Aqui vamos listar os leads</h5>
        <section id="accordion">
          <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
            <div id="headingticket" class="card-header pl-0 collapsed" data-toggle="collapse" role="button" data-target="#accordionticket" aria-expanded="false" aria-controls="accordionticket">
              <span class="btn btn-primary col"> Ver filtros </span>
            </div>
            <div id="accordionticket" role="tabpanel" data-parent="#accordionWrapa1" aria-labelledby="headingticket" class="collapse" style="">
              <div class="container-fluid search-container">
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Base de envio</label>
                    <input type="text" class="form-control" v-model="search.base_envio">
                  </div>
                  <div class="col-lg-6">
                    <label>Documento do cliente</label>
                    <input type="text" class="form-control" v-model="search.doc_cliente">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Data de nascimento</label>
                    <input type="text" class="form-control" v-model="search.data_nascimento">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Razão social</label>
                    <input type="text" class="form-control" v-model="search.razao_social">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Capital Social</label>
                    <input type="text" class="form-control" v-model="search.capital_social">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>CNAE Lead</label>
                    <input type="text" class="form-control" v-model="search.cnae_lead">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Descrição CNAE do lead</label>
                    <input type="text" class="form-control" v-model="search.descricao_cnae_lead">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>CNAEs secundários</label>
                    <input type="text" class="form-control" v-model="search.cnaes_secundarios">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Nome fantasia da empresa</label>
                    <input type="text" class="form-control" v-model="search.nome_fantasia">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Onde o email foi captado</label>
                    <input type="text" class="form-control" v-model="search.local_aquisicao">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Nome do contato do email</label>
                    <input type="text" class="form-control" v-model="search.contato_email">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Sexo</label>
                    <input type="text" class="form-control" v-model="search.sexo">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>UF</label>
                    <input type="text" class="form-control" v-model="search.uf">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Cidade</label>
                    <input type="text" class="form-control" v-model="search.cidade">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>CEP</label>
                    <input type="text" class="form-control" v-model="search.cep">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Endereço</label>
                    <input type="text" class="form-control" v-model="search.endereco">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Bairro</label>
                    <input type="text" class="form-control" v-model="search.bairro">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Tipo Cliente</label>
                    <input type="text" class="form-control" v-model="search.tipo_cliente">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Perfil de cliente</label>
                    <input type="text" class="form-control" v-model="search.perfil_cliente">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Produto contratado</label>
                    <input type="text" class="form-control" v-model="search.produto_contratado">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Status do contrato</label>
                    <input type="text" class="form-control" v-model="search.status_contrato">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Data de contratação</label>
                    <input type="text" class="form-control" v-model="search.data_contratacao">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Data do cancelamento</label>
                    <input type="text" class="form-control" v-model="search.data_cancelamento">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Email para disparo</label>
                    <input type="text" class="form-control" v-model="search.email_disparo">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Telefone comercial</label>
                    <input type="text" class="form-control" v-model="search.telefone_comercial">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Telefone celular</label>
                    <input type="text" class="form-control" v-model="search.telefone_celular">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Telefone residencial</label>
                    <input type="text" class="form-control" v-model="search.telefone_residencial">
                  </div>
                  <div class="col-lg-6 mt-1">
                    <label>Contato whatsapp</label>
                    <input type="text" class="form-control" v-model="search.contato_whatsapp">
                  </div>
                  <div class="col-lg-3">
                    <label>&nbsp;</label>
                    <div class="d-flex">
                      <button class="btn btn-primary mr-2" @click="load()">Consultar</button>
                      <button class="btn btn-primary" @click="exportar()">Exportar</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <hr>
        <table class="table table-responsive overflow-auto table-hover table-striped tableCrmLead">
          <thead>
            <tr>
              <th data-column-id="_id" data-visible="false">ID</th>
              <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>
              <th data-column-id="base_envio" data-sortable="true" data-order="asc">Base de envio</th>
              <th data-column-id="doc_cliente" data-sortable="true" data-order="asc">Documento do cliente</th>
              <th data-column-id="data_nascimento" data-sortable="true" data-order="asc">Data de nascimento</th>
              <th data-column-id="razao_social" data-sortable="true" data-order="asc">Razão social</th>
              <th data-column-id="capital_social" data-sortable="true" data-order="asc">Capital Social</th>
              <th data-column-id="cnae_lead" data-sortable="true" data-order="asc">CNAE Lead</th>
              <th data-column-id="descricao_cnae_lead" data-sortable="true" data-order="asc">Descrição CNAE do lead</th>
              <th data-column-id="cnaes_secundarios" data-sortable="true" data-order="asc">CNAEs secundários</th>
              <th data-column-id="nome_fantasia" data-sortable="true" data-order="asc">Nome fantasia da empresa</th>
              <th data-column-id="local_aquisicao" data-sortable="true" data-order="asc">Onde o email foi captado</th>
              <th data-column-id="contato_email" data-sortable="true" data-order="asc">Nome do contato do email</th>
              <th data-column-id="sexo" data-sortable="true" data-order="asc">Sexo</th>
              <th data-column-id="uf" data-sortable="true" data-order="asc">UF</th>
              <th data-column-id="cidade" data-sortable="true" data-order="asc">Cidade</th>
              <th data-column-id="cep" data-sortable="true" data-order="asc">CEP</th>
              <th data-column-id="endereco" data-sortable="true" data-order="asc">Endereço</th>
              <th data-column-id="bairro" data-sortable="true" data-order="asc">Bairro</th>
              <th data-column-id="tipo_cliente" data-sortable="true" data-order="asc">Tipo de cliente</th>
              <th data-column-id="perfil_cliente" data-sortable="true" data-order="asc">Perfil de cliente</th>
              <th data-column-id="produto_contratado" data-sortable="true" data-order="asc">Produto contratado</th>
              <th data-column-id="status_contrato" data-sortable="true" data-order="asc">Status do contrato</th>
              <th data-column-id="data_contratacao" data-sortable="true" data-order="asc">Data de contratação</th>
              <th data-column-id="data_cancelamento" data-sortable="true" data-order="asc">Data do cancelamento</th>
              <th data-column-id="email_disparo" data-sortable="true" data-order="asc">Email para disparo</th>
              <th data-column-id="telefone_comercial" data-sortable="true" data-order="asc">Telefone comercial</th>
              <th data-column-id="telefone_celular" data-sortable="true" data-order="asc">Telefone celular</th>
              <th data-column-id="telefone_residencial" data-sortable="true" data-order="asc">Telefone residencial</th>
              <th data-column-id="contato_whatsapp" data-sortable="true" data-order="asc">Contato whatsapp</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H, initGrid, showInfo} from 'hennig-common'
import {showError} from 'hennig-common/src/lib/notifications'

export default {
  name: 'CrmLead',
  data () {
    return {
      search: {},
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const addLabel = 'Novo'
      const collectionObj = 'CrmLead'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => this.search,
        rowClick: true,
        addLabel,
        noActiveFilter: true,
        noPrint: true,
      })
    },
    load () {
      let item = document.querySelector('[title="Atualizar"]')
      item.click()
    },
    exportar () {
      H.rpc('CrmLead', 'crmExportItems', [{search: this.search}], (rows) => {
        if (rows === 'Sucesso') {
          showInfo('Arquivo agendado para download. Você será notificado ao final do processo.')
        } else {
          showError('Erro ao atualizar!')
        }

        this.textButton = 'Enviar'
      })
    }
  }
}
</script>

<style scoped>
.search.form-group {
    display: none !important;
}
</style>
