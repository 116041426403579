<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <img src="/img/clubedemidia-world-escudo.png" class="d-none d-print-inline"/>
          <h3>Meus pedidos</h3>
        </div>
        <div class="dropdown-divider"></div>
      </div>

      <div class="card-body d-print-none">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Data Inicial</label>
            <DatePicker
              init_placeholder="- Escolher data -"
              v-model="search.dataini"
            />
          </div>
          <div class="col-lg-6">
            <label>Data Final</label>
            <DatePicker
              init_placeholder="- Escolher data -"
              v-model="search.datafim"
            />
          </div>
          <div class="col-lg-12">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="load()" v-text="textoBotao"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <th>Data</th>
            <th>Hora</th>
            <th>Total</th>
            <th>Visualizar</th>
          </thead>
          <tbody>
            <tr v-if="!result.length">
              <td colspan="4" class="text-center">Sem resultado</td>
            </tr>
            <tr v-else v-for="row in result">
              <td>{{ row.order_fdate }}</td>
              <td>{{ row.order_ftime }}</td>
              <td>{{ row.total_formated }}</td>
              <td class="text-center width-5-per"><eye-icon class="cursor-pointer" size="1.5x" v-on:click="view(row._id)"></eye-icon></td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="result.length">
              <td colspan="2" class="text-right font-weight-bolder">Total</td>
              <td colspan="2" class="font-weight-bolder" v-text="total"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'
import {setArr} from '../common'
import DatePicker from '../components/DatePicker'
import {EyeIcon} from 'vue-feather-icons'

export default {
  name: 'CoffeeOrderDetail',
  components: {
    DatePicker,
    EyeIcon
  },
  data () {
    return {
      search: {
        dataini: '',
        datafim: ''
      },
      result: [],
      total: 0,
      textoBotao: 'Consultar'
    }
  },
  methods: {
    fillCustomer (row) {
      this.customer_display_name = row.trade_name
    },
    view (id) {
      window.open('/#/cafeteria/pedido/detalhe/' + id, '_blank')
    },
    load () {
      setArr(this, 'result', [])
      let self = this
      this.textoBotao = 'Consultando'
      H.rpc('CoffeeOrder', 'getMy', [this.search], rows => {
        if (rows) {
          self.textoBotao = 'Consultar'
          self.total = rows.total
          setArr(this, 'result', rows.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
    th {
        font-size: 0.7rem;
        background-color: rgba(0, 0, 0, 0.125);
    }
}
</style>
