<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Sair</div>
                    <div class="card-body">
                        <p>Deseja sair do sistema?</p>
                    </div>
                    <div class="card-footer text-center">
                        <button class="btn btn-outline-primary" v-on:click="logout()">Sair</button>
                        <a class="btn btn-outline-secondary" href="/">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {H} from 'hennig-common'

export default {
    name: 'Logout',
    methods: {
        logout () {
            H.rpc('Auth', 'logout', [], (r) => {
                if (!r) return
                window.auth = null

                location.href = '/'
            })
        }
    }
}
</script>

<style scoped>
    .btn {
        min-width: 120px;
    }
</style>
