<template>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12 col-12 d-flex justify-content-center pt-2">
                <!-- <div id="support-trackers-chart"></div> -->
                <apexchart 
                    type="donut" 
                    width="452"
                    :options="chartOptions" 
                    :series="series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {AlertTriangleIcon,FrownIcon,ActivityIcon,} from "vue-feather-icons";

export default {
    name: 'DonutGraph',
    components: {AlertTriangleIcon,FrownIcon,ActivityIcon,VueApexCharts},
    props: {
        series: {
            type: Array,
            default: []
        },
    },
    data () {
        return {
          chartOptions: {
            chart: {
              type: 'donut',
            },
            labels: ["Bowser","Yoshi","Luigi","Goomba","Mario","Toad"],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        }
    },
    watch: {
        
    },
    mounted () {

    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
