<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Cadastro de fornecedores</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui vamos cadastrar e editar toda a base de fornecedores da empresa, centralizando todas as informações</h5>
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
                        <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>
                        <th data-align="center" data-column-id="x" data-formatter="x" data-sortable="false" data-width="36px"></th>
                        <th data-align="center" data-column-id="active" data-formatter="checkbox" data-width="36px"></th>
                        <th data-column-id="name" data-order="asc" data-sortable="true">Razão Social</th>
                        <th data-column-id="trade_name" data-sortable="true">Nome Fantasia</th>
                        <th data-column-id="doc">CNPJ</th>
                        <th data-column-id="tel">Telefone</th>
                        <th data-column-id="email">E-mail</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'

export default {
    name: 'Suppliers',
    mounted () {
        this.init()
    },
    methods: {
        init () {
            const addLabel = 'Novo fornecedor'
            const collectionObj = 'Supplier'
            initGrid({
                container: $(this.$el).find('table'),
                collectionObj,
                rowClick: true,
                addLabel
            })
        }
    }
}
</script>

<style scoped>

</style>
