<template>
    <BaseModal
        ref="BaseModal"
        title="Confirmar"
    >
        <div v-html="text"></div>
        <template v-slot:footer>
            <button :class="primary_class" @click="primary()">{{ primary_text }}</button>
            <button :class="secondary_class" @click="secondary()">{{ secondary_text }}</button>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'

export default {
    name: 'Confirmation',
    props: {
        init_text: {String, default: 'Deseja realmente continuar?'},
        init_primary_text: {String, default: 'Sim'},
        init_secondary_text: {String, default: 'Não'},
        init_primary_class: {String, default: 'btn btn-primary'},
        init_secondary_class: {String, default: 'btn btn-secondary'}
    },
    components: {BaseModal},
    data () {
        return {
            text: '',
            primary_text: '',
            secondary_text: '',
            primary_class: '',
            secondary_class: ''
        }
    },
    mounted () {
        for (const prop in this.$props) {
            this.$set(this, prop.replace(/^init_/, ''), this.$props[prop])
        }
    },
    methods: {
        show (props = {}, callback) {
            for (const prop in props) {
                this.$set(this, prop, props[prop])
            }

            this.$refs.BaseModal.show()
            this.callback = callback
        },
        primary () {
            this.callback && this.callback(true)
            this.$refs.BaseModal.hide()
        },
        secondary () {
            this.callback && this.callback(false)
            this.$refs.BaseModal.hide()
        }
    }
}
</script>

<style scoped>

</style>
