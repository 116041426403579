<template>
    <VueSelect
        v-model="current"
        :disabled="disabled"
        :options="options"
        :placeholder="placeholder"
        :reduce="row => row._id"
        label="trade_name"
        @input="emitInput"
        @search="fetchOptions"
        class="form-control"
    >
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                Nenhum cliente encontrado: <em>{{ search }}</em>.
            </template>
            <em v-else style="opacity: 0.5;">Digite para procurar...</em>
        </template>
    </VueSelect>
</template>

<script>
import {VueSelect} from 'vue-select'
import {H} from 'hennig-common'
import BaseComponent from './BaseComponent'

export default {
    name: 'CustomerSelect',
    extends: BaseComponent,
    components: {VueSelect},
    props: {
        value: {},
        init_placeholder: {String, default: ''},
        disabled: {Boolean, default: false}
    },
    data () {
        return {
            placeholder: ''
        }
    },
    watch: {
        value (_new, _old) {
            if (_new !== _old) this.setValue(_new)
        }
    },
    mounted () {
        this.baseMounted()
        this.setValue(this.value)
    },
    methods: {
        setValue (v) {
            this.fetchOptions(v, (loading) => {
                if (loading === false) {
                    this.current = v
                    this.emitInput(v)
                }
            })
        },
        fetchOptions (search, loading) {
            search = search || this.current
            if (!search) return

            loading(true)
            this.options = []
            H.rpc('Customer', 'lookup', [search], r => {
                if (r) {
                    this.options = r
                }

                loading(false)
            })
        }
    }
}
</script>

<style scoped>

</style>
