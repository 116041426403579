import Vue from 'vue'
import routerAdm from './router';
import routerCustomer from './routerCustomer';
import './imports'
import Echo from 'laravel-echo'
import Popover from 'vue-js-popover'
import moment from 'moment'
import VueScreen from 'vue-screen'
import Dialogs from './dialogs/Dialogs'
import {formatShort} from './dates'
import VueApexCharts from 'vue-apexcharts'

import '../sass/pagination.css'

Vue.use(VueScreen, 'bootstrap')
Vue.use(Popover)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
const router = window.auth.user_type === 'C' ? routerCustomer : routerAdm
// import PortalVue from 'portal-vue'
// Vue.use(PortalVue)

window.Popper = require('popper.js')

window.$ = window.jQuery = require('jquery')
window.HDefaults = {
  bootgridParams: {
    rowCount: [200, 400, 600],
    columnSelection: false,//Desabilitar seletor de colunas
  },
  confirmationMethod: (cb) => {
    window.app.dialogs()
      .Confirmation
      .show({text: 'Deseja realmente excluir?'}, cb)
  }
}

require('bootstrap')
require('bootstrap-notify')
require('bootstrap-select')

window.moment = require('moment')
require('moment/locale/pt-br')

window.datetimepicker = require('tempusdominus-bootstrap-4')

window.addEventListener('dragover', function (e) {
  e = e || event
  e.preventDefault()
}, false)

window.addEventListener('drop', function (e) {
  e = e || event
  e.preventDefault()
}, false)

window.Echo = new Echo({
  broadcaster: 'socket.io',
  transports: ['websocket', 'polling']
})

Vue.mixin({
  methods: {
    print () {
      window.print()
    },
    canAccess (name) {
      return window.gates[name] ?? false
    },
    formatElapsed (v) {
      if (!v) {
        return '-'
      }
      return moment(v).format('L LT')
    },
    formatShort,
    format (dt) {
      if (!dt) {return '-'}
      const m = moment(dt)
      return m.format('L')
    },
    formatDateTime (v) {
      if (!v) {
        return '-'
      }
      return moment(v).format('L LT')
    },
    dialogs () {
      return this.$root.$refs.Dialogs.$refs
    }
  }
})

window.gates = Vue.observable({ ...window.gates })
window.auth = Vue.observable({ ...window.auth })

window.app = new Vue({
  name: 'Main',
  router,
  components: {Dialogs}
}).$mount('#app')

// Globals
// eslint-disable-next-line no-unused-vars
window.redirect = (v, blank) => {
  if (blank) {
    window.open(router.resolve(v).href)
    return
  }

  router.push(v)
}

window.redirectLogin = () => {
  window.auth = null
  router.push({name: 'Login'})
}

window.g_current_locale =
{
  'delimiters': {
    'thousands': '.',
    'decimal': ','
  },
  'abbreviations': {
    'thousand': 'mil',
    'million': 'milhões',
    'billion': 'b',
    'trillion': 't'
  },
  'currency': {
    'symbol': 'R$'
  }
}
