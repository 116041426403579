<template>
  <VueSelect
    v-model="current"
    :options="options"
    :reduce="row => row._id"
    label="name"
    :placeholder="placeholder"
    @search="fetchOptions"
    @input="emitInput"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        Nenhum Tipo de job encontrado: <em>{{ search }}</em>.
      </template>
      <em v-else style="opacity: 0.5;">Digite para procurar...</em>
    </template>
  </VueSelect>
</template>

<script>
import {VueSelect} from 'vue-select'
import {H} from 'hennig-common'
import BaseComponent from './BaseComponent'

export default {
  name: 'JobTypeSelect',
  components: {VueSelect},
  extends: BaseComponent,
  props: {
    init_placeholder: {String, default: ''},
    filter: {},
    require_budget: {Boolean, default: false},
  },
  data () {
    return {
      placeholder: '',
    }
  },
  mounted () {
    this.baseMounted()
  },
  methods: {
    setValue (v) {
      this.fetchOptions(v, (loading) => {
        if (loading === false) {
          this.current = v
          this.emitInput(v)
        }
      })
    },
    fetchOptions (search, loading) {
      search = search || this.current
      if (!search) {return}

      loading(true)
      this.options = []
      H.rpc('JobType', 'lookup', [search, this.filter, this.require_budget], r => {
        if (r) {
          this.options = r
        }

        loading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
