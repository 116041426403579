<template>
    <BaseModal
        ref="BaseModal"
        backdrop="static"
        size="modal-xl"
        title="Visualizador"
    >
        <template v-slot:header>
            <div class="btn btn-primary mr-2">
                <i class="la la-2x la-download" @click="download(current_entry)"></i>
                <span>Download</span>
            </div>
            <h5 class="modal-title">{{ current_entry.name }}</h5>
        </template>

        <div class="text-center">
            <img v-if="getType(current_entry) === 'image'" :src="file(current_entry)" height="100%"/>
            <audio v-if="getType(current_entry) === 'audio'" :src="file(current_entry)" autoplay/>
            <video v-if="getType(current_entry) === 'video'" :src="file(current_entry)" autoplay controls height="100%"/>
        </div>
    </BaseModal>
</template>

<script>
import {getEntryType} from '../common'
import BaseModal from '../components/BaseModal'

export default {
    name: 'ImageView',
    components: {BaseModal},
    data () {
        return {
            param: {},
            current_entry: {}
        }
    },
    methods: {
        show ({param, entry}) {
            this.param = param
            this.current_entry = entry
            this.$refs.BaseModal.show()
        },
        getType (entry) {
            return getEntryType(entry)
        },
        getImg (entry) {
            const type = getEntryType(entry)

            if (type === 'image') {
                return this.file(entry)
            }

            if (entry.folder) {
                return '/img/folder.svg'
            }

            if (type === 'txt') {
                return '/img/txt.svg'
            }

            if (type === 'video') {
                return '/img/video.svg'
            }

            if (type === 'audio') {
                return '/img/audio.svg'
            }

            if (type === 'doc') {
                return '/img/doc.svg'
            }

            if (type === 'xls') {
                return '/img/xls.svg'
            }

            if (type === 'pdf') {
                return '/img/pdf.svg'
            }
            if (type === 'zip') {
                return '/img/zip.svg'
            }

            return '/img/default-file.svg'
        },
        file (entry) {
            return '/media/file?q=' + btoa(JSON.stringify({
                param: this.param,
                path: entry.name
            }))
        },
        download (entry) {
            const opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    param: this.param,
                    path: '/' + entry.name
                })
            }
            fetch('/download', opts)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    // the filename you want
                    a.download = entry.name
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                    // alert('your file has downloaded!') // or you know, something with better UX...
                })
        },
    }
}
</script>

<style scoped>

</style>
