<template>
  <div class="d-flex flex-column justify-content-between">
    <div>
      <div class="main p-2 border">
        <label class="font-weight-bold" v-if="!disableClick"># Fases permanentes</label>
        <div
          v-if="!disableClick"
          class="hist-item fs12"
          @click="addDetail()">
          <i class="la la-plus-circle"></i> Adicionar Informação Livre</div>
        <div
          v-if="!disableClick"
          v-for="item in fixed_phases"
          class="hist-item fs12"
          @click="fixedItemClicked(item)"
        >
          <template v-if="item.phase_type === 'submit'">
            <div>{{ item.phase_name }}</div>
          </template>
          <template v-else-if="item.phase_type === 'fixed'">
            <div>
              {{ item.phase_name }}
              <b v-if="item.phase.allow_schedule">{{ formatShort(item.dtf) }}</b>
            </div>
          </template>
        </div>

        <label :class="!disableClick ? 'font-weight-bold mt-4' : 'font-weight-bold'"># Histórico do Ticket</label>
        <div
          v-for="detail in details"
          :class="{completed: detail.completed, active: detail.active}"
          :title="detail.user_name"

          class="hist-item fs12"
          @click="itemClicked(detail)"
        >
          <span>{{ formatShort(detail.dti) }}</span>
          <span v-if="detail.dtf">a {{ formatShort(detail.dtf) }}</span>
          <span class="ml-2">{{ detail.phase_name }}</span>
          <small v-if="detail.count" class="ml-2">({{ detail.count }})</small>
        </div>

        <div v-if="!disableClick">
          <label class="font-weight-bold mt-4"># Histórico do Ticket</label>
          <div class="hist-item fs12" @click="viewObs('ficha')">Ficha do Cliente</div>
<!--          <div class="hist-item fs12" @click="setReferenceView">Arquivos Gerais do Ticket</div>-->
          <router-link
            v-if="form.parent_ticket_id"
            :to="{name: 'Ticket', params: {_id: form.parent_ticket_id}}"
            class="hist-item fs12"
          >Ticket Referência
          </router-link>

          <router-link
            v-if="form.combo_ticket_id"
            :to="{name: 'Ticket', params: {_id: form.combo_ticket_id}}"
            class="hist-item fs12"
          >Ticket Combo
          </router-link>

          <router-link
            v-if="form.meeting_id"
            :to="{name: 'Meeting', params: {_id: form.meeting_id}}"
            class="hist-item fs12"
          >Reunião
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'

export default {
  name: 'HistoryPanel',
  props: {
    fixed_phases: {},
    details: {},
    form: {},
    disableClick: {
      type: Boolean,
      default: false
    }
  },
  mounted () {

  },
  methods: {
    fixedItemClicked (detail) {
      detail.fixed = true
      this.$emit('itemClicked', detail)
    },
    itemClicked (detail) {
      if (this.disableClick) {
        return
      }
      this.$emit('itemClicked', detail)
    },
    addDetail () {
      const param = {
        customer_id: this.form.customer_id,
        ticket_id: this.form._id,
        ticket_detail_id: '_new'
      }
      this.dialogs().ConfirmAskDetail.show(param, comment => {
        H.rpc('Ticket', 'addDetail', [this.form._id, comment], r => {
          if (!r) {return}
          this.$emit('reload')
        })
      })
    },
    viewObs (tab) {
      let routeData = this.$router.resolve({
        name: 'CustomerView',
        params: {_id: this.form.customer_id},
        query: {tab}
      })
      window.open(routeData.href, '_blank')
    },
    setReferenceView () {
      this.$parent.view = 'references'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/variables";

.main {
  min-height: 400px;
}

.hist-item {
    cursor: pointer;
    color: $primary;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: 0.3s;

    &.completed {
        color: silver;
    }

    &.selected {
        color: #7367f0;
        padding-left: 3px;
    }

    &.active {
        color: $green;
        padding-left: 3px;
    }

    &:hover {
        color: #7367f0;
        padding-left: 3px;
    }
}
</style>
