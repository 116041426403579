<template>
    <div>
        <div class="d-flex justify-content-between mb-2">
            <input
                class="form-control form-control-sm flex-grow-1"
                placeholder="Filtro"
                v-model="filter"
            />
            <div class="ml-2 small text-center link" @click="filter_selected = !filter_selected">
                <span class="text-muted">Selecionados</span>
                <b>{{ countSelected() }}</b>
            </div>
        </div>
        <div class="list-group w-100 scroll-styled p-1 border">
            <div
                v-for="(option, index) in filtered()"
                :key="option._id"
                class="list-group-item d-flex justify-content-between p-2"
            >
                <span v-text="option.name"></span>
                <div class="d-flex flex-column justify-content-center">
                    <i
                        v-if="option.selected" class="la las la-check-circle"
                        v-on:click="unselect(option, index)"
                    ></i>
                    <i
                        v-else class="la las la-circle"
                        v-on:click="select(option, index)"
                    ></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {removeAccents, valueAsArray} from '../common'
import BaseComponent from './BaseComponent'
import {collect} from 'collect.js'

export default {
    name: 'MultiCheckbox',
    extends: BaseComponent,
    components: {},
    data () {
        return {
            filter: '',
            filter_selected: false,
            current_value: [],
            options: []
        }
    },
    methods: {
        * filtered () {
            const filter = removeAccents(this.filter)
            const regex = new RegExp(filter, 'gi')
            for (const row of this.options) {
                const name = removeAccents(row.name)
                if (name.match(regex)) {
                    if (!this.filter_selected || (this.filter_selected && row.selected)) {
                        yield row
                    }
                }
            }
        },
        countSelected () {
            return collect(this.options).where('selected', true).count()
        },
        unselect (option, index) {
            this.$set(option, 'selected', false)
            this.current_value.splice(index, 1)

            this.$emit('input', this.current_value)
        },
        select (option) {
            if (!option) return
            this.$set(option, 'selected', true)
            this.current_value.push(option)

            this.$emit('input', this.current_value)
        },
        selectById (_id) {
            const opt = this.getOptionById(_id)
            this.select(opt)
        },
        setProps (props) {
            if (props.options) {
                this.options = []
                for (const option of props.options) {
                    this.options.push({
                        selected: false,
                        ...option
                    })
                }
            }
        },
        getOptionById (_id) {
            for (const option of this.options) {
                if (_id === option._id) {
                    return option
                }
            }

            return null
        },
        getValue () {
            return this.current_value
        },
        setValue (v) {
            this.current_value = []
            v = valueAsArray(v)

            const values = v || []
            for (const value of values) {
                let option = this.getOptionById(value._id)
                if (!option) continue
                option.selected = true
                this.current_value.push(option)
            }

            this.$emit('input', this.current_value)
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-link {
  cursor: pointer;
}

.sequence {
  span {
    font-size: 1.2rem;
  }

  img {
    width: 1.2rem;
    height: 1.2rem;
  }

  margin-right: .5em;
}

.la {
  font-size: 1.5em;
  cursor: pointer;
}

.list-group {
  height: 250px;
  background-color: #e9ecef;
}

.input-group-text {
  background-color: initial;
}
</style>
