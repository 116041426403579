<template>
  <div>
    <div>
      <div
        class="btn btn-primary btn-sm"
        @click="addContact()"
      >Adicionar contato
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Email</th>
          <th>Telefone</th>
          <th>Cargo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!contacts.length">
          <td colspan="5" class="text-center">Sem contatos</td>
        </tr>
        <template
          v-else
          v-for="row in contacts"
        >
          <tr @click="addContact(row._id)">
            <td>{{ row.name }}</td>
            <td>{{ row.email }}</td>
            <td>{{ row.phone }}</td>
            <td>{{ row.position }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'
import {setArr} from '../common'
import BaseComponent from './BaseComponent'

export default {
  name: 'CrmLeadContact',
  extends: BaseComponent,
  data () {
    return {
      lead_id: '',
      contacts: []
    }
  },
  watch: {
    lead_id (new_value) {
      if (new_value) {
        this.load()
      }
    }
  },
  methods: {
    addContact (_id = '') {
      const that = this
      H.rpc('CrmLeadContact', 'form', [_id, {lead_id: this.lead_id}], r => {
        if (r) {
          H.createForm(r, {
            onstore (evnt, data, $form) {
              that.load()
              $form.closest('.modal').modal('hide')
            }
          })
        }
      })
    },
    save (item) {
      H.rpc('CrmLeadContact', 'save', [item._id, item], r => {
        if (r) {
          showInfo('Salvou')
          this.load()
        }
      })
    },
    load () {
      H.rpc('CrmLeadContact', 'load', [this.lead_id], rows => {
        if (rows) {
          setArr(this, 'contacts', rows)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
    th {
        font-size: 0.7rem;
        background-color: rgba(0, 0, 0, 0.125);
    }
}
</style>
