<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body ">
        <h3 class="d-inline">Lista de tickets</h3>
        <div class="dropdown-divider"></div>
        <h5 class="mb-2 d-print-none">Aqui você tem acesso a todos os tickets da agência. Tudo que está em produção, foi produzido ou foi cancelado em toda a nossa história.</h5>
        <div class="row p-1">
          <div class="d-inline d-print-none">
            <button
              class="btn btn-primary float-right"
              @click="$router.push('pauta')"
            >Minha pauta
            </button>

            <button
              class="btn btn-primary float-right mr-1"
              @click="$router.push('/meus/tickets')"
            >Meus clientes
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex">
              <div class="flex-fill pt-1">
                <select v-model="search.status" multiple class="form-control " style="min-height: 105px;padding: 4px 10px;">
                  <option value="">- Todos status -</option>
                  <option v-for="(v, k) in statusOptions" :key="k" :value="v" v-text="v"></option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-sm-4 p-1">
                <CustomerSelect
                  v-model="search.customer_id"
                  class="form-control "
                  style="padding-top: 2px"
                  init_placeholder="- Ver tickets de um cliente -"
                />
              </div>
              <div class="col-sm-4 p-1">
                <GroupSelect
                  class="form-control "
                  style="padding-top: 4px"
                  v-model="search.setor"
                  init_placeholder="- Ver ticket pelo setor -"
                >
                </GroupSelect>
              </div>
              <div class="col-sm-4 p-1">
                <UserSelect
                  class="form-control "
                  style="padding-top: 2px"
                  v-model="search.phase_user_id"
                  init_placeholder="- Ver ticket de um usuário -"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 p-1">
                <PhaseSelect
                  class="form-control "
                  style="padding-top: 4px"
                  v-model="search.phase_id"
                  init_placeholder="- Ver tickets estão em uma fase -"
                />
              </div>
              <div class="col-sm-4 p-1">
                <JobTypeSelect
                  ref="jobType"
                  v-model="search.ticket_type_id"
                  class="form-control "
                  style="padding-top: 2px"
                  init_placeholder="- Ver tickets de um tipo de serviço -"
                />
              </div>
              <div class="col-sm-4 p-1">
                <TeamSelect
                  v-model="search.team_id"
                  class="form-control "
                  style="padding-top: 2px"
                  init_placeholder="- Selecionar equipe -"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3 p-1">
                <select v-model="search.date_alias" class="form-control ">
                  <option value="">- Ver ticket pelo status do prazo -</option>
                  <option value="delayed">Atrasados</option>
                  <option value="shift">Para o turno</option>
                  <option value="today">Hoje</option>
                  <option value="tomorrow">Amanhã</option>
                </select>
              </div>
              <div class="col-sm-9">
                <div class="row">
                  <div class="col-sm-4 d-flex p-1">
                    <div class="col-sm-6 p-0">
                      <DateTimePicker
                        v-model="search.start_at_i"
                        init_placeholder="Abertura inicial"
                      />
                    </div>
                    <div class="col-sm-6 p-0">
                      <DateTimePicker
                        v-model="search.start_at_f"
                        init_placeholder="Abertura final"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4 d-flex p-1">
                    <div class="col-sm-6 p-0">
                      <DateTimePicker
                        v-model="search.approval_at_i"
                        init_placeholder="Entrega inicial"
                      />
                    </div>
                    <div class="col-sm-6 p-0">
                      <DateTimePicker
                        v-model="search.approval_at_f"
                        init_placeholder="Entrega final"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4 d-flex p-1">
                    <div class="col-sm-6 p-0">
                      <DateTimePicker
                        v-model="search.due_at_i"
                        init_placeholder="Vencimento inicial"
                      />
                    </div>
                    <div class="col-sm-6 p-0">
                      <DateTimePicker
                        v-model="search.due_at_f"
                        init_placeholder="Vencimento final"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-sm-12 p-0">
            <button class="btn btn-danger float-right ml-1 float-right" @click="clear()">Limpar filtros</button>
            <button class="btn btn-primary ml-1 float-right" @click="filter()">Filtrar</button>
          </div>
        </div>
        <div class="col-sm-12 p-0">
          <div class="table-responsive">
            <table class="table">
              <TableHeaders/>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'
import {initGrid} from 'hennig-common'
import {approval, effective_approval, phase_dates, projection, TableHeaders} from '../formmaters'
import PhaseSelect from '../components/PhaseSelect'
import TeamSelect from '../components/TeamSelect'
import GroupSelect from '../components/GroupSelect'
import DateTimePicker from '../components/DateTimePicker'

const collectionObj = 'Ticket'

export default {
  name: 'Tickets',
  components: {PhaseSelect, TableHeaders, TeamSelect, DateTimePicker},
  data () {
    return {
      search: {
        status: ['Aberto'],
        ticket_type_id: '',
        phase_id: '',
        phase_user_id: '',
        customer_id: '',
        date_alias: '',
        start_at_i: '',
        start_at_f: '',
        approval_at_i: '',
        approval_at_f: '',
        due_at_i: '',
        due_at_f: '',
        team_id: '',
        setor: '',
        remove_budget: this.$route.name !== 'TicketsBudget',
        only_budget: this.$route.name === 'TicketsBudget'
      },
      statusOptions: [],
    }
  },
  watch: {
    'search.team_id' (new_value) {
      if (new_value) {
        this.load()
      }
    },
    'search.setor' (new_value) {
      if (new_value) {
        this.load()
      }
    },
    'search.ticket_type_id' (new_value) {
      if (new_value) {
        this.load()
      }
    }
  },
  mounted () {
    this.loadStatus(),
    this.init(),
    this.search.team_id = this.$route.params.team_id || '',
    this.search.setor = this.$route.params.setor || ''
    if (this.$route.params.ticket_type_id) {
      this.search.ticket_type_id = this.$route.params.ticket_type_id || ''
      this.$refs.jobType.setValue(this.search.ticket_type_id)
    }
  },
  methods: {
    clear () {
      this.search = {
        status: ['Aberto'],
        ticket_type_id: '',
        phase_id: '',
        phase_user_id: '',
        customer_id: '',
        date_alias: '',
        start_at_i: '',
        start_at_f: '',
        approval_at_i: '',
        approval_at_f: '',
        due_at_i: '',
        due_at_f: '',
        team_id: '',
        setor: '',
      }
      $('.form-control').val('')
    },
    loadStatus () {
      H.rpc('Ticket', 'statusOptions', [], (r) => {
        if (r) {
          this.statusOptions = r
        }
      })
    },
    load () {
      this.filter()
    },
    filter (data) {
      for (const i in data) {
        if (!this.search.hasOwnProperty(i)) {continue}
        this.$set(this.search, i, data[i])
      }

      $(this.$el).find('table').bootgrid('reload')
    },
    init () {
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        noAddButton: true,
        rowClick: true,
        search: () => this.search,
        bootgridParams: {
          rowCss (row) {
            let issubcombo = ''
            let isCombo = ''
            let rowStatus = row.status
            if (row.combo_ticket_id){
              issubcombo = ' row-subcombo '
            }

            if (row.type === 'Combo'){
              isCombo = 'row-combo'
            }

            if (row.combo_awaiting){
              isCombo = 'row-combo-awaiting'
            }

            // if (row.type === 'Combo') return ' row-combo ' + issubcombo + ' ' + rowStatus
            if (row.status !== 'Aberto') {return ' entregue ' + issubcombo + ' ' + rowStatus}
            if (!row.approval_diff) {return ' no-prazo ' + issubcombo + ' ' + isCombo + ' ' + rowStatus}

            if(row.approval_diff){
              let diff = parseInt(row.approval_diff)
              const pos = diff < 0 ? ' a-menos ' : ' a-mais '
              return pos + issubcombo + ' ' + rowStatus + ' ' + isCombo
            }

            return ''
          }
        },
        formatters: {
          effective_approval,
          approval,
          projection,
          phase_dates,
          numPadding (column, row) {
            const combo = '<img height="12" class="mr-2" src="img/arrow-bottom-rigth.svg"/>'
            const parent = '<img height="12" class="mr-2" src="img/angle-double-right-solid.svg"/>'

            if (row.combo_ticket_id && row.parent_ticket_id) {
              return combo + parent + `<span>${row.num}</span>`
            }

            if (row.combo_ticket_id) {
              return combo + `<span>${row.num}</span>`
            }

            if (row.parent_ticket_id) {
              return parent + `<span>${row.num}</span>`
            }

            return row.num
          }
        }
      },
      )
    },
    setStyle () {

    }
  }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .table th:nth-child(6),
        .table th:nth-child(7),
        .table th:nth-child(8) {
            background-color: darkgray;
        }

        .th-phases {
            background-color: #b8f2f9;
        }

        .a-menos {
            border-left: 20px solid #48da89 ;
        }

        .no-prazo {
            border-left: 20px solid #c3efd7;
        }

        .a-mais {
            background-color: #f9cfcf;
            border-color: #f4a6a7;
        }

        .Fechado, .Cancelado {
            border-left: 20px solid #ffe4ca;
        }

        .Aguardando {
            border-left: 20px solid #d8d4fb;
        }

        .badge.badge-light-primary {
            background-color: rgba(115, 103, 240, 0.12);
            color: #7367f0 !important;
            font-size: 11px;
            margin-top: 0.72rem;
        }

        .Aberto td:last-child {
            color: #7367f0 !important;
            font-weight: 600;
        }

        .Fechado td:last-child {
            color: #ff9f43 !important;
            font-weight: 600;
        }

        .Cancelado td:last-child {
            color: #ea5455 !important;
            font-weight: 600;
        }

        .Aguardando td:last-child {
            color: #00cfe8 !important;
            font-weight: 600;
        }

        .row-combo {
            background-color: #7ae6f3;
        }

        .row-combo-awaiting {
            background-color: #000000cc;
            color: #ffffff;
        }

        .row-subcombo {
            border-right: 20px solid #7ae6f3 !important;
        }

        .table tr {
            border-top: 0px;
            border-bottom: 1px solid #cece;
            transition: 0.3s;
            position: relative;
        }

        .table tr:hover {
            top: -1px;
            transition: 1s;
        }
    }
</style>
