<template>
    <div v-if="current_detail">
        <div class="col-12 mb-4 mt-2">
            <div class="offset-3 col-6 d-flex justify-content-center">
                <label>Data de Entrega</label>
            </div>
            <div class="offset-3 col-6 d-flex justify-content-center">
                <DatePicker
                    init_placeholder="- Escolher data -"
                    v-model="new_schedule"
                />

                <button class="btn btn-primary btn-sm" @click="saveNewSchedule()">Salvar</button>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12">
                <label><b class="mr-4">{{ current_detail.phase_name }}</b></label>
            </div>
            <div class="col-lg-12">
                <HtmlEditor
                    ref="HtmlEditor"
                    v-model="content"
                />
                <div class="btn btn-primary btn-sm" @click="saveDetail">Salvar</div>
            </div>
        </div>

<!--        <div class="form-group row">-->
<!--            <label class="col-12">Arquivos para publicação</label>-->
<!--            <div class="col-12">-->
<!--                <UploadsEditor-->
<!--                    :param="{customer_id: form.customer_id, ticket_id: form._id, ticket_detail_id: current_detail.detail_id}"-->
<!--                    height="150px"-->
<!--                />-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'
import DatePicker from '../../components/DatePicker'

export default {
    name: 'PublishBody',
    components: {
        DatePicker
    },
    props: {
        current_detail: {},
        form: {}
    },
    data () {
        return {
            content: '',
            new_schedule: '',
        }
    },
    watch: {
        current_detail: {
            handler (v) {
                this.$refs.HtmlEditor.setValue(v.content)
            },
            deep: true
        }
    },
    mounted () {
        this.$refs.HtmlEditor.setValue(this.current_detail.content)
    },
    methods: {
        saveNewSchedule () {
            H.rpc('Ticket', 'saveDetailDate', [this.current_detail, this.new_schedule, this.$props.form], r => {
                if (!r) return
                this.new_schedule = ''
                showInfo('A fase foi agendada')
                this.$emit('reload')
            })
        },
        saveDetail () {
            H.rpc('Ticket', 'saveDetailContent', [this.current_detail, this.content], (r) => {
                if (r) {
                    showInfo('Este texto foi salvo na fase')
                    this.$emit('reloadContent')
                }
            })
        }
    }
}
</script>

<style scoped>
.content {
    min-height: 300px;
}
</style>
