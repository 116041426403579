<template>
    <div class="container">
        <div v-if="status === 'loading'">
            <div class="spinner-border" role="status">
            </div>
        </div>
        <div v-else-if="status === 'empty'" class="card">
            <div class="card-body">Esta mensagem não está disponível</div>
        </div>
        <div v-else class="card">
            <div class="card-header">
                Enviada por <b>{{ form.from }}</b> {{ formatElapsed(form.created_at) }}
            </div>
            <div class="card-body" v-html="form.content">
            </div>
        </div>
    </div>
</template>

<script>
import {setObj} from '../common'
import {H} from 'hennig-common'

export default {
    name: 'MessageView',
    data () {
        return {
            status: 'loading',
            form: {
                content: '',
                created_at: '',
                from: ''
            }
        }
    },
    mounted () {
        this.load()
    },
    methods: {
        load () {
            H.rpc('Message', 'get', [this.$route.params._id], r => {
                if (r) {
                    setObj(this.form, r)
                    this.status = 'loaded'
                    return
                }

                this.status = 'empty'
            })
        }
    }
}
</script>

<style scoped>

</style>
