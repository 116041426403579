import {formatShort} from './dates'

export const TableHeaders = {
  template: `<thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
                        <th data-column-id="num" data-formatter="numPadding" data-order="desc" data-sortable="true" data-width="100px">Número</th>
                        <th data-css-class='no-print' data-header-css-class="no-print" data-column-id="type" data-width="100px" data-sortable="true">Tipo</th>
                        <th data-column-id="customer_trade_name">Cliente</th>
                        <th data-column-id="job_type_name">Serviço</th>
                        <th data-column-id="name">Título</th>

                        <th data-column-id="phase_name">Fase atual</th>
                        <th data-column-id="phase_dates" data-formatter="phase_dates">Entrega da fase</th>
                        <th data-column-id="user_name">Responsável</th>

                        <th data-css-class='no-print' data-header-css-class="no-print" data-column-id="approval_attempts">Entrega do Ticket</th>
                        <th data-column-id="approval" data-formatter="approval">Entrega Original</th>
                        <th data-column-id="effective_approval" data-formatter="effective_approval">Próxima Entrega</th>
                        <th data-css-class='no-print' data-header-css-class="no-print" data-column-id="approval_diff" data-formatter="projection">Projeção de prazo</th>

                        <th data-column-id="status" data-sortable="true">Status</th>
                    </tr>
               </thead>`
}

export const CustomerTableHeaders = {
  template: `<thead>
                    <tr>
                        <th data-column-id="num" data-formatter="numPadding" data-order="desc" data-width="100px">Número</th>
                        <th data-column-id="job_type_name">Serviço</th>
                        <th data-column-id="name">Título</th>

                        <th data-column-id="phase_name">Fase atual</th>
                        <th data-column-id="phase_dates" data-formatter="phase_dates">Entrega da fase</th>

                        <th data-css-class='no-print' data-header-css-class="no-print" data-column-id="approval_attempts">Qtde Entregas</th>
                        <th data-column-id="approval" data-formatter="approval">Entrega Original</th>
                        <th data-column-id="effective_approval" data-formatter="effective_approval">Próxima Entrega</th>
                        <th data-css-class='no-print' data-header-css-class="no-print" data-column-id="approval_diff" data-formatter="projection">Projeção de prazo</th>

                        <th data-column-id="status" data-sortable="true">Status</th>
                    </tr>
               </thead>`
}

export function effective_approval (column, row) {
  if (row.status !== 'Aberto') {return '-'}
  if (!row.effective_approval_start_at && !row.effective_approval_due_at) {
    return '-'
  }

  return formatShort(row.effective_approval_start_at) + ' a ' + formatShort(row.effective_approval_due_at)
}

export function phase_dates (column, row) {
  if (row.status !== 'Aberto') {return '-'}
  if (!row.phase_start_at && !row.phase_due_at) {
    return '-'
  }
  return formatShort(row.phase_start_at) + ' a ' + formatShort(row.phase_due_at)
}

export function approval (column, row) {
  if (row.status !== 'Aberto') {return '-'}
  if (!row.approval_start_at && !row.approval_due_at) {
    return '-'
  }
  return formatShort(row.approval_start_at) + ' a ' + formatShort(row.approval_due_at)
}

export function projection (column, row) {
  if (row.status !== 'Aberto') {return 'entregue'}
  if (!row.approval_diff) {return 'no prazo'}
  let diff = parseInt(row.approval_diff)
  const pos = diff < 0 ? ' a menos' : ' a mais'
  diff = Math.abs(diff)
  return diff + (diff === 1 ? ' dia' : ' dias') + pos
}
