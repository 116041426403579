<template>
    <div class="d-flex justify-content-start main p-2">
        <div
            class="img-thumbnail mr-2"
            style="width: 100px; height: 100px;"
        >
            <div
                :style="imgItemStyle()"
                class="img-item hover-blur"
            ></div>

            <i
                class="la la-trash link position-absolute"
                style="top: 4px; right: 4px; font-size: 1.5rem;"
                v-on:click="remove()"
            ></i>
        </div>
        <div class="" style="width: 100px; height: 100px; overflow: hidden; position: relative">
            <div class="d-flex align-items-center justify-content-center h-100">
                <i class="la la-2x la-plus"></i>
                <span>Adicionar...</span>
            </div>

            <input type="file" accept="image/*" v-on:change="upload"/>
        </div>
    </div>
</template>
<script>
import BaseComponent from './BaseComponent'
import {showError} from 'hennig-common'

export default {
    name: 'UploadSingleEditor',
    extends: BaseComponent,
    components: {},
    mounted () {
        this.baseMounted()
    },
    methods: {
        imgItemStyle () {
            if (!this.current) {
                return {}
            }
            return {backgroundImage: `url('${this.current}')`}
        },
        remove () {
            this.current = ''
            this.$emit('input', this.current)
        },
        upload (evnt) {
            this._upload('/upload', evnt.target.files)
        },
        _upload (aUrl, aFiles) {
            if (aFiles.length === 0) {
                showError('Nenhum arquivo selecionado')
                return
            }

            for (const file of aFiles) {
                if (file.size > 50 * 10124 * 1024) {
                    showError('Arquivo muito grande. Limite 50MB')
                    return
                }

                const reader = new FileReader()
                reader.onloadend = () => {
                    this.current = reader.result
                    this.$emit('input', this.current)
                }

                reader.readAsDataURL(file)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/variables";

.main {
  border: 1px solid $input-border-color;
}

.img-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.la {
  opacity: .1;
  transition: opacity .5s;
}

.img-thumbnail {
  position: relative;

  &:hover {
    .hover-blur {
      filter: blur(6px);
    }

    .label, .la {
      opacity: 1;
    }
  }
}

.label {
  position: absolute;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  top: 40px;
  left: 0;
  transition: opacity .5s;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid red;
  height: 100%;
  opacity: .01;
}
</style>
