<template>
    <BaseModal
        ref="BaseModal"
        title="Encaminhar"
    >
        <div class="font-weight-bold">
            Deseja realmente encaminhar esta fase?
        </div>
        <hr>
        <div class="mt-2">
            <UserSelect :data="{ group_id }" v-model="user_id"/>
        </div>
        <template v-slot:footer>
            <div class="btn btn-primary" @click="confirm()">Encaminhar</div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'
import {showError} from 'hennig-common'

export default {
    name: 'ConfirmForward',
    components: {BaseModal},
    data () {
        return {
            group_id: '',
            user_id: ''
        }
    },
    methods: {
        show (group_id, callback) {
            this.group_id = group_id
            this.$refs.BaseModal.show()
            this.callback = callback
        },
        confirm () {
            if (!this.user_id) {
                showError('Você precisa informar um novo responsável')
                return
            }

            this.callback(this.user_id)
            this.$refs.BaseModal.hide()
        }
    }
}
</script>

<style scoped>
::v-deep .modal-content {
    min-height: 400px;
}
</style>
