import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'
import CoffeeCategory from '../views/CoffeeCategory'
import CoffeeOrder from '../views/CoffeeOrder'
import CoffeeOrderDetail from '../views/CoffeeOrderDetail'
import CoffeeMyOrders from '../views/CoffeeMyOrders'
import CoffeeOrders from '../views/CoffeeOrders'
import CoffeItensReport from '../views/CoffeItensReport'

const CoffeeProducts = () => import(/* webpackChunkName: "CoffeeProducts" */'../views/CoffeeProducts')

const authRequired = true

export default [
  {
    path: '/cafeteria/produtos',
    components: {default: CoffeeProducts, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CoffeeProduct',
    path: '/cafeteria/produto/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CoffeeProduct'}},
    meta: {authRequired, backRoute: '/cafeteria/produtos'}
  },
  {
    path: '/cafeteria/pedido',
    components: {default: CoffeeOrder, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/cafeteria/meuspedidos',
    components: {default: CoffeeMyOrders, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CoffeeOrderDetail',
    path: '/cafeteria/pedido/detalhe/:_id?',
    components: {default: CoffeeOrderDetail, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/cafeteria/categorias',
    components: {default: CoffeeCategory, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CoffeeCategory',
    path: '/cafeteria/categoria/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'CoffeeCategory'}},
    meta: {authRequired, backRoute: '/cafeteria/categorias'}
  },
  {
    path: '/cafeteria/relatorios/pedidos',
    components: {default: CoffeeOrders, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/cafeteria/relatorios/itens',
    components: {default: CoffeItensReport, header: Logged},
    meta: {authRequired}
  },
]
