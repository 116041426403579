<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>Usuários de clientes</h3>
        <div class="dropdown-divider"></div>
        <h5>Aqui vamos cadastrar e editar os acessos do sistema para clientes da empresa</h5>
        <table class="table table-sm table-hover table-striped">
          <thead>
            <tr>
              <th data-column-id="_id" data-visible="false">ID</th>
              <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>
              <th data-align="center" data-column-id="x" data-formatter="x" data-sortable="false" data-width="36px"></th>
              <th data-align="center" data-column-id="active" data-formatter="checkbox" data-width="36px"></th>
              <th data-column-id="name" data-sortable="true">Nome</th>
              <th data-column-id="customer_name">Cliente</th>
              <th data-column-id="email">E-mail</th>
              <!--                <th data-column-id="created_at" data-formatter="dt">Criação</th>-->
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {initGrid} from 'hennig-common'

export default {
  name: 'UsersCustomer',
  props: {
    customer_id: {String}
  },
  watch: {
    customer_id () {
      $(this.$el).find('table').bootgrid('reload')
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const addLabel = 'Novo usuário de cliente'
      const collectionObj = 'UserCustomer'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => {
          return {
            customer_id: this.customer_id
          }
        },
        rowClick: true,
        addLabel,
        // modalAdd: true,
        // modalEdit: true,
      })
    }
  }
}
</script>

<style scoped>

</style>
