import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'

const AdmTickets = () => import(/* webpackChunkName: "AdmTickets" */'../views/AdmTickets')
const AdmTicketTypes = () => import(/* webpackChunkName: "AdmTicketTypes" */'../views/AdmTicketTypes')

const authRequired = true

export default [
    {
        path: '/adm/tickets',
        components: {default: AdmTickets, header: Logged},
        meta: {authRequired}
    },
    {
        name: 'AdmTicket',
        path: '/adm/ticket/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'AdmTicket'}},
        meta: {authRequired, backRoute: '/adm/tickets'}
    },
    {
        path: '/adm/tipos',
        components: {default: AdmTicketTypes, header: Logged},
        meta: {authRequired}
    },
    {
        name: 'AdmTicketType',
        path: '/adm/tipo/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'AdmTicketType'}},
        meta: {authRequired, backRoute: '/adm/tipos'}
    }
]
