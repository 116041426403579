import Vue from 'vue'
import VueRouter from 'vue-router'
import Initial from './views/Initial'
import Users from './views/Users'
import Customers from './views/Customers'
import Dashboard from './views/Dashboard'
import DashboardNew from './views/DashboardNew'
import Login from './views/Login'
import Logout from './views/Logout'
import ChangePass from './views/ChangePass'
import Phases from './views/Phases'
import ContractTypes from './views/ContractTypes'
import ActivityLogs from './views/ActivityLogs'
import FormContainer from './views/FormContainer'
import Logged from './views/headers/Logged'
import Profile from './views/Profile'
import UsersCustomer from './views/UsersCustomer'
import {showError} from 'hennig-common'
import CustomerObs from './views/CustomerObs'
import Suppliers from './views/Suppliers'
import SupplierTypes from './views/SupplierTypes'
import Messages from './views/Messages'
import HolidayList from './views/HolidayList'
import MessageView from './views/MessageView'
import SysRoutes from './routes/sys'
import AdmRoutes from './routes/adm'
import QuaRoutes from './routes/qua'
import CoffeeRoutes from './routes/coffee'
import MeetingRoutes from './routes/meeting'
import TicketRoutes from './routes/ticket'
import WikiRoutes from './routes/wiki'
import Permissions from './views/Permissions'
import EmailLogs from './views/EmailLogs'
import Crm from './routes/crm'
import CustomerLogged from './views/headers/CustomerLogged'
import ReportGroup from './views/ReportGroup'
import CrmLeadImport from './views/CrmLeadImport.vue'
import CrmLeads from './views/CrmLead.vue'

const Settings = () => import(/* webpackChunkName: "Settings" */'./views/Settings')
const Groups = () => import(/* webpackChunkName: "Groups" */'./views/Groups')
const JobTypes = () => import(/* webpackChunkName: "JobTypes" */'./views/JobTypes')
const WorkedHours = () => import(/* webpackChunkName: "WorkedHours" */'./views/reports/WorkedHours')
const WorkedHoursExport = () => import(/* webpackChunkName: "WorkedHours" */'./views/reports/WorkedHoursExport')
const ContractsReport = () => import(/* webpackChunkName: "ContractsReport" */'./views/reports/ContractsReport')
const ReworkPerCustomer = () => import(/* webpackChunkName: "ReworkPerCustomer" */'./views/reports/ReworkPerCustomer')
const ContractReportGeneral = () => import(/* webpackChunkName: "ContractsReport" */'./views/reports/ContractReportGeneral')
const ProductivityPerPerson = () => import(/* webpackChunkName: "ProductivityPerPerson" */'./views/reports/ProductivityPerPerson')
const ProductivityPerGroup = () => import(/* webpackChunkName: "ProductivityPerGroup" */'./views/reports/ProductivityPerGroup')
const JobTypeReport = () => import(/* webpackChunkName: "JobTypeReport" */'./views/JobTypeReport')
const TimeMinutesAndHours = () => import(/* webpackChunkName: "TimeMinutesAndHours" */'./views/TimeMinutesAndHours')
const Companies = () => import(/* webpackChunkName: "Companies" */'./views/Companies')
const Teams = () => import(/* webpackChunkName: "Companies" */'./views/Team')
const MeetingCalendar = () => import(/* webpackChunkName: "MeetingCalendar" */'./views/MeetingCalendar')
const TrocarUsuarioEquipe = () => import(/* webpackChunkName: "MeetingCalendar" */'./views/TrocarUsuarioEquipe')
const AddPessoaFase = () => import(/* webpackChunkName: "MeetingCalendar" */'./views/AddPessoaFase')

const authRequired = true
const adminRequired = true

const routes = [
  ...SysRoutes,
  ...AdmRoutes,
  ...QuaRoutes,
  ...CoffeeRoutes,
  ...MeetingRoutes,
  ...TicketRoutes,
  ...WikiRoutes,
  ...Crm,
  {
    path: '/',
    components: {default: Initial},
    name: 'Initial'
  },
  {
    path: '/login', name: 'Login',
    components: {default: Login}
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {authRequired}
  },
  {
    path: '/dashboard', name: 'Home', components: {default: DashboardNew, header: Logged}, meta: {authRequired}
  },
  {
    path: '/profile',
    components: {default: Profile, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/usuarios',
    components: {default: Users, header: Logged},
    meta: {authRequired, gate: 'usuarios'}
  },
  {
    name: 'User',
    path: '/usuario/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'User'}},
    meta: {authRequired, backRoute: '/usuarios', gate: 'usuarios'}
  },
  {
    path: '/cliente/usuarios',
    components: {default: UsersCustomer, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/cliente/usuario/:_id?',
    name: 'UserCustomer',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'UserCustomer'}},
    meta: {authRequired, backRoute: '/cliente/usuarios'}
  },
  {
    path: '/fornecedores',
    components: {default: Suppliers, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Supplier',
    path: '/fornecedor/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Supplier'}},
    meta: {authRequired, backRoute: '/fornecedores'}
  },
  {
    path: '/empresas',
    components: {default: Companies, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Company',
    path: '/empresa/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Company'}},
    meta: {authRequired, backRoute: '/empresas'}
  },
  {
    path: '/equipes',
    components: {default: Teams, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Team',
    path: '/equipe/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Team'}},
    meta: {authRequired, backRoute: '/equipes'}
  },
  {
    path: '/clientes',
    components: {default: Customers, header: Logged},
    meta: {authRequired, gate: 'menu_cliente'}
  },
  {
    name: 'HolidayList',
    path: '/lista/feriados',
    components: {default: HolidayList, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Holiday',
    path: '/lista/feriado/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Holiday'}},
    meta: {authRequired, backRoute: '/feriados'}
  },
  {
    name: 'Customer',
    path: '/cliente/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Customer'}},
    meta: {authRequired, backRoute: '/clientes', gate: 'menu_cliente'}
  },
  {
    path: '/fichas',
    components: {default: CustomerObs, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'CustomerView',
    path: '/ficha/:_id?',
    components: {default: CustomerObs, header: Logged},
    meta: {authRequired}
  },

  {path: '/logs', components: {default: ActivityLogs, header: Logged}, meta: {adminRequired}},
  {path: '/logs/email', components: {default: EmailLogs, header: Logged}, meta: {adminRequired}},

  {path: '/setores', components: {default: Groups, header: Logged}, meta: {authRequired}},
  {
    name: 'Group',
    path: '/setor/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Group'}},
    meta: {authRequired, backRoute: '/setores'}
  },

  {path: '/tipos/fases', components: {default: Phases, header: Logged}, meta: {authRequired}},
  {
    name: 'Phase',
    path: '/tipos/fase/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Phase'}},
    meta: {authRequired, backRoute: '/tipos/fases'}
  },

  {path: '/tipos/jobs', components: {default: JobTypes, header: Logged}, meta: {authRequired}},
  {
    name: 'JobType',
    path: '/tipos/job/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'JobType'}},
    meta: {authRequired, backRoute: '/tipos/jobs'}
  },
  {
    path: '/tipos/contratos',
    components: {default: ContractTypes, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'ContractType',
    path: '/tipos/contrato/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'ContractType'}},
    meta: {authRequired, backRoute: '/tipos/contratos'}
  },
  {
    path: '/aviso/:_id',
    components: {default: MessageView, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/lista/avisos',
    components: {default: Messages, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'Message',
    path: '/lista/aviso/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Message'}},
    meta: {authRequired, backRoute: '/lista/avisos'}
  },
  {
    path: '/tipos/fornecedores',
    components: {default: SupplierTypes, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'SupplierType',
    path: '/tipos/fornecedor/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'SupplierType'}},
    meta: {authRequired, backRoute: '/tipos/fornecedores'}
  },
  {
    path: '/relatorio/jobs',
    components: {default: JobTypeReport, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/relatorio/jobshoras',
    components: {default: TimeMinutesAndHours, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/relatorio/horas/trabalhadas/:customer_id?',
    components: {default: WorkedHours, header: Logged},
    meta: {authRequired},
    name: 'WorkedHours'
  },
  {
    path: '/relatorio/horas/trabalhadasexport',
    name: 'WorkedHoursExport',
    components: {default: WorkedHoursExport, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/relatorio/horas/gerencialgeral/',
    components: {default: ContractReportGeneral, header: Logged},
    meta: {authRequired},
    name: 'ContractReportGeneral'
  },
  {
    path: '/relatorio/relprodutividadepessoa',
    components: {default: ProductivityPerPerson, header: Logged},
    meta: {authRequired},
    name: 'ProductivityPerPerson'
  },
  {
    path: '/relatorio/relprodutividadesetor',
    components: {default: ProductivityPerGroup, header: Logged},
    meta: {authRequired},
    name: 'ProductivityPerGroup'
  },
  {
    path: '/relatorio/retrabalho',
    components: {default: ReworkPerCustomer, header: Logged},
    meta: {authRequired},
    name: 'ReworkPerCustomer'
  },
  {
    path: '/relatorio/horas/gerencial/:customer_id?',
    components: {default: ContractsReport, header: Logged},
    meta: {authRequired},
    name: 'ContractsReport'
  },
  {
    path: '/permissoes',
    components: {default: Permissions, header: Logged},
    meta: {adminRequired}
  },
  {
    path: '/configuracoes',
    components: {default: Settings, header: Logged},
    meta: {adminRequired}
  },
  {
    path: '/trocar_usuario_equipe',
    components: {default: TrocarUsuarioEquipe, header: Logged},
    meta: {adminRequired}
  },
  {
    path: '/adicionar_pessoa_fase',
    components: {default: AddPessoaFase, header: Logged},
    meta: {adminRequired}
  },
  {
    name: 'Permission',
    path: '/permissao/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'Permission'}},
    meta: {adminRequired, backRoute: '/permissoes'}
  },
  {
    path: '/changePass',
    name: 'ChangePass',
    component: ChangePass,
    meta: {authRequired}
  },
  {
    name: 'MeetingCal',
    path: '/calendario',
    components: {default: MeetingCalendar, header: Logged},
    meta: {authRequired}
  },
  {
    path: '/agrupador_relatorios',
    components: {default: ReportGroup, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'ReportGroup',
    path: '/agrupador_relatorios/:_id?',
    components: {default: FormContainer, header: Logged},
    props: {default: {collectionObj: 'ReportGroup'}},
    meta: {authRequired, backRoute: '/agrupador_relatorios'}
  },
  {
    name: 'crmImportFile',
    path: '/crm/import',
    components: {default: CrmLeadImport, header: Logged},
    meta: {authRequired}
  },
  {
    name: 'crmImportFile',
    path: '/crm/lead',
    components: {default: CrmLeads, header: Logged},
    meta: {authRequired}
  },
]

const isAutheticated = () => window.auth && window.auth.user_id
const isAdmin = () => window.auth && window.auth.user_level >= 9

const router = new VueRouter({routes})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.adminRequired)) {
    if (isAdmin()) {
      next()
    } else {
      showError('Para acessar este recurso é necessário ser um administrador')
      next(false)
    }
  } else if (to.matched.some(record => record.meta.authRequired)) {
    for (const route of to.matched) {
      if (route.meta.gate && !window.gates[route.meta.gate]) {
        showError(`Para acessar este recurso é necessário ter permissão (${route.meta.gate})`)
        next(false)
        return
      }
    }

    if (isAutheticated()) {
      next()
    } else {
      next({name: 'Login', params: {nextUrl: to.fullPath}})
    }
  } else {
    if (isAutheticated() && window.changePass) {
      next({name: 'ChangePass'})
      return
    }

    if (isAutheticated()) {
      next({name: 'Home'})
    } else {
      next()
    }
  }
})
Vue.use(VueRouter)
export default router
