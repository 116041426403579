<template>
    <div>
        <Confirmation ref="Confirmation"/>
        <ImageView ref="ImageView"/>
        <ConfirmAskDetail ref="ConfirmAskDetail"/>
        <AddSubmitEmail ref="AddSubmitEmail"/>
        <AddTicketCombo ref="AddTicketCombo"/>
        <LinkTicketCombo ref="LinkTicketCombo"/>
    </div>
</template>

<script>
import Confirmation from './Confirmation'
import ImageView from './ImageView'
import ConfirmAskDetail from './ConfirmAskDetail'
import AddSubmitEmail from './AddSubmitEmail'
import AddTicketCombo from './AddTicketCombo'
import LinkTicketCombo from './LinkTicketCombo'

export default {
    name: 'Dialogs',
    components: {
        AddSubmitEmail,
        ConfirmAskDetail,
        LinkTicketCombo,
        ImageView,
        AddTicketCombo,
        Confirmation
    }
}
</script>

<style scoped>

</style>
