import Vue from 'vue'

// Global components
import MultiSelectEditor from './components/MultiSelectEditor'
import CustomerSelect from './components/CustomerSelect'
import JobTypeSelect from './components/JobTypeSelect'
import HtmlEditor from './components/HtmlEditor'
import CustomerUserGroupEditor from './components/CustomerUserGroupEditor'
import PhaseEditor from './components/PhaseEditor'
import UserPhaseEditor from './components/UserPhaseEditor'
import UploadsEditor from './components/UploadsEditor'
import UploadSingleEditor from './components/UploadSingleEditor'
import CustomerHelper from './helpers/customers'
import UserSelect from './components/UserSelect'
import TicketSelect from './components/TicketSelect'
import SysSelect from './components/SysSelect'
import GroupSelect from './components/GroupSelect'
import MultiCheckbox from './components/MultiCheckbox'
import ContractsEditor from './components/ContractsEditor'
import CrmLeadContact from './components/CrmLeadContact'
import CrmFunnelPhase from './components/CrmFunnelPhase'
import CrmLeadSelect from './components/CrmLeadSelect'
// const PhaseEditor = () => import(/* webpackChunkName: "PhaseEditor" */'./components/PhaseEditor')

Vue.component('MultiCheckbox', MultiCheckbox)
Vue.component('MultiSelectEditor', MultiSelectEditor)
Vue.component('PhaseEditor', PhaseEditor)
Vue.component('UserPhaseEditor', UserPhaseEditor)
Vue.component('UserSelect', UserSelect)
Vue.component('GroupSelect', GroupSelect)
Vue.component('CustomerSelect', CustomerSelect)
Vue.component('JobTypeSelect', JobTypeSelect)
Vue.component('HtmlEditor', HtmlEditor)
Vue.component('CustomerUserGroupEditor', CustomerUserGroupEditor)
Vue.component('UploadsEditor', UploadsEditor)
Vue.component('UploadSingleEditor', UploadSingleEditor)
Vue.component('TicketSelect', TicketSelect)
Vue.component('SysSelect', SysSelect)
Vue.component('ContractsEditor', ContractsEditor)
Vue.component('CrmLeadContact', CrmLeadContact)
Vue.component('CrmFunnelPhase', CrmFunnelPhase)
Vue.component('CrmLeadSelect', CrmLeadSelect)

// Global helpers
window.Globals = { ...CustomerHelper}
