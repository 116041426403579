<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="align-items-center d-flex float-left">
          <img src="/img/clubedemidia-world-escudo.png" class="d-none d-print-inline"/>
          <h3>Relatório Gerencial de {{ coin_name }} <b>{{ customer_display_name }}</b></h3>
        </div>
        <div class="text-right" v-show="!show_filter">
          <button class="btn btn-primary" @click="print()">Imprimir</button>
        </div>
        <div class="dropdown-divider"></div>
      </div>

      <div class="card-body d-print-none" v-show="show_filter">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Cliente</label>
            <CustomerSelect
              v-model="search.customer_id"
              @row="fillCustomer"
            />
          </div>

          <div class="col-lg-6">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="load()">Consultar</button>
              <button class="btn btn-primary" @click="print()">Imprimir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Tipo de contrato</th>
              <th>{{ coin_name }} do contrato</th>
              <th>Data de assinatura</th>
              <th>Data de encerramento</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!contracts.length">
              <td colspan="5" class="text-center">Sem contratos</td>
            </tr>
            <template
              v-else
              v-for="row in contracts"
            >
              <tr>
                <td>{{ row.contract_type.name }}</td>
                <td v-text="row.contract_time"></td>
                <td>{{ format(row.contract_start_date) }}</td>
                <td>{{ format(row.contract_end_date) }}</td>
                <td>{{ row.active ? 'Sim' : 'Não' }}</td>
              </tr>
              <tr v-if="row.contract_type.is_managed || row.contract_type._id === '9215d0c0-fb9b-4823-8f99-1ec2a013c704'">
                <td colspan="5">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Ano/Mês<br />de apuração</th>
                        <th>Saldo do<br />Mês Anterior</th>
                        <th>Ajuste Interno<br />de {{ coin_name }}</th>
                        <th>{{ coin_name }}<br />Mensais</th>
                        <th>{{ coin_name }}<br />Líquidas</th>
                        <th>{{ coin_name }}<br />Utilizados</th>
                        <th>Saldo<br />de {{ coin_name }}</th>
                        <th>{{ coin_name }} Extras a<br /> Compensar (Até 30%)</th>
                        <th>{{ coin_name }} Extras a Pagar<br/> (Acima de 30%)</th>
                        <th>{{ coin_name }} Extras a <br />Pagar ou a Compensar</th>
                      </tr>
                    </thead>
                    <tbody v-if="row.contract_type._id === '9215d0c0-fb9b-4823-8f99-1ec2a013c704'">
                      <tr v-for="item in row.contract_items">
                        <td>{{ item.year_month }}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{{ item.balance }}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr v-for="item in row.contract_items">
                        <td>
                          <a :href="'#/relatorio/horas/trabalhadas/' + item.year_month">{{ item.year_month }}</a>
                        </td>
                        <td>{{ item.previous_balance }}</td>
                        <td v-text="item.manual"></td>
                        <td>{{ item.contract_time }}</td>
                        <td>{{ item.previous_balance_contract_time }}</td>
                        <td>{{ item.work_time }}</td>
                        <td>{{ item.balance }}</td>
                        <td>{{ item.extra_time_regular }}</td>
                        <td>{{ item.extra_time }}</td>
                        <td v-text="changeLabel(item.payment_option)"></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'
import {setArr} from '../../common'

export default {
  name: 'ContractsReport',
  data () {
    return {
      show_filter: window.auth.user_type !== 'C',
      customer_display_name: '',
      search: {
        customer_id: '',
        date: '',
      },
      contracts: [],
      coin_name: 'Escudos'
    }
  },
  watch: {
    'search.customer_id' (new_value) {
      if (new_value) {
        this.load()
      }
    }
  },
  beforeMount () {
    H.rpc('Customer', 'getCoinName', [], r => {
      if (r) {
        this.coin_name = r.coin_name
      }
    })
  },
  mounted () {
    if (window.auth.user_type === 'C') {
      this.search.customer_id = window.auth.customer_id
      return
    }

    this.search.customer_id = this.$route.params.customer_id || ''
  },
  methods: {
    fillCustomer (row) {
      this.customer_display_name = row.trade_name
    },
    load () {
      H.rpc('Contract', 'load', [this.search.customer_id], rows => {
        if (rows) {
          setArr(this, 'contracts', rows)
        }
      })
    },
    changeLabel (option) {
      if (option === 'C') {
        return 'Compensar Normais e Pagar Extras'
      }

      if (option === 'T') {
        return 'Compensar Tudo'
      }

      if (option === 'P') {
        return 'Pagar Tudo'
      }
    },
    getContractTimeLabel (time) {
      return time/60 + 'h | ' + time + 'min'
    },
    getManualTimeLabel (time) {
      return time + 'h | ' + time*60 + 'min'
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
  th {
    font-size: 0.7rem;
    background-color: rgba(0, 0, 0, 0.125);
  }
}
</style>
