<template>
    <div>
        <div class="d-flex justify-content-end border-bottom">
            <i class="la la-copy ml-2 mb-2 link" title="Copiar" @click="copy"></i>
            <i class="la la-paste ml-2 mb-2 link" title="Colar" @click="paste"></i>
        </div>

        <div v-for="group in current.groups" :key="group._id">
            <div class="row mt-4">
                <div class="col-6">
                    {{ group.name }}
                </div>
                <div class="col-5 pt-1">
                    <UserSelect
                        v-model="group.user_id"
                        :data="{group_id: group._id}"
                        init_placeholder="- Usuário padrão -"
                    />
                </div>
                <div class="col-1 pt-1">
                    <button class="btn btn-outline-primary w-100" title="Preencher todo o setor" @click="fillDown(group)">
                        <i class="la la-arrow-down ml-2"></i>
                    </button>
                </div>
                <hr>
                <div class="col-12">
                    <div v-for="el in itemsOf(group._id)" class="row">
                        <label class="col-6 px-4">
                            {{ el.name }}
                        </label>
                        <div class="col-6 pt-1 pl-4">
                            <UserSelect
                                v-model="el.user_id"
                                :data="{group_id: group._id}"
                                init_placeholder="- Usuário responsável -"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseComponent from './BaseComponent'
import {H, showError, showInfo} from 'hennig-common'
import UserSelect from './UserSelect'
import {setArr, setObj, valueAsArray} from '../common'

export default {
    components: {UserSelect},
    extends: BaseComponent,
    name: 'UserPhaseEditor',
    mounted () {
        this.baseMounted()
        this.fetch()
    },
    data () {
        return {
            groups: [],
            atual: '',
            anterior: '',
        }
    },
    methods: {
        * itemsOf (group_id) {
            for (const el of valueAsArray(this.current.users)) {
                if (el.group_id === group_id) {
                    yield el
                }
            }
        },
        fillDown (group) {
            for (const i in this.current.users) {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.current.users.hasOwnProperty(i)) continue
                if (group._id === this.current.users[i].group_id) {
                    this.current.users[i].user_id = group.user_id
                }
            }
        },
        setValue (v) {
            this.current = {}
            if (!v) return

            if (v.groups) {
                setArr(this.current, 'groups', v.groups)
            }
            if (v.users) {
                setArr(this.current, 'users', v.users)
            } else {
                setArr(this.current, 'users', v)
            }
        },
        copy () {
            localStorage.copied = JSON.stringify(this.current)
            showInfo('Configuração copiada')
        },
        paste () {
            if (!localStorage.copied) {
                showError('Não há nenhuma configuração copiada')
                return
            }

            setObj(this.current, JSON.parse(localStorage.copied))
        },
        getByID (_id) {
            for (const cur of this.current.users) {
                if (cur._id === _id) {
                    return cur
                }
            }
        },
        fetch () {
            H.rpc('Group', 'all', [], groups => {
                if (this.current.groups) {
                    for (const stored of valueAsArray(this.current.groups)) {
                        for (const group of valueAsArray(groups)) {
                            if (group._id === stored._id) {
                                group.user_id = stored.user_id
                                break
                            }
                        }
                    }
                } else {
                    setArr(this.current, 'groups', groups)
                }
            })

            H.rpc('Phase', 'all', [], phases => {
                if (phases) {
                    this.current.users = this.current.users || []
                    for (const cur of this.current.users) {
                        cur.delete = true
                    }

                    for (const phase of phases) {
                        const cur = this.getByID(phase._id)
                        if (cur) {
                            cur.name = phase.name
                            cur.group_id = phase.group_id
                            cur.group_name = phase.group_name
                            delete cur.delete
                            continue
                        }

                        this.current.users.push({
                            user_id: '',
                            ...phase
                        })
                    }

                    this.current.users = this.current.users.filter(el => !el.delete)
                }
            })
        }
    }
}
</script>
