<template>
  <div>
    <div class="cabecalho">
      TEMPOS E PRAZOS DOS JOBS
    </div>

    <table id="timelapse">
      <thead>
        <tr>
          <th class="larguranome" rowspan="2"><br />Nome do Job<br />Tipo de Serviço<br /><img src="img/timelapse.png" width="350px" height="1px"></th>
          <th rowspan="2"><br />Tempo Cobrado<img src="img/timelapse.png" width="55px" height="1px"></th>
          <th rowspan="2"><br />Tempo Real<img src="img/timelapse.png" width="55px" height="1px"></th>
          <th rowspan="2"><br />%<br />Cobrada<img src="img/timelapse.png" width="55px" height="1px"></th>
          <th colspan="2" v-for="i in 40" v-text="'Dia ' + i"></th>
        </tr>
        <tr>
          <th v-for="i in 80" v-text="i % 2 === 0 ? 'T' : 'M'" class="larguraTurno"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rows[0]">
          <template v-if="row.execution_time === 0">
            <td class="larguranome cursor-pointer" :title="row.name" v-on:click="goToTickets(row._id)">{{ row.name }}</td>
            <td class="larguratempo" title="Varia conforme a quantidade de peças e demanda">
              <img src="img/alerta.png" width="15px" height="14px">
            </td>
            <td class="larguratempo">-</td>
            <td class="larguratempo">-</td>
          </template>
          <template v-else>
            <td class="larguranome cursor-pointer" :title="row.name + ' :: ' + row.days" v-on:click="goToTickets(row._id)">{{ row.name }}</td>
            <td class="larguratempo">{{ row.execution_time_frm }}</td>
            <td class="larguratempo">{{ row.phase_time_frm }}</td>
            <td class="larguratempo">{{ row.diff }}</td>
          </template>
          <td
            class="bold baseItemColor"
            v-for="chart in row.phase_chart"
            :style="{'background-color': `#${chart[0]}`}"
            :title="chart[3] + ' :: ' + chart[4] + ' ' + chart[5] + ' . ' + chart[2]"
            :colspan="chart[4]"
          >{{ chart[1] }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="naoesqueca">
      NÃO ESQUEÇA
    </div>

    <div class="complemento">
      <h3>ENTREGA DE ARTE FINAL</h3>
      O prazo para realização e entrega da arte final é de 1 dia após a aprovação do cliente.<br /><br />

      <h3>ALTERAÇÃO SIMPLES DE PEÇAS</h3>
      O prazo para realização de ajustes simples na arte enviada ao cliente é de 1 dia após o retorno deste e <b>será sempre efetuado sem cobrança por até 2x (duas vezes).</b><br /><br />

      <h3>RECRIAÇÃO DE MATERIAL</h3>
      O prazo para refazer um job eventualmente não aprovado é de <b>metade do seu tempo original.</b><br /><br />

      <h3>JOBS NÃO PREVISTOS</h3>
      Os trabalhos não previstos nesta tabela serão tratados de forma especial em cada um dos casos, devendo o cliente consultar diretamente o seu atendimento.<br /><br />

      <h3>HORAS QUE SOBRAM</h3>
      O valor por hora de cada job segue o contratatado pelo cliente.<br />
      Serão adicionados ao mês seguinte o número de horas que sobrarem do tempo contratado no mês anterior.<br /><br />

      <h3>HORAS EXCEDENTES ATÉ 30%</h3>
      O valor por hora de cada job segue a proposta/contrato do cliente, sendo que caso o trabalho do mês exceda o número de horas contratado em até 30%, o valor da fatura das horas excedentes é calculado conforme o valor do contrato, sendo que é facultado ao cliente a compensação dessas horas no mês seguinte mediante diminuição do fluxo de trabalho.<br /><br />

      <h3>HORAS EXCEDENTES EM MAIS DE 30%</h3>
      O valor por hora de cada job segue a proposta/contrato do cliente. As horas excedentes acima de 30% da quantidade de horas contratadas correrão pelo valor de tabela da agência, estipulado em R$ 110,00 não podendo ser compensadas eis que acarretam hora extra de trabalho dos colaboradores da agência.<br /><br />
    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'

export default {
  name: 'JobTypeReport',
  data () {
    return {
      rows: [],
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      H.rpc('JobType', 'report', [], r => {
        if (r) {
          this.rows.push(r)
        }
      })
    },
    goToTickets (id) {
      window.open('#/tickets/job/' + id, '_blank')
    }
  }
}
</script>

<style scoped>
body {
    overflow-x: scroll !important;
}

td.bold {
    font-weight: bold;
}

.cabecalho{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px 30px 0px;
    padding: 30px;
    background-color: crimson;
    border: solid 2px black;
    color: black;
}

.naoesqueca {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 30px;
    padding: 30px;
    background-color: gold;
    border: solid 2px black;
    color: black;
}

#timelapse {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    border-collapse: collapse;
    color: black;
    width: 3000px;
}

#timelapse td, #timelapse th {
    border: 1px solid #ddd;
    padding: 6px;
    text-align: center;
}

#timelapse th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
}

.larguranome{
    width: 340px !important;
    text-align: left !important;
}

.larguratempo{
    width: 50px !important;
    text-align: center !important;
}

.complemento {
    margin: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: black;
}

.complemento h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.baseItemColor {
    color:#fff;
    font-size:11px;
}

.larguraTurno {
    width: 30px !important;
}
</style>
