<template>
  <div class="container-fluid p-2">
    <div class="card">
      <div class="card-body">
        <h3>Ficha do Cliente <b>{{ customer_name }}</b></h3>
        <div class="dropdown-divider"></div>
      </div>

      <div v-if="!read_only" class="card-body">
        <div class="form-group row">
          <div class="col-lg-12">
            <label>Cliente</label>
            <CustomerSelect
              ref="customer_id"
              v-model="customer_id"
              class="form-control "
              style="padding-top: 2px"
              @row="fillCustomer"
            />
          </div>
        </div>
      </div>

      <div v-show="customer_id" class="card-body">
        <ul class="nav nav-tabs">
          <!-- <li v-if="canAccess('ficha_aba_wiki')" class="nav-item"> -->
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#ficha">Ficha do Cliente</a>
          </li>

          <!-- <li v-if="canAccess('ficha_aba_data_importante') && !read_only" class="nav-item"> -->
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              href="#editar"
            >Datas Importantes</a>
          </li>

          <!-- <li v-if="canAccess('ficha_aba_arquivos_fundamentais')" class="nav-item"> -->
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#arquivos">Arquivos Fundamentais</a>
          </li>

          <li v-if="canAccess('menu_cliente_usuarios')" class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#usuarios_cliente">Usuários do Cliente</a>
          </li>
        </ul>

        <div class="tab-content border-top-0 border p-4">
          <!-- <div v-if="canAccess('ficha_aba_wiki')" id="ficha" class="tab-pane fade show active" role="tabpanel"> -->
          <div id="ficha" class="tab-pane fade show active" role="tabpanel">
            <div class="form-group row">
              <div class="col-lg-3">
                <div class="border-bottom">
                  <i class="la la-home link" @click="clear"></i>
                </div>
                <div>
                  <div
                    v-for="topic in topicsSorted()"
                    :key="topic._id"
                    :class="topicClass(topic)"
                    class="topic"
                    @click="loadTopic(topic)"
                    v-text="topic.name"
                  ></div>
                </div>
              </div>
              <div class="col-lg-9">
                <div v-if="current_topic">
                  <div class="d-flex justify-content-between mb-2">
                    <label class="flex-grow-1" v-text="current_topic.name"></label>
                    <div
                      v-if="state === 'view'"
                      class="btn btn-secondary btn-sm ml-2"
                      @click="state = 'edit'"
                    >Editar
                    </div>
                    <div
                      v-if="state === 'edit'"
                      class="btn btn-secondary btn-sm ml-2"
                      @click="saveTopic()"
                    >Salvar
                    </div>
                  </div>

                  <HtmlEditor
                    ref="obs"
                    :disabled="state === 'view'"
                    :toolbar="state === 'edit'"
                  />

                  <label class="mb-2">Arquivos deste tópico</label>

                  <UploadsEditor
                    :max-size="50000000"
                    :param="{wiki_id: wiki_id}"
                    :read_only="state === 'view'"
                  />
                </div>
                <div v-else>
                  <label class="mb-2">Últimas modificações</label>
                  <table class="table table-sm border">
                    <tr v-for="log in logs">
                      <td class="blue">{{ log.user_name }}</td>
                      <td class="small text-muted">{{ formatDateTime(log.created_at) }}</td>
                      <td>{{ log.action }}</td>
                      <td>{{ log.text }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="canAccess('ficha_aba_data_importante') && !read_only" id="editar" class="tab-pane fade" role="tabpanel"> -->
          <div id="editar" class="tab-pane fade" role="tabpanel">
            <h5>Aqui ficam registradas as datas importantes como fundação da empresa, padroeiro de cidades e tantos outros marcos especiais para que seja salientado no nosso calendário</h5>
            <div class="dropdown-divider mb-4"></div>
            <table ref="calendar_list" class="table table-sm table-hover table-striped">
              <thead>
                <tr>
                  <th data-column-id="_id" data-visible="false">ID</th>
                  <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>
                  <th data-align="center" data-column-id="x" data-formatter="x" data-sortable="false" data-width="36px"></th>
                  <th data-align="center" data-column-id="active" data-formatter="checkbox" data-width="36px"></th>
                  <th data-column-id="name" data-sortable="true">Descrição</th>
                  <th data-column-id="full_fmt">Data</th>
                </tr>
              </thead>
            </table>
          </div>
          <!-- <div v-if="canAccess('ficha_aba_arquivos_fundamentais')" id="arquivos" class="tab-pane fade" role="tabpanel"> -->
          <div id="arquivos" class="tab-pane fade" role="tabpanel">
            <h5>Aqui você inclui os arquivos fundamentais do cliente como fontes, folha timbrada, cartões de visita, logotipos em todas as versões e todos os
              materiais de download automático que o cliente possa precisar. <br>Assim evitamos que o cliente precise ficar pedindo acesso aos arquivos e
              tenhamos que enviar um a um.</h5>
            <div class="dropdown-divider mb-4"></div>
            <div class="form-group row">
              <div class="col-lg-12">
                <UploadsEditor
                  :max-size="50000000"
                  :param="{ customer_id }"
                  :read_only="read_only"
                />
              </div>
            </div>
          </div>

          <div v-if="canAccess('menu_cliente_usuarios')" id="usuarios_cliente" class="tab-pane fade" role="tabpanel">
            <UsersCustomer
              :customer_id="customer_id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {H, initGrid, showInfo} from 'hennig-common'
import {empty} from '../common'
import UsersCustomer from './UsersCustomer'

export default {
  name: 'CustomerObs',
  components: {UsersCustomer},
  data () {
    return {
      state: 'view',
      read_only: false,
      customer_id: '',
      customer_name: '',
      wiki_id: '',
      current_topic: '',

      logs: [],
      topics: []
    }
  },
  watch: {
    customer_id () {
      if (this.read_only) {return}
      this.load()
    }
  },
  mounted () {
    $(this.$el).find('#quill-container').css({
      'min-height': '50vh'
    })

    this.customer_id = this.$route.params._id || ''
    this.$refs.customer_id.setValue(this.customer_id)

    setTimeout(() => {
      if (this.$route.query.tab) {
        $(`[href="#${this.$route.query.tab}"]`).tab('show')
      }
    }, 10)

    this.loadTopics()
  },
  methods: {
    clear () {
      this.wiki_id = ''
      this.current_topic = ''
    },
    fillCustomer (row) {
      if (!row) {
        this.customer_name = ''
        return
      }

      this.customer_name = row.trade_name || row.name
    },
    topicClass (topic) {
      const response = {
        selected: this.current_topic._id === topic._id,
      }

      response[`lvl-${topic.lvl}`] = true
      return response
    },
    topicsSorted () {
      const response = []
      const work = function (topics) {
        if (!topics) {return}
        for (const topic of topics) {
          response.push({
            _id: topic._id,
            name: topic.name,
            tpl_wiki: topic.tpl_wiki,
            lvl: topic.lvl
          })

          work(topic.childs)
        }
      }

      work(this.topics)
      return response
    },
    saveTopic (cb = empty) {
      const data = {
        _id: this.wiki_id,
        customer_id: this.customer_id,
        topic_id: this.current_topic._id,
        content: this.$refs.obs.getValue()
      }

      H.rpc('Wiki', 'save', [data], r => {
        if (!r) {return}
        this.state = 'view'
        showInfo('Capítulo foi salvo')
        cb()
      })
    },
    loadTopic (topic) {
      if (this.current_topic && this.state === 'edit') {
        // Existe um topico ativo, perguntar se salvar ou continuar sem salvar
        this.dialogs().Confirmation.show({
          text: 'Deseja salvar o capítulo atual antes de continuar?',
          primary_text: 'Salvar',
          secondary_text: 'Continuar sem salvar',
        }, r => {
          if (r) {
            this.saveTopic(() => {
              this.loadTopic(topic)
            })

            return
          }

          this.state = 'view'
          this.loadTopic(topic)
        })

        return
      }

      this.wiki_id = ''
      this.$refs.obs && this.$refs.obs.setValue('')

      H.rpc('Wiki', 'topic', [this.customer_id, topic._id], row => {
        if (!row) {return}

        this.wiki_id = row._id
        this.current_topic = topic
        this.$nextTick(() => {
          this.$refs.obs.setValue(row.content)
        })
      })
    },
    loadTopics () {
      this.topics = []
      H.rpc('Wiki', 'topics', [], topics => {
        if (topics) {
          this.topics = topics
        }
      })
    },
    load () {
      this.wiki_id = ''
      this.current_topic = ''
      this.loadCalendarList()
      this.loadCalendar()
      this.loadWikiLogs()
    },
    loadWikiLogs () {
      this.logs = []
      H.rpc('Wiki', 'logs', [this.customer_id], logs => {
        if (!logs) {return}
        this.logs = logs
      })
    },
    loadCalendarList () {
      if (this.gridInstance) {
        this.gridInstance.bootgrid('reload')
        return
      }

      this.gridInstance = initGrid({
        search: () => ({
          customer_id: this.customer_id
        }),
        container: $(this.$refs.calendar_list),
        collectionObj: 'Calendar',
        addLabel: 'Nova data importante',
        customAdd: this.addCalendarItem,
        customEdit: this.addCalendarItem
      })
    },
    loadCalendar (from, to) {
      if (!this.customer_id) {return}

      from = from || moment().format('YYYY-MM')
      to = to || moment().add(8, 'month').format('YYYY-MM')

      H.rpc('Calendar', 'customer', [from, to, this.customer_id], (r, e) => {
        if (r) {
          this.attributes = []

          for (let customData of r) {
            let attribute = {
              highlight: true,
              customData,
              popover: {
                label: customData.name,
              },
              dates: moment(customData.full).toDate()
            }

            this.attributes.push(attribute)
          }
        }
      })
    },
    addCalendarItem (classname, $grid, _id) {
      const that = this
      const customer_id = this.customer_id
      const calendar_type_id = 'customer'
      H.rpc('Calendar', 'form', [_id, {customer_id, calendar_type_id}], (r, e) => {
        if (r) {
          H.createForm(r, {
            onstore (evnt, data, $form) {
              that.loadCalendar()
              that.loadCalendarList()
              $form.closest('.modal').modal('hide')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.invalid-feedback {
    display: initial;
}

.topic {
    margin-bottom: .25em;
    cursor: pointer;
}

.blue {
    color: darkblue;
}

.topic.selected {
    color: darkblue;
}

.lvl-1 {
    padding-left: 1em;
}

.lvl-2 {
    padding-left: 2em;
}

.lvl-3 {
    padding-left: 3em;
}
</style>
