<template>
    <BaseModal
        ref="BaseModal"
        title="Mudança de Fase"
    >
        <div>
            Deseja realmente voltar para uma fase anterior?
        </div>
        <div class="mt-2">
            <label>Especiais</label>
            <div class="list-group">
                <div v-for="phase in special_phases" class="list-group-item">
                    <div>
                        <div>{{ phase.short_name || phase.name }}</div>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-sm" @click="confirm(phase)">Selecionar</button>
                    </div>
                </div>
            </div>

            <label>Completadas</label>
            <div class="list-group mb-2">
                <div v-for="phase in completed" class="list-group-item">
                    <div>
                        <div>{{ phase.short_name || phase.name }}</div>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-sm" @click="confirm(phase)">Selecionar</button>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:footer>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'
import {H} from 'hennig-common'

export default {
    name: 'ConfirmPrior',
    components: {BaseModal},
    data () {
        return {
            ticket_id: '',
            completed: [],
            special_phases: [],
            form: {
                phase_id: ''
            }
        }
    },
    methods: {
        load () {
            H.rpc('Ticket', 'completedPhases', [this.ticket_id], rows => {
                this.completed = rows
            })
        },
        loadSpecialPhases () {
            H.rpc('Ticket', 'specialPhases', [this.ticket_id], phases => {
                this.special_phases = phases || []
            })
        },
        show (ticket_id, callback) {
            this.ticket_id = ticket_id
            this.load()
            this.loadSpecialPhases()

            this.$refs.BaseModal.show()
            this.callback = callback
        },
        confirm (phase) {
            this.callback({phase_idx: phase.idx})
            this.$refs.BaseModal.hide()
        }
    }
}
</script>

<style scoped>
.list-group-item {
    display: flex;
    justify-content: space-between;
    padding: .25rem;
}
</style>
