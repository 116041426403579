<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Tickets dos Meus Clientes</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui você tem acesso a todos os tickets de clientes que você é envolvido, independente da fase, status ou quando ele foi criado.</h5>
            </div>
        </div>

        <div class="card">
            <div class="card-body ">
                <table ref="my_tickets" class="table table-sm table-hover table-striped">
                    <TableHeaders/>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'
import {approval, effective_approval, phase_dates, projection, TableHeaders} from '../formmaters'

const formatters = {
    effective_approval,
    approval,
    projection,
    phase_dates,
    numPadding (column, row) {
        const combo = '<img height="12" class="mr-2" src="img/arrow-bottom-rigth.svg"/>'
        const parent = '<img height="12" class="mr-2" src="img/angle-double-right-solid.svg"/>'

        if (row.combo_ticket_id && row.parent_ticket_id) {
            return combo + parent + `<span>${row.num}</span>`
        }

        if (row.combo_ticket_id) {
            return combo + `<span>${row.num}</span>`
        }

        if (row.parent_ticket_id) {
            return parent + `<span>${row.num}</span>`
        }

        return row.num
    }
}
export default {
    name: 'MyTickets',
    components: {
        TableHeaders
    },
    data () {
        return {}
    },
    mounted () {
        this.init()
    },
    methods: {
        init () {
            const collectionObj = 'Ticket'
            const rowClick = true

            initGrid({
                container: $(this.$refs.my_tickets),
                collectionObj,
                noAddButton: true,
                rowClick,
                search: {my_tickets: true},
                formatters
            })
        }
    }
}
</script>

<style scoped>
.card-header {
    font-weight: bold;
    font-size: 1.2em;
}

.card {
    margin-bottom: 1em;
}

::v-deep .bootgrid-header,
::v-deep .bootgrid-footer,
::v-deep .pagination {
}
</style>
