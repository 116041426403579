<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <img src="/img/clubedemidia-world-escudo.png" class="d-none d-print-inline"/>
          <div>
            <h3>Relatório de horas <b>{{ customer_display_name }}</b></h3>
            <i>{{ search.date }}</i>
          </div>
        </div>
        <div class="dropdown-divider"></div>
      </div>
      <div class="card-body d-print-none">
        <div class="form-group row">
          <div class="col-lg-3">
            <label>Mês referência</label>
            <select
              ref="date"
              v-model="search.date"
              class="form-control"
              placeholder="MM/AAAA"
            >
              <option v-for="value in 13" :value="format(value)" v-text="format(value)"></option>
            </select>
          </div>

          <div class="col-lg-3">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary mr-1" @click="load()">Consultar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-left">
          <h3>
            Contrato <b>{{ contract_time }}h</b> |
            Mês Anterior <b>{{ previous_balance }}h</b> |
            Horas líquidas <b>{{ previous_balance_contract_time }}h</b>
          </h3>
        </div>
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Tipo de<br />Serviço</th>
              <th>Data<br />de Início</th>
              <th>Status<br />do Job</th>
              <th>Tempo de<br />Execução</th> <!-- Informado no ticket ou padrao -->
              <th>Saldo<br />de Horas</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!rows.length">
              <td colspan="7" class="text-center">Sem dados para exibir</td>
            </tr>
            <tr v-else v-for="row in rows">
              <td>{{ row.description }}</td>
              <td>{{ format(row.start_at) }}</td>
              <td>{{ row.status }}</td>
              <td>{{ row.execution_time_h }}</td>
              <td>{{ row.balance_h }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H} from 'hennig-common'
import moment from 'moment'
import {setArr} from '../../common'

export default {
  name: 'WorkedHours',
  data () {
    return {
      search: {
        date: '',
      },
      customer_display_name: '',
      contract_time: 0,
      previous_balance: 0,
      previous_balance_contract_time: 0,
      rows: [],
      currentMonth: moment().format('MM/YYYY')
    }
  },
  mounted () {
    this.search.customer_id = window.auth.customer_id
    this.search.date = this.$route.params.mes || moment().format('YYYY-MM')
    this.load()
  },
  methods: {
    fillCustomer (row) {
      this.customer_display_name = row.trade_name
    },
    load () {
      H.rpc('Customer', 'workedHoursReport', [this.search], r => {
        if (r) {
          setArr(this, 'rows', r.rows)
          this.contract_time = r.header.contract_time || 0
          this.previous_balance = r.header.previous_balance || 0
          this.previous_balance_contract_time = r.header.previous_balance_contract_time || 0
        }
      })
    },
    format (value) {
      if (value-1 === 0) {
        return this.currentMonth
      }

      return moment().subtract(value-1, 'month').format('MM/YYYY')
    }
  }
}
</script>

<style scoped>
td.bold {
    font-weight: bold;
}

.chart-item {
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid silver;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
</style>
