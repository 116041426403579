<template>
  <div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-overlay"></div>
    <div class="content-body">
      <div class="auth-wrapper auth-v2">
        <div class="auth-inner row m-0">
          <div class="d-none d-lg-flex col-lg-8 p-0 align-items-center">
            <div class="w-100 d-lg-flex align-items-center justify-content-center"><img class="img-fluid" src="img/mario-luigi-bg.png" alt="Login V2" /></div>
          </div>
          <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
              <div class="text-center mb-3">
                <img height="80" src="img/clubedemidia-world.png"/>
              </div>
              <div class="auth-login-form mt-2">
                <p class="text-center mt-2 h4 text-danger"><span>Já faz um tempinho que tu não troca a senha né! Então, bora lá!</span></p>
                <div class="form-group">
                  <input
                    v-model="form.oldpass"
                    class="form-control form-control-merge"
                    id="oldpass"
                    type="password"
                    name="oldpass"
                    placeholder="Senha antiga"
                    tabindex="2"
                    autocomplete="off"
                    required
                    v-on:keyup.enter="login()"
                  />
                </div>

                <div class="form-group">
                  <div class="input-group input-group-merge form-password-toggle">
                    <input
                      v-model="form.newpass"
                      class="form-control form-control-merge"
                      id="newpass"
                      type="password"
                      name="newpass"
                      placeholder="Senha nova"
                      tabindex="2"
                      autocomplete="off"
                      required
                      v-on:keyup.enter="login()"
                    />
                  </div>
                </div>

                <div class="text-center mb-2">
                  <button class="btn btn-warning btn-block" tabindex="4" v-on:click="trocar()">Trocar</button>
                </div>
              </div>
              <p class="alert-danger h4 mt-2 p-2 rounded text-center" v-if="message.length > 0"><span v-text="message"></span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {H} from 'hennig-common'

export default {
  name: 'ChangePass',
  data () {
    return {
      windowWidth: window.innerWidth,
      message: '',
      form: {
        oldpass: '',
        newpass: ''
      }
    }
  },
  methods: {
    trocar (params = this.form) {
      let self = this
      H.rpc('Auth', 'changePass', [params], r => {
        if (r) {
          if(r.error) {
            self.message = r.message
            return
          }

          window.changePass = false
          window.redirect('/')
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
