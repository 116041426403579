<template>
    <div>
        Aguarde...
    </div>
</template>

<script>

export default {
    name: 'Initial',
    mounted () {
        setTimeout(() => {
            this.$router.replace('/login')
        }, 1)
    }
}
</script>

<style scoped>
    .bg-initial {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-image: url("/img/cm-bg-login-2.jpg");
        background-size: cover;
    }
</style>
