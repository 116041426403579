<template>
    <BaseModal
        ref="BaseModal"
        title="Adicionar E-mail"
    >
        <div class="mb-2">
            <input
                v-model="mail"
                autocomplete="no"
                class="form-control form-control-lg"
                placeholder="Informe o e-mail de destino"
                type="email"
            />
        </div>
        <div>
            Os arquivos desta fase serão adicionados na fila de envio.
            Deseja realmente adicionar?
        </div>
        <hr>
        <template v-slot:footer>
            <button
                :disabled="!mail"
                class="btn btn-primary"
                @click="confirm()"
            >Adicionar
            </button>
        </template>
    </BaseModal>
</template>

<script>
import {showError} from 'hennig-common'
import BaseModal from '../components/BaseModal'

export default {
    name: 'AddSubmitEmail',
    components: {BaseModal},
    data () {
        return {
            mail: ''
        }
    },
    methods: {
        show (callback) {
            this.$refs.BaseModal.show()
            this.callback = callback
        },
        confirm () {
            if (!this.mail) {
                showError('Você precisa informar um e-mail')
                return
            }

            this.callback(this.mail)
            this.$refs.BaseModal.hide()
        }
    }
}
</script>

<style scoped>

</style>
