<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <img src="/img/clubedemidia-world-escudo.png" class="d-none d-print-inline"/>
          <h3>Pedido Cafeteria</h3>
        </div>
        <div class="dropdown-divider"></div>
      </div>

      <div class="card-body d-print-none">
        <div class="row justify-content-center">
          <div class="form-group col-md-12">
            <label>Data do pedido</label>
            <DateTimePicker
              v-model="order_date"
              id="coffee_order_date"
              ref="coffee_order_date"
            />
          </div>
          <div class="form-group col-md-12">
            <label>Usuário</label>
            <select class="form-control" id="coffe_user_id" v-model="user_id">
              <option value="">Selecione um usuário</option>
              <option v-for="user in users" :value="user._id" v-text="user.name"></option>
            </select>
          </div>
          <div class="form-group col-md-12">
            <label class="custom-font-size">Itens</label>
            <section id="accordion">
              <div class="row">
                <div class="col-sm-12">
                  <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
                    <div class="card collapse-icon">
                      <div class="card-body p-0">
                        <div class="collapse-default">
                          <div class="card" v-for="(product, indice) in products">
                            <div :id="'heading ' + indice" class="card-header collapsed" data-toggle="collapse" role="button" :data-target="'#accordion' + indice" aria-expanded="false" :aria-controls="'accordion' + indice">
                              <span class="lead collapse-title"> {{ product.name }} </span>
                            </div>
                            <div :id="'accordion' + indice" role="tabpanel" data-parent="#accordionWrapa1" :aria-labelledby="'heading ' + indice" class="collapse" style="">
                              <table class="table m-1">
                                <thead>
                                  <tr>
                                    <th class="width-70-per">Produto</th>
                                    <th class="width-10-per">Preço</th>
                                    <th class="width-10-per">Quantidade</th>
                                    <th class="width-10-per text-right">Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item in product.products">
                                    <td>{{ item.name }}</td>
                                    <td>{{ getFormatedValue(item.price) }}</td>
                                    <td><input v-model="item.quantity" type="number" class="form-control"></td>
                                    <td class="text-right">{{ getFormatedValue(item.quantity * item.price) }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="col-lg-12">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary mr-2" @click="add()">Adicionar</button>
              <button class="btn btn-danger" @click="cancel()">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center custom-width"> </th>
              <th>Data</th>
              <th>Hora</th>
              <th>Produto</th>
              <th>Preço</th>
              <th>Quantidade</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!result.length">
              <td colspan="7" class="text-center">Sem itens</td>
            </tr>
            <tr v-else v-for="(row, idx) in result">
              <td>
                <trash-icon class="cursor-pointer text-danger" size="1.5x" v-on:click="removeItem(idx)"></trash-icon>
              </td>
              <td>{{ row.date }}</td>
              <td>{{ row.hour }}</td>
              <td>{{ row.product_name }}</td>
              <td>{{ getFormatedValue(row.price) }}</td>
              <td>{{ row.quantity }}</td>
              <td>{{ row.value }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="result.length">
              <td colspan="6" class="text-right font-weight-bolder">Total</td>
              <td class="font-weight-bolder" v-text="getFormatedValue(total)"></td>
            </tr>
          </tfoot>
        </table>

        <div v-if="result.length" class="col-lg-12">
          <label>&nbsp;</label>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary mr-2" v-on:click="finish()">Finalizar pedido</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker from '../components/DateTimePicker'
import {H, initGrid, showInfo, showError} from 'hennig-common'
import {TrashIcon} from 'vue-feather-icons'
import moment from 'moment'
export default {
  name: 'CoffeeOrder',
  components: {
    TrashIcon,
    moment,
    DateTimePicker
  },
  data () {
    return {
      products: [],
      users: [],
      user_id: '',
      order_date: '',
      product: '',
      result: [],
      quantity: 1,
      total: 0,
      totalquantity: 0
    }
  },
  mounted () {
    H.rpc('CoffeeProduct', 'productsWithGroup', [], r => {
      if (r) {
        this.products = r
      }
    })

    H.rpc('User', 'all', [], r => {
      if (r) {
        this.users = r
      }
    })
  },
  methods: {
    add () {
      if (this.user_id === '') {
        showError('Um usuário deve ser selecionado!')
        return
      }

      let orderDate = moment().format('DD/MM/yyyy'),
        orderTime = moment().format('HH:mm:ss'),
        dateTimeOrder = moment().format('yyyy-MM-DD HH:mm:ss')

      if (this.order_date) {
        orderDate = moment(this.order_date).format('DD/MM/yyyy')
        orderTime = moment(this.order_date).format('HH:mm:ss')
        dateTimeOrder = moment(this.order_date).format('yyyy-MM-DD HH:mm:ss')
      }

      $('#coffe_user_id').prop('disabled', 'disabled')
      $('#coffee_order_date').prop('disabled', 'disabled')
      let self = this
      Object.entries(self.products).forEach( (key, value) => {
        Object.entries(key[1].products).forEach( (keyI, valueI) => {
          if (keyI[1].quantity > 0) {
            self.result.push({
              user_id: self.user_id,
              date: orderDate,
              hour: orderTime,
              dateTimeOrder: dateTimeOrder,
              datetime: moment(),
              product_id: keyI[1]._id,
              product_name: keyI[1].name,
              quantity: keyI[1].quantity,
              price: keyI[1].price,
              value: self.getFormatedValue(keyI[1].quantity * keyI[1].price)
            })

            self.total += (keyI[1].quantity * keyI[1].price)

            keyI[1].quantity = 0
            self.totalquantity += keyI[1].quantity
          }
        })
      })
    },
    cancel () {
      this.dialogs().Confirmation.show({
        text: 'Deseja realmente cancelar esse pedido?',
        primary_text: 'Não',
        secondary_text: 'Sim',
        secondary_class: 'btn btn-danger'
      }, r => {
        if (!r) {
          this.order_date = ''
          this.user_id = ''
          this.result = []
          this.total = 0
          this.totalquantity = 0
          $('#coffe_user_id').prop('disabled', false)
        }

        return
      })
    },
    finish () {
      let self = this

      this.dialogs().Confirmation.show({
        text: 'Confirmar pedido no total de R$ ' + this.total
            + ' para ' + $( '#coffe_user_id option:selected' ).text() + '?',
        primary_text: 'Sim',
        secondary_text: 'Não',
      }, r => {
        if (r) {
          H.rpc('CoffeeOrder', 'newOrder', [this.result], r => {
            if (r.success) {
              showInfo('Pedido realizado com sucesso!')
              self.user_id = ''
              self.result = []
              self.total = 0
              self.totalquantity = 0
              $('#coffe_user_id').prop('disabled', false)
              return
            }
            showError('Deu ruim, chama o suporte!')
          })
        }

        return
      })
    },
    getFormatedValue (value) {
      if (!isNaN(value) && value < 1){
        return '-'
      }

      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value)
    },
    removeItem (idx) {
      this.dialogs().Confirmation.show({
        text: 'Deseja realmente remover esse item?',
        primary_text: 'Não',
        secondary_text: 'Sim',
        secondary_class: 'btn btn-danger'
      }, r => {
        if (!r) {
          this.total -= this.result[idx].quantity * this.result[idx].price
          this.result.splice(idx, 1)
        }

        return
      })
    }
  }
}
</script>

<style scoped>
 .custom-width {
     width: 40px;
 }
 .custom-font-size {
     font-size: 13px;
 }
</style>
