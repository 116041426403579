export default {
    copyAddr () {
        $('[name=zip_shipment]').val( $('[name=zip]').val() )
        $('[name=address_shipment]').val( $('[name=address]').val() )
        $('[name=district_shipment]').val( $('[name=district]').val() )
        $('[name=city_shipment]').val( $('[name=city]').val() )
        $('[name=state_shipment]').val( $('[name=state]').val() )
        $('[name=country_shipment]').val( $('[name=country]').val() )
    },
    setUserIdGroup (v) {
        $('.to_user_id').trigger('H.setProp', {group_id: v})
    }
}
