<template>
  <BaseModal
    ref="BaseModal"
    title="Editar tempo do ticket"
  >
    <div class="font-weight-bold">
      Deseja realmente alterar o tempo desse ticket?
    </div>
    <hr>
    <div class="mt-2">
      O tempo atual desse ticket é de: <span v-text="currenttime"></span> minutos
    </div>
    <div class="mt-2">
      Novo tempo: <input type="text" class="form-control" v-model="new_time">
    </div>
    <template v-slot:footer>
      <div class="btn btn-primary" @click="confirm()">Trocar tempo</div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../components/BaseModal'
import {showError} from 'hennig-common'

export default {
  name: 'EditTime',
  components: {BaseModal},
  data () {
    return {
      new_time: '',
      currenttime: ''
    }
  },
  methods: {
    show (currentTime, callback) {
      this.currenttime = currentTime
      this.new_time = ''
      this.$refs.BaseModal.show()
      this.callback = callback
    },
    confirm () {
      if (!this.new_time) {
        showError('Um tempo precisa ser definido')
        return
      }

      this.callback({newtime: this.new_time})
      this.new_time = ''
      this.$refs.BaseModal.hide()
    }
  }
}
</script>

<style scoped>
::v-deep .modal-content {
    min-height: 400px;
}
</style>
