<template>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="card-body pt-0 pb-0">
        <div class="d-flex align-items-center">
          <div>
            <h3>Bem vindo <b>{{ userName }}</b></h3>
            <i>{{ companyName }}</i>
          </div>
        </div>
      </div>
    </div>
    <div class="content-overlay"></div>
    <div class="content-body">
      <!-- Stats Vertical Card -->
      <div class="row mt-2">
        <div class="col-xl-6 col-sm-6 col-12">
          <div class="card text-center">
            <div class="card-body cursor-pointer" v-on:click="goTo('/tickets')">
              <h2 class="font-weight-bolder">{{ myOpenTickets }}</h2>
              <p class="card-text">Total de Tickets na Pauta</p>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6 col-12">
          <div class="card text-center">
            <div class="card-body cursor-pointer" v-on:click="goTo('/tickets/92440719-8d94-4130-beba-3fc835188095')">
              <h2 class="font-weight-bolder">{{ myOpenTicketsInApproval }}</h2>
              <p class="card-text">Tickets Aguardando Aprovação</p>
            </div>
          </div>
        </div>
      </div>
      <!--/ Stats Vertical Card -->
    </div>
    <div class="content-body">
      <div class="row mt-2">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Status dos Tickets | Por mês</h4>
            </div>
            <div class="pl-2 pr-2">
              <bar-chart ref="chartBarLine" :chartdata="seriesAllTickets" :options="options"></bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row mt-2">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Estatísticas de Tickets por Área</h4>
            </div>
            <div class="pl-2 pr-2">
              <bar-chart ref="chartTicketsArea" :chartdata="seriesTicketsArea" :options="options"></bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row mt-2">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Consumo de Horas Mensal em minutos</h4>
            </div>
            <div class="pl-2 pr-2">
              <bar-chart ref="chartBarLineTime" :chartdata="seriesTime" :options="options"></bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { H } from 'hennig-common'
import BarChart from '../components/CustomerBarChart'

export default {
  name: 'CustomerHome',
  components: {
    'bar-chart': BarChart,
  },
  data () {
    return {
      myOpenTickets: 0,
      myOpenTicketsInApproval: 0,
      userName: window.auth.user_name,
      companyName: window.auth.company_name,
      seriesAllTickets: {},
      seriesTime: {},
      seriesTicketsArea: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false
        }
      }
    }
  },
  mounted () {
    let self = this
    H.rpc('Dashboard', 'HeaderCustomerData', [], (r) => {
      if (r) {
        this.myOpenTickets = r.myOpenTickets
        this.myOpenTicketsInApproval = r.myOpenTicketsInApproval
      }
    })

    H.rpc('Dashboard', 'CustomerAllTicketsData', [], (r) => {
      if (r) {
        self.seriesAllTickets.labels = r.labels
        self.seriesAllTickets.datasets = r.infos
        self.$refs.chartBarLine.renderChart(self.seriesAllTickets, self.options)
      }
    })

    H.rpc('Dashboard', 'CustomerTicketsData', [], (r) => {
      if (r) {
        self.seriesTicketsArea.labels = r.labels
        self.seriesTicketsArea.datasets = r.infos
        self.$refs.chartTicketsArea.renderChart(self.seriesTicketsArea, self.options)
      }
    })

    H.rpc('Dashboard', 'CustomerHours', [], (r) => {
      if (r) {
        self.seriesTime.labels = r.labels
        self.seriesTime.datasets = r.infos
        self.$refs.chartBarLineTime.renderChart(self.seriesTime, self.options)
      }
    })
  },
  methods: {
    goTo (url) {
      this.$router.push(url)
    }
  },
}
</script>

<style scoped>
.overx {
    overflow-x: scroll;
}
</style>
