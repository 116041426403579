<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <img src="/img/clubedemidia-world-escudo.png" class="d-none d-print-inline"/>
          <h3>Itens de pedidos</h3>
        </div>
        <div class="dropdown-divider"></div>
      </div>

      <div class="card-body d-print-none">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Data Inicial</label>
            <DatePicker
              init_placeholder="- Escolher data -"
              v-model="search.dataini"
            />
          </div>
          <div class="col-lg-6">
            <label>Data Final</label>
            <DatePicker
              init_placeholder="- Escolher data -"
              v-model="search.datafim"
            />
          </div>
          <div class="col-lg-12">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="load()" v-text="textoBotao"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <th>Item</th>
            <th>Quantidade</th>
            <th>Valor Unitário</th>
            <th>Total</th>
          </thead>
          <tbody>
            <tr v-if="!result.length">
              <td colspan="4" class="text-center">Sem resultado</td>
            </tr>
            <tr v-else v-for="row in result">
              <td>{{ row.product_name }}</td>
              <td>{{ row.qty }}</td>
              <td>{{ row.unit_price }}</td>
              <td>{{ row.vl_total }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="result.length">
              <td class="text-right font-weight-bolder">Total</td>
              <td class="font-weight-bolder" v-text="total"></td>
              <td class="font-weight-bolder">&nbsp;</td>
              <td class="font-weight-bolder" v-text="total_valor"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'
import {setArr} from '../common'
import DatePicker from '../components/DatePicker'

export default {
  name: 'CoffeeItensReport',
  components: {
    DatePicker
  },
  data () {
    return {
      search: {
        dataini: '',
        datafim: ''
      },
      users: [],
      result: [],
      total: 0,
      total_valor: 0,
      textoBotao: 'Consultar'
    }
  },
  mounted () {
    H.rpc('User', 'all', [], r => {
      if (r) {
        this.users = r
      }
    })
  },
  methods: {
    load () {
      setArr(this, 'result', [])
      let self = this
      this.textoBotao = 'Consultando'
      H.rpc('CoffeeOrderItem', 'report', [this.search], rows => {
        if (rows) {
          self.textoBotao = 'Consultar'
          self.total = rows.total
          self.total_valor = rows.total_valor
          setArr(this, 'result', rows.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
    th {
        font-size: 0.7rem;
        background-color: rgba(0, 0, 0, 0.125);
    }
}
</style>
