<template>
    <div class="container-fluid">
        <div class="card">
            <div v-if="!no_header" class="card-body">
                <div v-if="is_combo">
                    <h3>Novo ticket combo</h3>
                    <div class="dropdown-divider"></div>
                    <h5>Esse é o menu onde são adicionados os tickets combo, aqueles que combinam a execução de diversos tickets dentro deles como canvas, campanhas e implantações</h5>
                </div>
                <div v-else>
                    <h3>Novo ticket simples</h3>
                    <div class="dropdown-divider"></div>
                    <h5>Cada serviço executado independente de qualquer outro é um ticket simples.</h5>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-lg-4">
                        <span class="mb-2 d-inline-block">Nome do cliente</span>
                    </div>
                    <div class="col-lg-8">
                        <span v-if="job_name" class="text-right float-right mb-2 d-inline-block">
                            {{ job_name }} | Liberação para aprovação:
                            <b>{{ formatShort(approval_start_at) }} a {{ formatShort(approval_due_at) }}</b>
                            | {{ coin_name }} contrato: <b>{{ contract_time }}</b>
                            | saldo de {{ coin_name }}: <b>{{ balance }}</b>
                            | Saldo de {{ coin_name }} previsto com esse job: <b><span :class="{ 'danger-balance': negative_balance }">{{ virtual_balance }}</span></b>
                        </span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                        <CustomerSelect
                            ref="customer_id"
                            v-model="form.customer_id"
                            :disabled="!!init_customer_id || embed"
                            class="form-control "
                            style="padding-top: 2px"
                            init_placeholder="- Pesquisar um cliente -"
                        />
                        <div class="row">
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.customer_id"></div>
                            <span v-if="form.customer_id" class="col-sm-6 link text-right" v-on:click="viewObs()">Ficha do cliente</span>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-3">
                        <label>Tipo de serviço</label>
                        <JobTypeSelect
                            ref="ticket_type_id"
                            v-model="form.ticket_type_id"
                            :filter="{ is_combo }"
                            class="form-control "
                            style="padding-top: 2px"
                            init_placeholder="- Pesquisar serviços -"
                            @row="onJobTypeSelected"
                        />
                        <div class="row">
                            <div class="col-sm-6 invalid-feedback" v-text="feedbacks.ticket_type_id"></div>
                            <span v-if="form.ticket_type_id" class="col-sm-6 link text-right" v-on:click="addBriefText()">Inserir texto do briefing</span>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div>
                            <label>Tempo de execução (minutos)</label>
                            <input
                                v-model="form.execution_time"
                                class="form-control"
                                placeholder="- Aguardando serviço -"
                            />
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div>
                            <label>Título</label>
                            <input
                                v-model="form.name"
                                class="form-control"
                                placeholder="- Título do ticket -"
                            />
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Data e hora do briefing</label>
                        <DateTimePicker
                            v-model="form.start_at"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <HtmlEditor ref="content" v-model="form.content"/>
                        <div class="invalid-feedback" v-text="feedbacks.content"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <label v-if="form.customer_id">Arquivos do briefing</label>
                        <UploadsEditor
                            v-if="form.customer_id"
                            :param="{customer_id: form.customer_id, ticket_id: '_new'}"
                        />
                        <div class="invalid-feedback" v-text="feedbacks.references"></div>
                    </div>
                </div>
            </div>

            <div v-if="!no_footer" class="card-footer">
                <SaveButton
                    ref="save"
                    :disabled="!changed"
                    @click="save()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DateTimePicker from '../components/DateTimePicker'
import {H, showError} from 'hennig-common'
import {renderFeedback, setObj} from '../common'
import SaveButton from '../components/SaveButton'

export default {
    name: 'Ticket',
    components: {DateTimePicker, SaveButton},
    props: {
        is_combo: {Boolean, default: false},
        no_header: {Boolean, default: false},
        no_footer: {Boolean, default: false},
        embed: {Boolean, default: false},
        init_customer_id: {String, default: ''},
        init_parent_ticket_id: {String, default: ''},
        init_combo_ticket_id: {String, default: ''},
    },
    data () {
        return {
            feedbacks: {},
            form: {
                _id: '',
                name: '',
                type: this.is_combo ? 'combo' : 'normal',
                num: '<auto>',
                active: 1,
                customer_id: '',
                ticket_type_id: '',
                parent_ticket_id: '',
                combo_ticket_id: '',
                content: '',
                repeat_type: '',
                repeat_qty: '',
                start_at: '',
                status: 'Aberto',
            },
            due_at: '',
            approval_start_at: '',
            approval_due_at: '',
            job_name: '',
            changed: false,
            show_repeat: false,
            contract_time: 0,
            balance: 0,
            virtual_balance: 0,
            negative_balance: false,
            coin_name: ''
        }
    },
    mounted () {
        this.init()
        this.initPopover()
    },
    watch: {
        'form.ticket_type_id' () {
            this.calcDueDate()
        },
        'form.start_at' () {
            this.calcDueDate()
        }
    },
    methods: {
        onJobTypeSelected (row) {
            this.form.execution_time = row.execution_time
        },
        initPopover () {
            const content = `
* As repetições ocorrerão até o final do ano<br>
* Será antecipado se a repetição ocorrer em final de semana, feriado ou final de mês<br>
<br>
Diariamente - Todos os dias<br>
Mensal - Sempre no mesmo dia do mês<br>
Semanal - Sempre no mesmo dia da semana<br>
Quinzenal - A cada duas semanas sempre no mesmo dia da semana<br>
`
            $(this.$refs.recurrenceHelp).popover({
                content,
                html: true,
                trigger: 'hover'
            })
        },
        clear () {
            this.form.content = ''
            this.form.ticket_type_id = ''
            this.form.start_at = ''
            this.form.num = '<auto>'
        },
        init () {
            this.updateComponents()
            this.setChanged(true)
        },
        setProps (props = {}) {
            for (const prop in this.$props) {
                if (!prop.match(/^init_/)) continue
                this.$set(this.form, prop.replace(/^init_/, ''), this.$props[prop])
            }

            for (const prop in props) {
                this.$set(this.form, prop, props[prop])
            }

            this.updateComponents()
        },
        setChanged (v) {
            setTimeout(() => {
                this.changed = v
            }, 10)
        },
        calcDueDate () {
            this.due_at = ''
            this.job_name = ''
            if (this.form.type === 'combo') {
                // Combo nao pode ser calculado, depende das subtasks
                return
            }

            if (!this.form.ticket_type_id) {
                return
            }
            // Previsao de datas
            H.rpc('Ticket', 'calcDueDate', [this.form], r => {
                if (!r) return
                this.approval_due_at = r.approval_due_at
                this.approval_start_at = r.approval_start_at
                this.job_name = r.job_name
                this.contract_time = r.contract_time
                this.balance = r.balance
                this.virtual_balance = r.virtual_balance
                this.negative_balance = r.negative_balance,
                this.coin_name = r.coin_name
            })
        },
        updateComponents () {
            this.$nextTick(() => {
                this.$refs.customer_id && this.$refs.customer_id.setValue(this.form.customer_id)
                this.$refs.ticket_type_id && this.$refs.ticket_type_id.setValue(this.form.ticket_type_id)
                this.$refs.content && this.$refs.content.setValue(this.form.content)
            })
        },
        addBriefText () {
            H.rpc('JobType', 'briefing', [this.form.ticket_type_id], r => {
                if (!r) return
                this.form.content += r
                this.$refs.content.setValue(this.form.content)
            })
        },
        viewObs () {
            let routeData = this.$router.resolve({name: 'CustomerView', params: {_id: this.form.customer_id}})
            window.open(routeData.href, '_blank')
        },
        fieldClass (name) {
            return {
                'is-invalid': this.feedbacks[name]
            }
        },
        save () {
            if (!this.changed) return

            for (let i in this.feedbacks) {
                this.feedbacks[i] = ''
            }

            this.$refs.save.start()
            H.rpc('Ticket', 'save', [this.form], (r, e) => {
                if (e) {
                    this.$refs.save.fail()
                    if (!renderFeedback(this, e)) {
                        showError(e.message)
                    }
                    return true
                }

                if (r) {
                    this.$refs.save.done()

                    if (this.embed) {
                        this.$emit('saved')
                        return
                    }

                    setObj(this.form, r.data)
                    this.setChanged(false)
                    this.$router.replace({name: 'Ticket', params: {_id: r.data._id}})
                }
            })
        }
    }
}
</script>

<style scoped>
.invalid-feedback {
    display: initial;
}

.danger-balance {
    color: red;
}
</style>
