<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between pb-0">
      <h4 class="card-title mb-1">Consumo de horas mensal | Por minuto</h4>
      <div class="btn-group">
        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ filter }}
        </button>
        <div class="dropdown-menu">
          <p class="dropdown-item m-0" v-for="item in teamSelect" :key="item.status" v-on:click="calculaGraphByTeam(item)">{{ item.status }}</p>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 col-12 justify-content-center pt-2">
          <apexchart 
            type="bar" 
            height="350" 
            :options="chartOptions" 
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { H } from 'hennig-common'

export default {
  name: 'ExpectedChart',
  components: {VueApexCharts},
  props: {

  },
  data () {
    return {
      clientHoursByTeam: [],
      filter: '',

      teamSelect: [
        { status: 'Mário', value: 'Mario' },
        { status: 'Luigi', value: 'Luigi' },
        { status: 'Yoshi', value: 'Yoshi' },
        { status: 'Toad', value: 'Toad' },
        { status: 'Goomba', value: 'Goomba' },
        { status: 'Bowser', value: 'Bowser' },
      ],

      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " minutos"
            }
          }
        },
        labels: [],
      },

    }
  },
  watch: {

  },
  mounted () {
    H.rpc('Dashboard', 'HoursByClient', [], (r) => {
      if (r) {
        this.clientHoursByTeam = r.clientHoursByTeam

        this.filter = 'Bowser'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Bowser.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Bowser.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Bowser.map((item) => {return item.trade_name}),
        };

      }
    })
  },
  methods: {
    calculaGraphByTeam (param) {
      this.ByTeamSearch = param.status

      if(param.value === 'Bowser'){
        this.filter = 'Bowser'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Bowser.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Bowser.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Bowser.map((item) => {return item.trade_name}),
        };
      }
      if(param.value === 'Luigi'){
        this.filter = 'Luigi'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Luigi.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Luigi.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Luigi.map((item) => {return item.trade_name}),
        };
      }
      if(param.value === 'Yoshi'){
        this.filter = 'Yoshi'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Yoshi.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Yoshi.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Yoshi.map((item) => {return item.trade_name}),
        };
      }
      if(param.value === 'Mario'){
        this.filter = 'Mario'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Mario.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Mario.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Mario.map((item) => {return item.trade_name}),
        };
      }
      if(param.value === 'Toad'){
        this.filter = 'Toad'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Toad.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Toad.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Toad.map((item) => {return item.trade_name}),
        };
      }
      if(param.value === 'Goomba'){
        this.filter = 'Goomba'
        this.series = [{
            name: 'Minutos totais',
            data: this.clientHoursByTeam.Goomba.map((item) => {return item.balance}),
            color: '#66DA26'
          }, {
            name: 'Minutos gastos',
            data: this.clientHoursByTeam.Goomba.map((item) => {return item.work_time}),
            color: '#E91E63'
        }]
        this.chartOptions = {
          labels: this.clientHoursByTeam.Goomba.map((item) => {return item.trade_name}),
        };
      }

    },
  }
}
</script>

<style scoped>

</style>