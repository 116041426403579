import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'

const WikiTopics = () => import(/* webpackChunkName: "WikiTopics" */'../views/WikiTopics')
const authRequired = true

export default [
    {
        name: 'WikiTopic',
        path: '/wiki/capitulo/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'WikiTopic'}},
        meta: {authRequired, backRoute: '/wiki/capitulos'}
    },
    {
        path: '/wiki/capitulos',
        components: {default: WikiTopics, header: Logged},
        meta: {authRequired}
    }
]
