<template>
    <div>
        <div v-if="message" class="text-center text-muted">{{ message }}</div>

        <div class="main scroll-styled">

            <div class="d-flex flex-wrap">
                <div v-for="entry in entries" class="entry img-thumbnail" @click="entryClick(entry)">
                    <img :src="getImg(entry)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {H} from 'hennig-common'
import {getEntryType} from '../../common'

export default {
    name: 'FileViewWrap',
    props: {
        param: {},
    },
    data () {
        return {
            tree: [],
            message: '',
            current_entry: {},
        }
    },
    computed: {
        entries () {
            return this.tree.filter(row => !row.folder)
        }
    },
    mounted () {
        this.load()
    },
    methods: {
        getType (entry) {
            return getEntryType(entry)
        },
        getImg (entry) {
            const type = getEntryType(entry)

            if (type === 'image') {
                return this.file(entry)
            }

            if (entry.folder) {
                return '/img/folder.svg'
            }

            if (type === 'txt') {
                return '/img/txt.svg'
            }

            if (type === 'video') {
                return '/img/video.svg'
            }

            if (type === 'audio') {
                return '/img/audio.svg'
            }

            if (type === 'doc') {
                return '/img/doc.svg'
            }

            if (type === 'xls') {
                return '/img/xls.svg'
            }

            if (type === 'pdf') {
                return '/img/pdf.svg'
            }
            if (type === 'zip') {
                return '/img/zip.svg'
            }

            return '/img/default-file.svg'
        },
        entryClick (entry) {
            this.dialogs().ImageView.show({param: this.param, entry})
        },
        load () {
            clearTimeout(this.handler)
            this.handler = setTimeout(() => {
                H.rpc('Media', 'list', [this.param], (r, e) => {
                    this.tree = r || {}
                    if (e) {
                        this.message = e.message
                        setTimeout(() => {
                            this.message = ''
                        }, 3000)
                        return true
                    }
                })
            }, 500)
        },
        file (entry) {
            return '/media/file?q=' + btoa(JSON.stringify({
                param: this.param,
                path: entry.name
            }))
        },
        left () {
            this.swiper.slidePrev()
        },
        right () {
            this.swiper.slideNext()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/variables";

.main {
  height: 400px;
  overflow-y: auto;
}

.entry {
  margin: .5em;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}

::v-deep {
  .modal-dialog {
    height: 80%;
  }

  .modal-content {
    height: 100%;
  }
}
</style>
