<template>
    <div
        :class="{ over }"
        :style="{ height }"
        class="main p-2 scroll-styled"
        @drop.prevent="drop($event)"
        @dragover.prevent="setOver()"
    >
        <div class="toolbar border d-flex justify-content-start">
            <div class="button home border">
                <i class="la la-home" @click="loadHome()"></i>
            </div>
            <div class="button back border">
                <i class="la la-arrow-left" @click="loadUp()"></i>
            </div>
            <div class="button zip border">
                <i class="la la-download" @click="downloadAll()"></i>
            </div>
            <div v-if="!read_only" class="button upload-item border">
                <div class="d-flex align-items-center justify-content-center h-100" title="Clique ou arraste...">
                    <i class="la la-plus"></i>
                </div>
                <input
                    multiple
                    title="Clique ou arraste..."
                    type="file"
                    v-on:change="upload"
                />
            </div>
            <div class="flex-grow-1 text-right">
                {{ last_path }}
            </div>
        </div>
        <div v-if="uploading">Enviando arquivos ...</div>
        <div v-else-if="message" class="text-center text-muted">{{ message }}</div>
        <div v-for="entry in tree" v-else class="entry" @click="entryClick(entry)">
            <img :src="getImg(entry)"/>
            <span class="name">{{ entry.name }}</span>
            <i v-if="entry.folder" class="la la-download" @click.stop="downloadAll(entry)"></i>
            <span v-else>{{ getSize(entry) }}</span>
            <i v-if="!read_only" class="la la-trash" @click.stop="remove(entry)"></i>
        </div>
        <Confirmation
            ref="Confirmation"
            init_primary_text="Remover pasta"
            init_secondary_text="Não"
            init_text="Deseja realmente remover uma pasta?"
        />
    </div>
</template>
<script>
import BaseComponent from './BaseComponent'
import {H, showError} from 'hennig-common'
import {getFilesWebkitDataTransferItems} from '../common'
import Confirmation from '../dialogs/Confirmation'

export default {
    name: 'UploadsEditor',
    extends: BaseComponent,
    components: {Confirmation},
    props: {
        read_only: {
            Boolean,
            default: false
        },
        maxSize: {
            Number,
            default: 50 * 1024 * 1024
        },
        param: {},
        height: {String, default: 'auto'},
        route: {
            String,
            default: '/upload'
        },
    },
    data () {
        return {
            uploading: false,
            over: false,
            tree: {},
            message: '',

            last_path: ''
        }
    },
    mounted () {
        this.baseMounted()
        this.load()
    },
    watch: {
        param (new_value, old_value) {
            if (JSON.stringify(new_value) !== JSON.stringify(old_value)) {
                this.load()
            }
        }
    },
    methods: {
        entryClick (entry) {
            if (entry.folder) {
                return this.load(entry.path)
            }

            this.download(entry)
        },
        getSize (entry) {
            if (entry.folder) {
                return '-'
            }

            return Math.ceil(entry.size / 1024 / 1024) + 'MB'
        },
        getImg (entry) {
            if (entry.folder) {
                return '/img/folder.svg'
            }

            if (entry.name.match(/txt$/i)) {
                return '/img/txt.svg'
            }

            if (entry.name.match(/(avi|mpg|mkv|mp4)$/i)) {
                return '/img/video.svg'
            }

            if (entry.name.match(/(jpeg|bmp|png|gif|svg)$/i)) {
                return '/img/image.svg'
            }

            if (entry.name.match(/(mp3|wav|ogg)$/i)) {
                return '/img/audio.svg'
            }

            if (entry.name.match(/(doc|odt|docx)$/i)) {
                return '/img/doc.svg'
            }

            if (entry.name.match(/(xls|ods|xlsx|csv)$/i)) {
                return '/img/xls.svg'
            }

            if (entry.name.match(/pdf$/i)) {
                return '/img/pdf.svg'
            }
            if (entry.name.match(/zip|rar|7z/i)) {
                return '/img/zip.svg'
            }

            return '/img/default-file.svg'
        },
        loadHome () {
            this.last_path = ''
            this._load('')
        },
        load (path = '') {
            this.last_path = path
            this._load(this.last_path)
        },
        loadUp () {
            // Remove last piece
            this.last_path = this.last_path.replace(/\/\w+\/?$/, '')
            return this._load(this.last_path)
        },
        _load (path) {
            clearTimeout(this.handler)
            this.handler = setTimeout(() => {
                H.rpc('Media', 'list', [this.param, path], (r, e) => {
                    this.tree = r || {}
                    if (e) {
                        this.message = e.message
                        setTimeout(() => {
                            this.message = ''
                        }, 3000)
                        return true
                    }
                })
            }, 500)
        },
        drop (ev) {
            ev.dataTransfer.dropEffect = 'copy'

            getFilesWebkitDataTransferItems(ev.dataTransfer.items).then(files => {
                this._upload(this.route, files)
            })
        },
        setOver () {
            this.over = true
            clearTimeout(this.overTimer)
            this.overTimer = setTimeout(() => {
                this.over = false
            }, 1000)
        },
        imgItemStyle (el) {
            return {backgroundImage: `url('/image/${el._id}')`}
        },
        remove (entry, force) {
            if (!force && entry.folder) {
                this.$refs.Confirmation.show(r => {
                    if (r) {
                        this.remove(entry, true)
                    }
                })

                return
            }

            H.rpc('Media', 'remove', [this.param, entry.path], r => {
                if (r) this.load()
            })
        },
        downloadAll (entry = {}) {
            const path = entry.path || ''
            const name = entry.name || 'folder'
            const opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    param: this.param,
                    path
                })
            }
            fetch('/download/folder', opts)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    // the filename you want
                    a.download = name + '.zip'
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                    // alert('your file has downloaded!') // or you know, something with better UX...
                })
        },
        download (entry) {
            const opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    param: this.param,
                    path: this.last_path + '/' + entry.name
                })
            }
            fetch('/download', opts)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    // the filename you want
                    a.download = entry.name
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                    // alert('your file has downloaded!') // or you know, something with better UX...
                })
        },
        upload (evnt) {
            this._upload(this.route, evnt.target.files)
        },
        _upload (path, files) {
            if (files.length === 0) {
                showError('Nenhum arquivo selecionado')
                return
            }

            this.uploading = true

            const uploads = []
            for (const file of files) {
                const form = new FormData()
                for (const k in this.param) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.param.hasOwnProperty(k)) {
                        form.append(k, this.param[k])
                    }
                }

                if (this.maxSize && file.size > this.maxSize) {
                    showError(`Arquivo muito grande. Limite ${Math.round(this.maxSize / 1000000)}MB`)
                    continue
                }

                form.append('clientfilepath', file.filepath)
                form.append('uploadedfile', file)
                uploads.push(
                    jQuery
                        .ajax({
                            url: path,
                            type: 'POST',
                            data: form,
                            processData: false,
                            contentType: false
                        })
                        .catch(error => {
                            if (error.status === 413) {
                                showError(`Arquivo muito grande. Limite ${Math.round(this.maxSize / 1000000)}MB`)
                                return
                            }

                            showError('Não foi possível enviar este arquivo')
                        })
                )
            }

            Promise
                .all(uploads)
                .then(returns => {
                    this.uploading = false
                    let messages = []

                    for (const r of returns) {
                        if (typeof r === 'string') {
                            messages.push(r)
                        }
                    }

                    if (messages.length) {
                        showError(messages.join('<br>'))
                    }

                    this._load(this.last_path)
                })
                .catch(errors => {
                    this.uploading = false
                    for (const e of errors) {
                        if (e.status === 413) {
                            showError('Um arquivo muito grande foi enviado')
                            return
                        }
                    }

                    console.error(e)
                    showError('Algo deu errado')
                })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/variables";

.main {
  border: 1px solid $input-border-color;
  min-height: 100px;

  &.over {
    border: 2px dashed rgba(0, 0, 255, .5);
  }
}

.btn-secondary {
  min-width: $default-button-width;
}

.toolbar {
  margin-bottom: 1em;

  > div {
    margin: .2rem;
  }

  > div.button {
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.file-item {
  height: 80px;
  width: 60px;
  margin: .2rem;

  .la {
    opacity: .1;
    transition: opacity .5s;
  }
}

.img-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.img-thumbnail {
  position: relative;

  &:hover {
    .hover-blur {
      filter: blur(6px);
    }

    .label, .la {
      opacity: 1;
    }
  }
}

.entry {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .2rem;

  img {
    height: 2em;
    margin-right: .2rem;
  }

  .name {
    flex-grow: 1;
  }
}

.label {
  position: absolute;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  top: 40px;
  left: 0;
  transition: opacity .5s;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid red;
  height: 100%;
  opacity: .01;
  cursor: pointer;
}
</style>
