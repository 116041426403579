<template>
    <select v-model="current_value" class="form-control custom-select">
        <option value="">{{ placeholder }}</option>
        <option
            v-for="place in options"
            :key="place._id"
            :value="place._id"
            v-text="place.name"
        ></option>
    </select>
</template>

<script>
import {H} from 'hennig-common'
import BaseComponent from './BaseComponent'

export default {
    name: 'PlaceSelect',
    extends: BaseComponent,
    props: {
        value: {},
        init_placeholder: {String, default: '- Selecione um local -'},
    },
    data () {
        return {
            placeholder: '',
            current_value: ''
        }
    },
    watch: {
        value (newvalue) {
            this.current_value = newvalue
        },
        current_value (newvalue) {
            this.emitInput(newvalue)
        }
    },
    mounted () {
        this.baseMounted()

        H.rpc('MeetingPlace', 'lookup', [''], options => {
            if (options) {
                this.options = options
            }

            this.emitRow(this.current_value)
        })
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
