<template>
  <div>
    <div>
      <div
        class="btn btn-primary btn-sm"
        @click="addPhase()"
      >Adicionar Fase
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Ordem</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!phases.length">
          <td colspan="5" class="text-center">Sem fases</td>
        </tr>
        <template
          v-else
          v-for="row in phases"
        >
          <tr @click="addPhase(row._id)">
            <td>{{ row.name }}</td>
            <td>{{ row.order }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'
import {setArr} from '../common'
import BaseComponent from './BaseComponent'

export default {
  name: 'CrmFunnelPhase',
  extends: BaseComponent,
  data () {
    return {
      funnel_id: '',
      phases: []
    }
  },
  watch: {
    funnel_id (new_value) {
      if (new_value) {
        this.load()
      }
    }
  },
  methods: {
    addPhase (_id = '') {
      const that = this
      H.rpc('CrmFunnelPhase', 'form', [_id, {funnel_id: this.funnel_id}], r => {
        if (r) {
          H.createForm(r, {
            onstore (evnt, data, $form) {
              that.load()
              $form.closest('.modal').modal('hide')
            }
          })
        }
      })
    },
    save (item) {
      H.rpc('CrmFunnelPhase', 'save', [item._id, item], r => {
        if (r) {
          showInfo('Salvou')
          this.load()
        }
      })
    },
    load () {
      H.rpc('CrmFunnelPhase', 'load', [this.funnel_id], rows => {
        if (rows) {
          setArr(this, 'phases', rows)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
    th {
        font-size: 0.7rem;
        background-color: rgba(0, 0, 0, 0.125);
    }
}
</style>
