import Vue from 'vue'
import VueRouter from 'vue-router'
import CustomerHome from './views/CustomerHome'
import {showError} from 'hennig-common'
import Login from './views/Login'
import Logout from './views/Logout'
import CustomerTickets from './views/CustomerTickets'
import CustomerLogged from './views/headers/CustomerLogged'
import CustomerTicketView from './views/CustomerTicketView'
import CustomerWorkedHours from './views/reports/CustomerWorkedHours'
import JobTypeReport from './views/JobTypeReport'
import ContractsReport from './views/reports/ContractsReport'
import Profile from './views/Profile'

const authRequired = true

const routes = [
  {
    path: '/login', name: 'Login',
    components: {default: Login}
  },
  {
    path: '/profile',
    components: {default: Profile, header: CustomerLogged},
    meta: {authRequired}
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {authRequired}
  },
  {
    path: '/customerHome', name: 'CustomerHome', components: {default: CustomerHome, header: CustomerLogged}, meta: {authRequired}
  },
  {
    path: '/tickets/:phase_id?',
    name: 'Tickets',
    components: {default: CustomerTickets, header: CustomerLogged},
    meta: {authRequired}
  },
  {
    name: 'TicketApproval',
    path: '/ticket/aprovacao/:_id',
    components: {default: CustomerTicketView, header: CustomerLogged},
    props: {default: {isApprovalRoute: true}},
    meta: {authRequired}
  },
  {
    name: 'Ticket',
    path: '/ticket/:_id',
    components: {default: CustomerTicketView, header: CustomerLogged},
    meta: {authRequired}
  },
  {
    path: '/relatorio/horas/trabalhadas/:mes?',
    name: 'WorkedHours',
    components: {default: CustomerWorkedHours, header: CustomerLogged},
    meta: {authRequired}
  },
  {
    path: '/relatorio/jobs',
    components: {default: JobTypeReport, header: CustomerLogged},
    meta: {authRequired}
  },
  {
    path: '/relatorio/horasgerencial/',
    components: {default: ContractsReport, header: CustomerLogged},
    meta: {authRequired}
  },
]

const isAutheticated = () => window.auth && window.auth.user_id
const isAdmin = () => window.auth && window.auth.user_level >= 9

const router = new VueRouter({routes})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.adminRequired)) {
    if (isAdmin()) {
      next()
    } else {
      showError('Para acessar este recurso é necessário ser um administrador')
      next(false)
    }
  } else if (to.matched.some(record => record.meta.authRequired)) {
    for (const route of to.matched) {
      if (route.meta.gate && !window.gates[route.meta.gate]) {
        showError(`Para acessar este recurso é necessário ter permissão (${route.meta.gate})`)
        next(false)
        return
      }
    }

    if (isAutheticated()) {
      next()
    } else {
      next({name: 'Login', params: {nextUrl: to.fullPath}})
    }
  } else {
    if (isAutheticated() && window.changePass) {
      next({name: 'ChangePass'})
      return
    }

    if (isAutheticated()) {
      next({name: 'CustomerHome'})
    } else {
      next()
    }
  }
})
Vue.use(VueRouter)
export default router
