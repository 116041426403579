import Logged from '../views/headers/Logged'
import FormContainer from '../views/FormContainer'

const SysTickets = () => import(/* webpackChunkName: "SysTickets" */'../views/SysTickets')
const SysTicketTypes = () => import(/* webpackChunkName: "SysTicketTypes" */'../views/SysTicketTypes')

const authRequired = true

export default [
    {
        path: '/sys/tickets',
        components: {default: SysTickets, header: Logged},
        meta: {authRequired}
    },
    {
        name: 'SysTicket',
        path: '/sys/ticket/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'SysTicket'}},
        meta: {authRequired, backRoute: '/sys/tickets'}
    },
    {
        path: '/sys/tipos',
        components: {default: SysTicketTypes, header: Logged},
        meta: {authRequired}
    },
    {
        name: 'SysTicketType',
        path: '/sys/tipo/:_id?',
        components: {default: FormContainer, header: Logged},
        props: {default: {collectionObj: 'SysTicketType'}},
        meta: {authRequired, backRoute: '/sys/tipos'}
    }
]
