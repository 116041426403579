<template>
  <div class="container-fluid">
    <div ref="dyn">
      <!-- Dyn -->
    </div>
  </div>
</template>

<script>

import {H} from 'hennig-common'

export default {
  name: 'FormContainer',
  props: {
    collectionObj: String,
    collectionMethod: {String, default: 'form'},
    collectionParams: {default: () => {}},
  },
  mounted () {
    this.createForm()
  },
  methods: {
    createForm () {
      const that = this
      $(this.$refs.dyn).empty()
      const _id = this.$route.params._id || ''
      H.rpc(this.collectionObj, this.collectionMethod, [_id, Object.assign((typeof this.collectionParams != 'undefined' ? this.collectionParams : {}), (typeof this.$route.query != 'undefined' ? this.$route.query : {}))], (r) => {
        if (!r) {return}
        H.createForm(r, {
          container: this.$refs.dyn,
          onstore (evnt, data, $form) {
            // $table.bootgrid('reload')
            // $form.closest('.modal').modal('hide')
            if (that.$route.meta.backRoute) {redirect(that.$route.meta.backRoute)}
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
