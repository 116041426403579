<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>Cadastro de leads</h3>
        <div class="dropdown-divider"></div>
        <h5>Aqui será feito a importação dos leads</h5>
        <div class="form-group row">
          <div class="col-lg-6">
            <input type="file" ref="file-to-upload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
          </div>
          <div class="col-lg-6">
            <input type="checkbox" v-model="useServerFile"> Usar arquivo do servidor <br />
            <input type="text" class="form-control" placeholder="Nome do arquivo" v-model="caminho">
          </div>
          <div class="col-lg-12">
            <input type="button" v-on:click="map" value="Mapear" class="btn btn-primary mr-2">
          </div>
        </div>
      </div>
      <table class="table">
        <tr v-bind:key="rowKey" v-for="(item, rowKey) in fileColumns">
          <td> {{ item.name }}</td>
          <td>
            <select class="form-control-sm" v-model="item.selectedOption" @change="filterOptions">
              <option value=""> - Selecione - </option>
              <option v-for="option in item.filteredOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
          </td>
        </tr>
        <tr v-if="allowSubmit">
          <td colspan="2">
            <input type="button" v-on:click="upload" :value="textButton" class="btn btn-primary mr-2">
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {H, showInfo} from 'hennig-common'
import {showError} from 'hennig-common/src/lib/notifications'

export default {
  name: 'CrmLeadImport',
  data () {
    return {
      current: '',
      tmpContent: '',
      textButton: 'Enviar',
      options: [
        {
          value: 'base_envio',
          label: 'Base de envio',
        },
        {
          value: 'doc_cliente',
          label: 'Documento do cliente',
        },
        {
          value: 'data_nascimento',
          label: 'Data de nascimento',
        },
        {
          value: 'razao_social',
          label: 'Razão social',
        },
        {
          value: 'capital_social',
          label: 'Capital Social',
        },
        {
          value: 'cnae_lead',
          label: 'CNAE Lead',
        },
        {
          value: 'descricao_cnae_lead',
          label: 'Descrição CNAE do lead',
        },
        {
          value: 'cnaes_secundarios',
          label: 'CNAEs secundários',
        },
        {
          value: 'nome_fantasia',
          label: 'Nome fantasia da empresa',
        },
        {
          value: 'local_aquisicao',
          label: 'Onde o email foi captado',
        },
        {
          value: 'contato_email',
          label: 'Nome do contato do email',
        },
        {
          value: 'sexo',
          label: 'Sexo',
        },
        {
          value: 'uf',
          label: 'UF',
        },
        {
          value: 'cidade',
          label: 'Cidade',
        },
        {
          value: 'cep',
          label: 'CEP',
        },
        {
          value: 'endereco',
          label: 'Endereço',
        },
        {
          value: 'numero_endereco',
          label: 'Número',
        },
        {
          value: 'complemento_endereco',
          label: 'Complemento',
        },
        {
          value: 'bairro',
          label: 'Bairro',
        },
        {
          value: 'tipo_cliente',
          label: 'Tipo de cliente',
        },
        {
          value: 'perfil_cliente',
          label: 'Perfil de cliente',
        },
        {
          value: 'produto_contratado',
          label: 'Produto contratado',
        },
        {
          value: 'status_contrato',
          label: 'Status do contrato',
        },
        {
          value: 'data_contratacao',
          label: 'Data de contratação',
        },
        {
          value: 'data_cancelamento',
          label: 'Data do cancelamento',
        },
        {
          value: 'email_disparo',
          label: 'Email para disparo',
        },
        {
          value: 'telefone_comercial',
          label: 'Telefone comercial',
        },
        {
          value: 'telefone_celular',
          label: 'Telefone celular',
        },
        {
          value: 'ddd_telefone',
          label: 'DDD Telefone',
        },
        {
          value: 'telefone_residencial',
          label: 'Telefone residencial',
        },
        {
          value: 'contato_whatsapp',
          label: 'Contato whatsapp',
        }
      ],
      fileColumns: [],
      mappedColumns: [],
      allowSubmit: false,
      useServerFile: false,
      caminho: ''
    }
  },
  watch: {
    current () {
      if (this.current === '') {
        return
      }

      this.fileColumns.forEach((num, idx, arr) => {
        let tmp = {
          label: num.selectedOption,
          value: idx
        }

        this.mappedColumns.push(tmp)
      })

      H.rpc('CrmLead', 'crmUpload', [{file: this.current, columns: this.mappedColumns, caminho: this.caminho}], (rows) => {
        showInfo('Importação agendada! Você será notificado ao final do processo.')
        this.textButton = 'Enviar'
      })

      this.current = ''
    },
    fileColumns: {
      handler (val) {
        let docCli = val.some(item => item.selectedOption === 'doc_cliente')
        let email = val.some(item => item.selectedOption === 'email_disparo')

        this.allowSubmit = docCli && email
      },
      deep: true,
    }
  },
  methods: {
    filterOptions () {
      const selectedOptions = this.fileColumns.map(select => select.selectedOption)
      this.fileColumns.forEach(select => {
        select.filteredOptions = this.options.filter(option => !selectedOptions.includes(option.value) || option.value === select.selectedOption)
      })
    },
    async map () {
      this.fileColumns = []
      this.current = ''
      this.tmpContent = ''
      this.textButton = 'Enviar'
      this.fileColumns = []
      this.mappedColumns = []
      this.allowSubmit = false

      let aFiles = this.$refs['file-to-upload'].files

      if (aFiles.length === 0) {
        showError('Nenhum arquivo selecionado')
        return
      }

      const readHeader = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const text = reader.result
            const lines = text.split('\n')
            const headers = lines[0].split(';')

            headers.forEach(header => {
              if (header.trim() !== '') {
                let data = {
                  name: header,
                  selectedOption: null,
                  filteredOptions: []
                }
                this.fileColumns.push(data)
              }
            })

            resolve(reader.result)
          }
          reader.onerror = reject
          reader.readAsText(file)
        })
      }

      const readFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            resolve(reader.result)
          }
          reader.onerror = reject
          reader.readAsDataURL(file)
        })
      }

      try {
        for (const file of aFiles) {
          await readHeader(file)
          this.tmpContent = await readFile(file)
        }

        this.fileColumns.forEach(select => {
          select.filteredOptions = [...this.options]
        })

      } catch (error) {
        showError('Erro ao processar o arquivo')
        this.textButton = 'Enviar'
      }
    },
    async upload () {
      if (this.useServerFile && (this.caminho === '' || this.caminho === null)) {
        showError('Arquivo não informado')
        return
      }

      this.textButton = 'Enviando... aguarde'
      this.current = this.tmpContent
    }
  }
}
</script>

<style scoped>

</style>
