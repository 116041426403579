<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Cadastro de tipos de contrato</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui vamos cadastrar e editar todos os tipos de contratos da empresa</h5>
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
                        <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>
                        <th data-align="center" data-column-id="x" data-formatter="x" data-sortable="false" data-width="36px"></th>
                        <th data-align="center" data-column-id="active" data-formatter="checkbox" data-width="36px"></th>
                        <th data-column-id="name">Nome do contrato</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {initGrid} from 'hennig-common'

export default {
    name: 'ContractTypes',
    mounted () {
        this.init()
    },
    methods: {
        init () {
            const addLabel = 'Novo tipo de contrato'
            const collectionObj = 'ContractType'
            initGrid({
                container: $(this.$el).find('table'),
                collectionObj,
                rowClick: true,
                addLabel
            })
        }
    }
}
</script>

<style scoped>

</style>
